import { Base, Component, Ref } from '@/vue-property-decorator'
import { Button } from '@/plugins/ant-design-vue/tsx-support'
import { CommonCurrencyInfos } from '@/components/business-components'
import { RouteName } from '@/router/config'
import { createSvgSprite } from '@/utils/business-utils/assets'
import { useI18n } from '@/i18n'
import { useMainStore } from '@/store/index'
import { useSportsStore } from '@/views/selfoperated-games/sports/store'
import AccessRestricted from './403'
import LogoBox from '@/layouts/components/header/components/logo-box'
import Modal from '@/controller/Modal'
import ParlayBetEntrance from '@/views/selfoperated-games/sports/components/parlayBetEntrance'
import Render from '@/components/business-components/func-render/async-component'
import myScroll from '@/components/my-scroll'

import style from './style.module.scss'

@Component<WGGame>({
  name: 'WGGame',
  components: {
    /**自营游戏-体育模块 */
    SelfoperatedGamesSports: Render.createAsyncComponent({
      component: () => import('@/views/selfoperated-games/sports')
    })
  }
})
export default class WGGame extends Base {
  private mainStore = useMainStore()
  private sportsStore = useSportsStore()

  @Ref()
  private scrollRef!: myScroll

  private scrollToTop() {
    this.scrollRef.scrollTo({ y: 0 })
  }

  private goBack() {
    this.$router.push({
      name: RouteName.GAME,
      query: {
        ...this.$route.query
      }
    })
  }

  render() {
    const { t } = useI18n()
    const { hasLogined, userInfos } = this.mainStore
    return (
      <div class={style.wgGameWrap}>
        <header class={style.header}>
          <span onClick={this.goBack} class={style.backIconWrap}>
            <icon-sprite sprite={createSvgSprite('comm_icon_fh')} />
          </span>

          <LogoBox class={style.logoWrap} />
          {!hasLogined ? (
            <div class={style.beforeLogin}>
              <Button.Tsx
                type={'default'}
                onClick={() => Modal.openLoginRegisterModal({ login: true })}
              >
                {t('lobby.modal.login.label')}
              </Button.Tsx>
              <Button.Tsx
                type={'primary'}
                onClick={() => Modal.openLoginRegisterModal()}
              >
                {t('lobby.tabbar.register')}
              </Button.Tsx>
            </div>
          ) : (
            <div class={style.userInfoWrap}>
              <div>
                <icon-sprite sprite={createSvgSprite('input_icon_zh')} />
                <span>{userInfos?.username}</span>
              </div>
              <CommonCurrencyInfos class={style.currencyInfoWrap} />
            </div>
          )}
        </header>

        <div class={style.WGbackTopWrap} onClick={this.scrollToTop}>
          <icon-sprite
            class={style.iconWrap}
            useSkeleton
            sprite={createSvgSprite('comm_icon_fhdb')}
          />
          <div class={style.text}>{t('lobby.rightBar.backTop')}</div>
        </div>

        <ParlayBetEntrance />

        {this.sportsStore.ipAccess ? (
          <my-scroll direction="y" ref="scrollRef">
            <selfoperated-games-sports />
          </my-scroll>
        ) : (
          <AccessRestricted />
        )}
      </div>
    )
  }
}
