import {
  ApiActiveTaskCompleteList,
  ApiEventCategory,
  ApiEventCutOnce,
  ApiEventGet,
  ApiEventItem,
  CutOnceCycleTypeEnum
} from '@/api/event/type'
import {
  CutOnceAnnouncementItem,
  DataItem,
  PopChopOneResponse
} from '@/api/event/cutOnce/type'
import { CutOnceCacheItem, EventState, InvestPageStep } from './type'
import {
  EventDiscountsRedDot,
  apiEventAndDiscountsRedDot
} from '@/api/event/apiEventAndDiscountsRedDot/apiEventAndDiscountsRedDot'
import { MyMap } from './util/myMap'
import {
  PopTypeAfterLogin,
  PopTypeBeforeLogin
} from '@/views/global-modal/modal/common/util/judgePopupType/enum'
import {
  apiActiveTaskCompleteList,
  apiAfterRechargeActivity,
  apiEventCategoryAndActivity
} from '@/api/event'
import {
  apiChopOneKnifeAnnouncement,
  apiChopOneKnifeMyLogs,
  apiPopChopOneKnife
} from '@/api/event/cutOnce'
import { createMyPersistedStorage } from '@/utils/business-utils'
import { defineStore } from 'pinia'
import { injectActivityRedDot, injectCategoryRedDot } from './util/injectRedDot'
import { proxyPreJudgeOpenCutOnce } from './util/proxyOpenCutOnceModal'
import { useMainStore } from '@/store/index'
import { v4 } from 'uuid'
import Manager from '@/context/manager'
import autoModalManager from '@/context/autoModalManager'
import moment from 'moment'
import to from 'await-to-js'

/** 活动的store */
export const useEventStore = defineStore('event', {
  state: (): EventState => ({
    showSaveShortCutGuide: false,
    afterRechargeActivityList: [],
    afterRechargeActivityModalOpening: false,
    categories: [],
    activeList: [],
    eventRedDot: null,
    customizeEvents: {},
    currentCategoryId: 0, // categoryId
    currentActiveIndex: 0,
    collectWordsTimes: 0,
    scrollTop: 0,
    offsetHeight: undefined,
    isUpdate: false,
    detailLoading: false,
    showBottom: false,
    eventItemTitle: '',
    saveUrlUuid: v4(),
    hasAddSaveAddress: false,
    /** 砍一刀活动数据  */
    cutOnceData: {} as ApiEventGet,
    /** 砍一刀抽奖次数  */
    cutOnceTurnCount: 0,
    /** 活动id  */
    activeId: 0,
    /** 砍一刀公告列表  */
    cutOnceAnnouncement: [],
    /** 砍一刀活动抽奖记录id  */
    cutOnceRecordId: 0,
    /** 砍一刀活动规则内容  */
    cutOnceRuleContent: '',
    cutOnceRecordList: [],
    cutOnceReceiveStatus: false,
    /** 砍一刀活动缓存列表  */
    cutOnceCacheList: [] as ApiEventGet[],
    cutOnceSubTaskUserOptType: 0,
    firstLoaded: false,
    /** 砍一刀登录前弹窗状态  */
    cutOnceBeforeLoginPopType: PopTypeBeforeLogin.never,
    /** 砍一刀登录后弹窗状态  */
    cutOnceAfterLoginPopType: PopTypeAfterLogin.never,
    /** 砍一刀弹窗方式：每日弹窗一次 */
    isCutOnceHaveOpenedToday: false,
    /** 砍一刀弹窗方式：只弹窗一次 */
    isCutOnceHaveOpenedEver: false,
    /**砍一刀首页弹窗复选框开关：本次不再显示弹窗 */
    isCutOnceThisTimeNoRemind: false,
    /**砍一刀首页弹窗复选框开关：永不提示 */
    isCutOnceNeverPop: false,
    cutOnceTabsActive: 0,
    /** 刷新中 */
    refreshing: false,
    /** 网络超时 */
    isTimeoutError: false,
    isLoading: false,
    /** 列表页错误信息 */
    eventListErrInfo: null,
    /**投资页面步骤 */
    investPageStep: InvestPageStep.StepOne,
    // 活动任务-完成情况
    activeTaskCompleteList: [] as ApiActiveTaskCompleteList[]
  }),
  getters: {
    /** @deprecated 不要再使用它了.直接使用 activeList ,它俩完全等效,这一块准备移除 */
    getActiveListFlat(): ApiEventItem[] {
      return this.activeList
    },

    /** 映射: 活动分类Id VS 活动列表 */
    mapperCategoryIdToActivity(): MyMap<number, ApiEventItem[]> {
      // 注入小红点,支线任务
      injectActivityRedDot(this.activeList, this.eventRedDot)
      // 此逻辑生成的map,仅包含,有活动指向的[分类id],没有活动指向的,是不会被设置到[map的key]上
      const mapper = new MyMap<number, ApiEventItem[]>()
      // 遍历所有的活动列表,将它们分别转入mapper映射中
      this.activeList.forEach((activity: ApiEventItem) => {
        // 取到当前活动指向的 cateIds ,该id被取到,必须就有活动指向它
        const cateIds = activity.categories.split(',').map((id) => Number(id))
        cateIds.forEach((cId) => {
          const list = mapper.get(cId) || []
          list.push(activity)
          // cId必须有活动指向,然后通过cId映射列表
          mapper.set(cId, list)
        })
      })
      // 对活动列表进行排序,可以领取奖励的排在前面
      mapper.forEach((list) => {
        list.sort((aEv, bEv) => {
          const aNu = aEv.activeCount || 0
          const bNu = bEv.activeCount || 0
          return bNu - aNu
        })
      })
      return mapper
    },
    /** 活动分类 注入了小红点 已过滤掉没有[活动]指向[它的id]的分类 */
    categoriesHasRedDotList(): ApiEventCategory[] {
      // 注入小红点,支线任务
      injectCategoryRedDot(this.categories, this.eventRedDot)
      const cIds = new Set(this.mapperCategoryIdToActivity.keys())
      const cateList = this.categories.filter((cate: { categoryId: number }) =>
        cIds.has(cate.categoryId)
      )
      return cateList
    },
    announcementData(): DataItem[] {
      const { userInfos } = useMainStore()
      const username = String(userInfos?.username || '')
      return (this.cutOnceAnnouncement.find(
        (i) => i.username === username && this.cutOnceData?.id === i.activeId
      )?.list || []) as DataItem[]
    },
    /** 砍一刀缓存数据，根据活动id映射 */
    cutListMap(): Map<number, ApiEventGet> {
      const mapper = new Map<number, ApiEventGet>()
      this.cutOnceCacheList?.forEach((cut: ApiEventGet) => {
        mapper.set(cut.id, cut)
      })
      return mapper
    }
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: 'web.lobby.event',
        // TODO66 经过一系列的测试,此处的檡项,感觉没有任何卵用...以后再来捣鼓它吧.先绕过不管了..
        storage: createMyPersistedStorage({
          setItem(key, value) {
            const data: Pick<EventState, 'categories' | 'activeList'> =
              JSON.parse(value) || {}
            /** 统计分类列表的数量,存够10个以后就不存了 */
            const countMapper = new MyMap<number, number>()
            // 需要被持久化的活动列表,需要过滤
            const activeList = data.activeList.reduce((pre, cur) => {
              // 取得活动所对应的分类id数组
              const ids = cur.categories.split(',').map((id) => Number(id))
              // 判断该活动是否需要持久化,如果某个分类下的活动数量<10个,则需要将该活动持久化
              const isPersist = ids.reduce((isPersist, id) => {
                const count = countMapper.get(id) || 0
                // 如果<10个,则需要存储(只要有一个分类需要存入该活动,则它需要加入列表的状态就不会再变成false)
                if (count < 10) isPersist = true
                // 设置计数器+1
                countMapper.set(id, count + 1)
                return isPersist
              }, false)
              if (isPersist) {
                pre.push(cur)
              }
              return pre
            }, [] as ApiEventItem[])
            // 持久化的数据
            const persistStr = JSON.stringify({
              categories: data.categories,
              activeList
            })
            localStorage.setItem(key, persistStr)
          },
          removeItem(key) {
            return localStorage.removeItem(key)
          }
        }),
        paths: [
          'categories',
          'activeList',
          'saveUrlUuid',
          'hasAddSaveAddress',
          'cutOnceAnnouncement',
          'cutOnceCacheList',
          'isCutOnceHaveOpenedEver',
          'isCutOnceNeverPop',
          'activeTaskCompleteList'
        ]
      }
    ]
  },
  actions: {
    setShowSaveShortCutGuide(showSaveShortCutGuide: boolean) {
      this.showSaveShortCutGuide = showSaveShortCutGuide
    },
    setIsTimeoutError(isTimeoutError: boolean) {
      this.isTimeoutError = isTimeoutError
    },
    /** 获取充值后活动弹窗数据 */
    async getAfterRechargeActivityList() {
      const [err, data] = await to(apiAfterRechargeActivity())
      if (err) {
        console.error('AfterRechargeActivity fetch error:', err)
        return []
      }
      // console.log('>>>>> 充值后弹窗', JSON.parse(JSON.stringify(data)))
      this.afterRechargeActivityList = data || []
      return data || []
    },
    setHasAddSaveAddress(hasAddSaveAddress: boolean) {
      this.hasAddSaveAddress = hasAddSaveAddress
    },
    /** 获取活动分类和活动列表数据 */
    async freshEventCategoryAndActivity() {
      const [err, data] = await to(apiEventCategoryAndActivity())
      this.eventListErrInfo = err
      if (err) {
        console.error('cashback fetch error:', err)
        return []
      }
      // 分类列表(排序)
      data.categoryList?.sort((a, b) => a.sortNumber - b.sortNumber)
      // 处理sonar异味(避免其它维护人员,忽略sort会改变原数组)->赋值与排序分开写
      this.categories = data.categoryList
      const now = moment()
      // 活动列表(增补额外字段)
      this.activeList = (data.activeList || [])
        ?.reduce((pre, acti) => {
          try {
            if (acti.imgId) {
              acti.imgIdParsed = JSON.parse(acti.imgId)
              acti.imgId = ''
            }
          } catch (error) {
            // 脏数据导致报异常,则不添加它
            return pre
          }
          pre.push(acti)
          return pre
        }, [] as ApiEventItem[])
        .filter((i) => {
          const endShowTime = i.endShowTime
          return (endShowTime && now.unix() < endShowTime) || !endShowTime
        })
      // 记录是否请求过
      this.setFirstLoaded(true)
      return this.activeList
    },
    /** 获取活动的消息小红点数量 */
    async freshEventRedDot(isRefresh = false) {
      if (!useMainStore().hasLogined) {
        this.eventRedDot = new EventDiscountsRedDot()
        return
      }
      const [err, data] = (await to(apiEventAndDiscountsRedDot(isRefresh))) as [
        null,
        EventDiscountsRedDot
      ]
      // 未登录智能弹窗需求 需要在未登录时获取本地缓存拿到待领取数量展示
      localStorage.setItem('totalRedDotsCount', JSON.stringify(data))
      if (err) {
        console.error('cashback fetch error:', err)
        return
      }
      this.eventRedDot = data
    },
    /** 获取砍一刀活动公告列表 */
    async featchAnnouncement(activeId: number) {
      const [err, data] = await to(apiChopOneKnifeAnnouncement({ activeId }))
      if (err) {
        console.error('chopOneKnifeAnnouncement error:', err)
        return
      }
      const { userInfos } = useMainStore()
      const username = String(userInfos?.username || '')
      const item = {
        username,
        activeId,
        list: data?.Data ?? []
      }
      if (!this.cutOnceAnnouncement?.length) {
        this.cutOnceAnnouncement = [item]
      } else {
        const old = (
          JSON.parse(
            JSON.stringify(this.cutOnceAnnouncement || '[]')
          ) as CutOnceAnnouncementItem[]
        ).filter((i) => !(i.activeId === activeId && i.username === username))
        this.cutOnceAnnouncement = [item, ...old]
      }
    },
    /** 获取砍一刀抽奖记录列表 */
    async featchCutOnceRecords(recordId: number) {
      const [err, data] = await to(
        apiChopOneKnifeMyLogs({ chop_one_knife_id: recordId })
      )
      if (!err) {
        this.cutOnceRecordList = data?.Data ?? []
      }
    },
    // 设置当前活动大类
    setCurrentCategoryId(categoryId: number) {
      this.currentCategoryId = categoryId
    },
    // 设置是否初始化过
    setFirstLoaded(val: boolean) {
      this.firstLoaded = val
    },
    setAfterRechargeActivityModalOpening(val: boolean) {
      this.afterRechargeActivityModalOpening = val
    },
    // 设置当前活动索引
    setCurrentIndex(index: number) {
      this.currentActiveIndex = index
    },
    setScrollTop(top: number) {
      this.scrollTop = top
    },
    setOffsetHeight(height: number) {
      this.offsetHeight = height
    },
    setCollectWordsTimes(times: number) {
      this.collectWordsTimes = times
    },
    updateIndex(isUpdate: boolean) {
      this.isUpdate = isUpdate
    },
    setDetailLoading(val: boolean) {
      this.detailLoading = val
    },
    setShowBottom(val: boolean) {
      this.showBottom = val
    },
    seteEventItemTitle(val: string) {
      this.eventItemTitle = val
    },
    updateCutOnceTurnCount(count: number) {
      this.cutOnceTurnCount = count
    },
    setActiveId(id: number) {
      this.activeId = id
    },
    setCutOnceRecordId(id: number) {
      this.cutOnceRecordId = id
    },
    updateCutOnceRule(content: string) {
      this.cutOnceRuleContent = content
    },
    updateCutOnceData(data: ApiEventGet) {
      this.cutOnceData = data
    },
    resetCutOnceRecordList() {
      this.cutOnceRecordList = []
    },
    updateCutOnceReceiveStatus(status: boolean) {
      this.cutOnceReceiveStatus = status
    },
    setCutOnceSubTaskUserOptType(type: number) {
      this.cutOnceSubTaskUserOptType = type
    },
    updateIsCutOnceHaveOpenedToday(status: boolean) {
      this.isCutOnceHaveOpenedToday = status
    },
    updateIsCutOnceHaveOpenedEver(status: boolean) {
      this.isCutOnceHaveOpenedEver = status
    },
    setCutOnceTabsActive(val: number) {
      this.cutOnceTabsActive = val
    },
    updateCutOnceCacheList(cutOnceData: CutOnceCacheItem) {
      if (cutOnceData) {
        const { language, userInfos } = useMainStore()
        cutOnceData.username = String(userInfos?.platfromid)
        cutOnceData.lang = language
      }
      this.cutOnceCacheList.unshift(cutOnceData)
    },
    // 判断弹窗是否能打开
    preJudgeOpenCutOnce(params: { isLoginTrigger: boolean }) {
      const { cutOnceCacheList } = useEventStore()
      if (!cutOnceCacheList?.[0]) return false
      const firstItem = cutOnceCacheList[0]
      if (!firstItem?.activeData || !firstItem?.id) return false
      const { isLoginTrigger = false } = params || {}
      // 前端需要手动判断活动是否已经到达了展示结束时间
      // 后端接口已经过滤了，但是由于接口静态化可能过滤不生效
      const unixTime = moment().unix()
      if (firstItem?.endShowTime > 0 && unixTime >= firstItem?.endShowTime) {
        return false
      }
      return proxyPreJudgeOpenCutOnce({
        isLoginTrigger,
        activeData: firstItem.activeData as ApiEventCutOnce,
        isNeverPop: this.isCutOnceNeverPop,
        isThisTimeNoPop: this.isCutOnceThisTimeNoRemind
      })
    },
    /** 打开砍一刀弹窗 */
    openCutOnceModal() {
      const { cutOnceCacheList } = useEventStore()
      if (cutOnceCacheList[0]?.id) {
        Manager.Modal.open('cutOnce', {
          activeId: cutOnceCacheList[0]?.id,
          isHomeModal: true
        }).then((context) => {
          context.$once('modal:close', () => {
            autoModalManager.onModalClose('cutOnce')
          })
        })
      }
    },
    /** 刷新弹窗数据 */
    async freshPopData() {
      const [error, data] = await to(apiPopChopOneKnife())
      if (error) return
      /** 会员层级 未登录状态下会员的默认层级是1,不能直接使用 || 有会员的层级为 0  */
      let memberLevel = useMainStore().userInfos?.member_level as number
      if (memberLevel == undefined) {
        memberLevel = 1
      }

      /** 需要对已有数据做过滤，手动判断活动是否已经到达了展示结束时间 */
      const unixTime = moment().unix()
      const filterData = data?.filter((item: PopChopOneResponse) => {
        return !(item?.endShowTime > 0 && unixTime >= item?.endShowTime)
      })

      /** 匹配到当前用户层级的抽奖助力数据 */
      const currentCutOnce = filterData?.find((item: PopChopOneResponse) => {
        const configLevel =
          (item?.userLevel && item?.userLevel?.split(',')) || []

        // 如果后台是空层级,或是层级包含0,则表示后台要匹配任意层级,它将要显示
        if (configLevel.length === 0 || configLevel.includes('0')) {
          return true
        }
        // 如果匹配上当前用户层级,则显示
        return configLevel.includes(String(memberLevel))
      })

      if (currentCutOnce) {
        currentCutOnce.username = String(useMainStore()?.userInfos?.username)
      }

      // 有缓存数据，查看活动id和当前缓存数据中的活动id是否一致，一致则使用缓存数据
      if (this.cutOnceCacheList.length > 0 && currentCutOnce) {
        // 缓存的接口数据会比静态化的数据准确，因此使用缓存的数据来存
        const cacheDataItem = this.cutOnceCacheList[0]
        // 如果 活动id 用户id 不相等，则更新缓存
        const isActiveSame =
          cacheDataItem?.id === currentCutOnce?.id &&
          cacheDataItem?.username === currentCutOnce.username

        const activeData = cacheDataItem?.activeData as ApiEventCutOnce

        // 循环方式
        const { cycle_type } = activeData

        // 计算是否在重置周期内
        const servieTime = activeData?.time ?? 0
        const periodTime = activeData?.period_time ?? 0
        const overTime = Number(servieTime) + Number(periodTime)
        const now = new Date().getTime() / 1000

        // 如果用户和id不一致 或者 同个用户和活动id的活动过期，则需要重新缓存，
        if (
          !isActiveSame ||
          (overTime - now < 0 &&
            isActiveSame &&
            cycle_type !== CutOnceCycleTypeEnum.Once)
        ) {
          this.cutOnceCacheList = []
          this.updateCutOnceCacheList(currentCutOnce)
        }
      } else {
        // 第一次且没有缓存的情况
        currentCutOnce && this.updateCutOnceCacheList(currentCutOnce)
      }

      this.activeId = currentCutOnce?.id ?? 0

      this.cutOnceBeforeLoginPopType =
        currentCutOnce?.activeData?.beforeLoginPopType ??
        PopTypeBeforeLogin.never
      this.cutOnceAfterLoginPopType =
        currentCutOnce?.activeData?.afterLoginPopType ?? PopTypeAfterLogin.never
    },
    switchCutOncePopStatus(
      name: 'isCutOnceThisTimeNoRemind' | 'isCutOnceNeverPop'
    ) {
      this[name] = !this[name]
    },
    /** 重置砍一刀数据 */
    resetCutOnceData() {
      this.cutOnceData = {} as ApiEventGet
      this.cutOnceReceiveStatus = false
      this.cutOnceSubTaskUserOptType = 0
      this.cutOnceTurnCount = 0
      this.cutOnceRecordId = 0
      this.cutOnceRuleContent = ''
    },
    /** 更新数据 */
    updateEventData() {
      if (this.refreshing) return
      this.refreshing = true
    },
    // 获取活动任务完成情况
    async getActiveTaskCompleteList() {
      const [error, data] = await to(apiActiveTaskCompleteList())
      if (error) return
      this.activeTaskCompleteList = data
    }
  }
})
