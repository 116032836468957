import { JudgePopupTypeTemplate } from './judgePopupTypeTemplate'
import { TaskCategoryApiCodeEnum } from '../../../tasks-modal-dialog/api/type'
import { TaskModalConstant } from '../../../tasks-modal-dialog/store/task-modal-constant'
import { TaskRuleStatusEnum } from '../../../tasks-modal-dialog/class/TaskRule'
import { getAllTaskRuleStatus } from './util/getAllTaskRuleStatus'
/** 任务的类型判断 */
export class JudgePopupTypeTask extends JudgePopupTypeTemplate {
  /** 当前处理的apiCode */
  private apiCode!: TaskCategoryApiCodeEnum
  private store!: TaskModalConstant
  protected getIsHaveOpenedToday() {
    const { codeIsHaveOpenedTodayMapper } = this.store
    return codeIsHaveOpenedTodayMapper[this.apiCode]
  }
  protected getIsHaveOpenedEverMapper() {
    const { codeIsHaveOpenedEverMapper } = this.store
    return codeIsHaveOpenedEverMapper[this.apiCode]
  }
  protected getBeforeLoginType() {
    const { codePopBefLoginMapper } = this.store
    return codePopBefLoginMapper[this.apiCode]
  }
  protected getAfterLoginType() {
    const { codePopAftLoginMapper } = this.store
    return codePopAftLoginMapper[this.apiCode]
  }
  protected getStatusList() {
    // 静态数据中没有状态,要匹配状态才能弹出内容,都直接匹配所有状态
    return getAllTaskRuleStatus()
  }

  /** 特殊处理神秘任务的状态 */
  private handleSpeciallySmrwStatus(statusList: TaskRuleStatusEnum[]) {
    // 先缓存第四个状态
    const lastItem = statusList.pop() as TaskRuleStatusEnum
    // 如果前三个状态,都是待领取或完成,第四个状态才有意义,才重新将之装进数组
    const { PendingReceive, Finish } = TaskRuleStatusEnum
    const exhiArr = [PendingReceive, Finish]
    if (statusList.every((item) => exhiArr.includes(item))) {
      statusList.push(lastItem)
    }
    return statusList
  }

  /** 重写父级的run函数,扩展入参 */
  run(params: {
    store: TaskModalConstant
    /** 是否由登录触发 */
    isLoginTrigger: boolean
    /** 当前处理的类型apiCode */
    apiCode: TaskCategoryApiCodeEnum
  }) {
    const { apiCode, store, ...rest } = params
    this.apiCode = apiCode
    this.store = store
    return super.run(rest)
  }
}
