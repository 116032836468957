/**
 * @deprecated 废弃（请使用consts/categorys 下 GameCategoryIds）
 * 游戏菜单分类id
 */
export enum GameCategoryIds {
  /**
   * 默认值 只是占位
   */
  NORMAL = -1,

  /**
   * 热门
   */
  HOT = 0,

  /**
   * 搜索
   */
  SEARCH = 99,
  /**
   * 最近
   */
  RECENT = 100,
  /**
   * 收藏
   */
  COLLENCT = 101,
  /**
   * 试玩
   */
  DEMO = 20,
  /**
   * 俱乐部
   */
  CLUB = 9,
  /**
   * 朋友局
   */
  CLUBFRIEND = 10,
  /**
   * 全部
   */
  ALL = 0,
  /**
   * 棋牌
   */
  CHESS = 1,
  /**
   * 捕鱼
   */
  FISH = 2,
  /**
   * 电子
   */
  ELECTRONIC = 3,
  /**
   * 真人
   */
  REAL = 4,
  /**
   * 体育
   */
  SPORTS = 5,
  /**
   * 斗鸡
   */
  COCKFIGHTING = 6,
  /**
   * 电竞
   */
  GAMING = 7,
  /**
   * 彩票
   */
  LOTTERY = 8,
  /**
   * 区块链
   */
  BLOCK_CHAIN = 11
}

/**
 * @deprecated 废弃
 * 游戏平台id
 */
export enum GamePlatformIds {
  /**
   * 自营游戏平台
   */
  SELF_OPERATED = 13
}

/**
 * @deprecated 废弃
 */
export enum osType {
  IOS = 1,
  Android = 2,
  PC = 3
}

/**
 * 定位类型:1=无,2=外部链接,3=活动,4=任务,5=充值,6=返水,7=代理,8=VIP
 */
export enum JumpType {
  /**
   * 1=无
   */
  NULL = 1,
  /**
   * 2=外部链接
   */
  EXTERNAL_URL = 2,
  /**
   * 3=活动
   */
  EVENT = 3,
  /**
   * 4=任务
   */
  TASK = 4,
  /**
   * 5=充值
   */
  RECHARGE = 5,
  /**
   * 6=返水
   */
  CASHBACK = 6,
  /**
   * 7=代理
   */
  PROMOTE = 7,
  /**
   * 8=VIP
   */
  VIP = 8,
  /**
   * 俱乐部
   */
  CLUB = 9,
  /**
   * 余额宝
   */
  YUEBAO = 10,
  /**
   * 公积金
   */
  FUND = 11,
  /**
   * 游戏
   */
  GAME = 12
}

/**
 * @deprecated 废弃请使用 PlatformCategories
 * 平台类别（有子平台列表）
 */
export const PlatformCatgory = [
  GameCategoryIds.CHESS,
  GameCategoryIds.FISH,
  GameCategoryIds.ELECTRONIC,
  GameCategoryIds.BLOCK_CHAIN
]

/**
 * @deprecated 废弃请使用 GamePlatformCatgory
 * 平台游戏类别，比如真人、体育、彩票、斗鸡、电竞（游戏列表）
 */
export const PlatformGameCatgory = [
  GameCategoryIds.REAL,
  GameCategoryIds.SPORTS,
  GameCategoryIds.LOTTERY,
  GameCategoryIds.COCKFIGHTING,
  GameCategoryIds.GAMING
]

/**
 * @deprecated 废弃（请使用consts/platforms 下 CustomPlatformId）
 * 二级页面全部平台（自定义的id）
 */
export const CustomPlatformId = '999'

export enum ElectronicPlatformsOrigin {
  PG = 14,
  PG_SL = 200,
  JILI = 40,
  PP = 37,
  JDB = 206,
  TP = 117,
  JDB_NEW = 206
}

export enum ElectronicPlatforms {
  PG = ElectronicPlatformsOrigin.PG,
  JILI = ElectronicPlatformsOrigin.JILI,
  PP = ElectronicPlatformsOrigin.PP,
  JDB = ElectronicPlatformsOrigin.JDB
}

export const electronicPlatforms = [
  ElectronicPlatforms.PG,
  ElectronicPlatforms.JILI,
  ElectronicPlatforms.PP,
  ElectronicPlatforms.JDB
]

/**
 * 新增集合，pg电子是200
 */
export const electronicPlatformsNew = [
  ElectronicPlatformsOrigin.PG_SL,
  ElectronicPlatforms.JILI,
  ElectronicPlatforms.PP,
  ElectronicPlatforms.JDB
]

/** 卡片类型的枚举 */
export enum CardType {
  /** 横向卡片 */
  Landscape = 'landscape',
  /** 垂直卡片 */
  Vertical = 'vertical',
  /** 方型卡片 */
  Square = 'square'
}

export type CardTypeWithoutLandscape = Exclude<CardType, CardType.Landscape>
