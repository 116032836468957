import {
  ApiEventCutOnceNew,
  ApiEventGet,
  CutOnceCycleTypeEnum
} from '@/api/event/type'
import {
  CutOnceAnnouncementItem,
  DataItem,
  PopChopOneResponse
} from '@/api/event/cutOnce/type'
import { CutOnceCacheItem, CutOnceState, RotateIdxItem } from './type'
import { NewSubTaskData } from '@/api/event/newCutOnce/type'
import {
  PopTypeAfterLogin,
  PopTypeBeforeLogin
} from '@/views/global-modal/modal/common/util/judgePopupType/enum'
import {
  apiNewChopOneKnifeAnnouncement,
  apiNewChopOneKnifeMyLogs,
  apiNewPopChopOneKnife
} from '@/api/event/newCutOnce'
import { createMyPersistedStorage } from '@/utils/business-utils'
import { defineStore } from 'pinia'
import { proxyPreJudgeOpenNewCutOnce } from './util/proxyOpenCutOnceModal'
import { useMainStore } from '@/store/index'
import Manager from '@/context/manager'
import autoModalManager from '@/context/autoModalManager'
import moment from 'moment'
import to from 'await-to-js'

/** 活动的store */
export const useNewCutOnceStore = defineStore('newCutOnce', {
  state: (): CutOnceState => ({
    /** 砍一刀活动数据  */
    cutOnceData: {} as ApiEventGet,
    /** 砍一刀抽奖次数  */
    cutOnceTurnCount: 0,
    /** 活动id  */
    // activeId: 0,
    /** 砍一刀公告列表  */
    cutOnceAnnouncement: [],
    /** 砍一刀活动抽奖记录id  */
    cutOnceRecordId: 0,
    /** 砍一刀活动规则内容  */
    cutOnceRuleContent: '',
    cutOnceRecordList: [],
    cutOnceReceiveStatus: false,
    /** 砍一刀活动缓存列表  */
    cutOnceCacheList: [] as ApiEventGet[],
    cutOnceSubTaskUserOptType: 0,
    /** 砍一刀登录前弹窗状态  */
    cutOnceBeforeLoginPopType: PopTypeBeforeLogin.never,
    /** 砍一刀登录后弹窗状态  */
    cutOnceAfterLoginPopType: PopTypeAfterLogin.never,
    /** 砍一刀弹窗方式：每日弹窗一次 */
    isCutOnceHaveOpenedToday: false,
    /** 砍一刀弹窗方式：只弹窗一次 */
    isCutOnceHaveOpenedEver: false,
    /**砍一刀首页弹窗复选框开关：本次不再显示弹窗 */
    isCutOnceThisTimeNoRemind: false,
    /**砍一刀首页弹窗复选框开关：永不提示 */
    isCutOnceNeverPop: false,
    cutOnceTabsActive: 0,
    rotateIdxs: [] as RotateIdxItem[],
    /** 网络超时 */
    isTimeoutError: false,
    isLoading: false
  }),
  getters: {
    /** 当前弹出的弹窗活动id */
    activeId(): number {
      return this.cutOnceData.id || 0
    },
    announcementData(): DataItem[] {
      const { userInfos } = useMainStore()
      const username = String(userInfos?.username || '')
      return (this.cutOnceAnnouncement.find(
        (i) => i.username === username && this.activeId === i.activeId
      )?.list || []) as DataItem[]
    },
    // 剩余次数
    getCutOnceTurnCount(): number {
      return (
        +this.activeData.total_lottery_count -
        +this.activeData.current_lottery_count
      )
    },
    /** 活动状态数据 */
    activeData(): ApiEventCutOnceNew {
      return (this.cutOnceData.activeData || {}) as ApiEventCutOnceNew
    },
    /** 活动抽奖相关 */
    subTaskData(): NewSubTaskData {
      return (this.activeData.SubTaskData || {}) as NewSubTaskData
    }
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: 'web.lobby.newCutOnece',
        // TODO66 经过一系列的测试,此处的檡项,感觉没有任何卵用...以后再来捣鼓它吧.先绕过不管了..
        storage: createMyPersistedStorage(),
        paths: [
          'cutOnceAnnouncement',
          'cutOnceCacheList',
          'isCutOnceHaveOpenedEver',
          'rotateIdxs',
          'isCutOnceNeverPop'
        ]
      }
    ]
  },
  actions: {
    /** 获取砍一刀活动公告列表 */
    async featchAnnouncement(activeId: number) {
      const [err, data] = await to(apiNewChopOneKnifeAnnouncement({ activeId }))
      if (err) {
        console.error('chopOneKnifeAnnouncement error:', err)
        return
      }
      const { userInfos } = useMainStore()
      const username = String(userInfos?.username || '')
      const item = {
        username,
        activeId,
        list: data?.Data ?? []
      }
      if (!this.cutOnceAnnouncement?.length) {
        this.cutOnceAnnouncement = [item]
      } else {
        const old = (
          JSON.parse(
            JSON.stringify(this.cutOnceAnnouncement || '[]')
          ) as CutOnceAnnouncementItem[]
        ).filter((i) => !(i.activeId === activeId && i.username === username))
        this.cutOnceAnnouncement = [item, ...old]
      }
    },
    /** 获取砍一刀抽奖记录列表 */
    async featchCutOnceRecords(recordId: number) {
      const [err, data] = await to(
        apiNewChopOneKnifeMyLogs({ chop_one_knife_id: recordId })
      )
      if (!err) {
        this.cutOnceRecordList = data?.Data ?? []
      }
    },
    updateRotateIdxs(rotateDegIdx: number) {
      const { userInfos } = useMainStore()
      if (!this.rotateIdxs) {
        this.rotateIdxs = []
      }
      const rotateIdxs = JSON.parse(
        JSON.stringify(this.rotateIdxs)
      ) as RotateIdxItem[]
      const item = rotateIdxs?.find(
        (i) =>
          i.activeId === this.activeId &&
          String(i.username) === String(userInfos?.username)
      )
      if (item) {
        item.rotateDegIdx = rotateDegIdx
      } else {
        rotateIdxs.push({
          rotateDegIdx,
          username: String(userInfos?.username),
          activeId: this.activeId
        })
      }
      this.rotateIdxs = rotateIdxs
    },
    updateCutOnceTurnCount(count: number) {
      this.cutOnceTurnCount = count
    },
    // setActiveId(id: number) {
    //   this.activeId = id
    // },
    setCutOnceRecordId(id: number) {
      this.cutOnceRecordId = id
    },
    updateCutOnceRule(content: string) {
      this.cutOnceRuleContent = content
    },
    updateCutOnceData(data: ApiEventGet) {
      this.cutOnceData = data
    },
    resetCutOnceRecordList() {
      this.cutOnceRecordList = []
    },
    updateCutOnceReceiveStatus(status: boolean) {
      this.cutOnceReceiveStatus = status
    },
    setCutOnceSubTaskUserOptType(type: number) {
      this.cutOnceSubTaskUserOptType = type
    },
    updateIsCutOnceHaveOpenedToday(status: boolean) {
      this.isCutOnceHaveOpenedToday = status
    },
    updateIsCutOnceHaveOpenedEver(status: boolean) {
      this.isCutOnceHaveOpenedEver = status
    },
    setCutOnceTabsActive(val: number) {
      this.cutOnceTabsActive = val
    },
    setIsTimeoutError(isTimeoutError: boolean) {
      this.isTimeoutError = isTimeoutError
    },
    setIsLoading(isLoading: boolean) {
      this.isLoading = isLoading
    },
    updateCutOnceCacheList(cutOnceData: CutOnceCacheItem) {
      if (cutOnceData) {
        const { language, userInfos } = useMainStore()
        cutOnceData.username = String(userInfos?.platfromid)
        cutOnceData.lang = language
      }
      this.cutOnceCacheList.unshift(cutOnceData)
    },
    // 判断弹窗是否能打开
    preJudgeOpenNewCutOnce(params: { isLoginTrigger: boolean }) {
      if (!this.cutOnceCacheList?.[0]) return false
      const firstItem = this.cutOnceCacheList[0]
      if (!firstItem?.activeData || !firstItem?.id) return false
      const { isLoginTrigger = false } = params || {}
      // 前端需要手动判断活动是否已经到达了展示结束时间
      // 后端接口已经过滤了，但是由于接口静态化可能过滤不生效
      const unixTime = moment().unix()
      if (firstItem?.endShowTime > 0 && unixTime >= firstItem?.endShowTime) {
        return false
      }
      return proxyPreJudgeOpenNewCutOnce({
        isLoginTrigger,
        activeData: firstItem.activeData as ApiEventCutOnceNew,
        isNeverPop: this.isCutOnceNeverPop,
        isThisTimeNoPop: this.isCutOnceThisTimeNoRemind
      })
    },
    /** 打开砍一刀弹窗 */
    openNewCutOnceModal() {
      if (this.cutOnceCacheList[0]?.id) {
        Manager.Modal.open('newCutOnce', {
          activeId: this.cutOnceCacheList[0]?.id,
          isHomeModal: true
        }).then((context) => {
          context.$once('modal:close', () => {
            autoModalManager.onModalClose('newCutOnce')
          })
        })
      }
    },
    /** 刷新弹窗数据 */
    async freshPopData() {
      const [error, data] = await to(apiNewPopChopOneKnife())
      if (error) return
      /** 会员层级 未登录状态下会员的默认层级是1,不能直接使用 || 有会员的层级为 0  */
      let memberLevel = useMainStore().userInfos?.member_level as number
      if (memberLevel == undefined) {
        memberLevel = 1
      }

      /** 需要对已有数据做过滤，手动判断活动是否已经到达了展示结束时间 */
      const unixTime = moment().unix()
      const filterData = data?.filter((item: PopChopOneResponse) => {
        return !(item?.endShowTime > 0 && unixTime >= item?.endShowTime)
      })
      /** 匹配到当前用户层级的抽奖助力数据 */
      const currentCutOnce = filterData?.find((item: PopChopOneResponse) => {
        const configLevel =
          (item?.userLevel && item?.userLevel?.split(',')) || []

        // 如果后台是空层级,或是层级包含0,则表示后台要匹配任意层级,它将要显示
        if (configLevel.length === 0 || configLevel.includes('0')) {
          return true
        }
        // 如果匹配上当前用户层级,则显示
        return configLevel.includes(String(memberLevel))
      })

      if (currentCutOnce) {
        currentCutOnce.username = String(useMainStore()?.userInfos?.username)
      }

      // 有缓存数据，查看活动id和当前缓存数据中的活动id是否一致，一致则使用缓存数据
      if (this.cutOnceCacheList.length > 0 && currentCutOnce) {
        // 缓存的接口数据会比静态化的数据准确，因此使用缓存的数据来存
        const cacheDataItem = this.cutOnceCacheList[0]
        // 如果 活动id 用户id 不相等，则更新缓存
        const isActiveSame =
          cacheDataItem?.id === currentCutOnce?.id &&
          cacheDataItem?.username === currentCutOnce.username

        const activeData = cacheDataItem?.activeData as ApiEventCutOnceNew

        // 循环方式
        const { cycle_type } = activeData

        // 计算是否在重置周期内
        const servieTime = activeData?.time ?? 0
        const periodTime = activeData?.period_time ?? 0
        const overTime = Number(servieTime) + Number(periodTime)
        const now = new Date().getTime() / 1000

        // 如果用户和id不一致 或者 同个用户和活动id的活动过期，则需要重新缓存，
        if (
          !isActiveSame ||
          (overTime - now < 0 &&
            isActiveSame &&
            cycle_type !== CutOnceCycleTypeEnum.Once)
        ) {
          this.cutOnceCacheList = []
          this.updateCutOnceCacheList(currentCutOnce)
        }
      } else {
        // 第一次且没有缓存的情况
        currentCutOnce && this.updateCutOnceCacheList(currentCutOnce)
      }

      // this.activeId = currentCutOnce?.id ?? 0

      this.cutOnceBeforeLoginPopType =
        currentCutOnce?.activeData?.beforeLoginPopType ??
        PopTypeBeforeLogin.never
      this.cutOnceAfterLoginPopType =
        currentCutOnce?.activeData?.afterLoginPopType ?? PopTypeAfterLogin.never
    },
    switchCutOncePopStatus(
      name: 'isCutOnceThisTimeNoRemind' | 'isCutOnceNeverPop'
    ) {
      this[name] = !this[name]
    },
    /** 重置砍一刀数据 */
    resetCutOnceData() {
      this.cutOnceData = {} as ApiEventGet
      this.cutOnceReceiveStatus = false
      this.cutOnceSubTaskUserOptType = 0
      this.cutOnceTurnCount = 0
      this.cutOnceRecordId = 0
      this.cutOnceRuleContent = ''
    }
  }
})
