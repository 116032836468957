import { AmounKey, JackPotState, PlaceKeyType } from './type'
import {
  CalculatePolling,
  ShowPosition,
  ShowType,
  VirtualBonusPoolData
} from '@/api/aside/type'
import { defineStore } from 'pinia'
import { getVirtualBonusPoolData } from '@/api/aside'
// import { jackpotBaseData } from './mock'
import { GameCategoryIds } from '@/views/game/consts/categorys'
import { HAS_JACKPOT } from '@/views/game/entry/home/config'
import { createMyPersistedStorage } from '@/utils/business-utils'
import { getJumpIndex, setCommonJackPotAmount } from '../common/utils'
import { showPositionMap, stepSize } from '../common/config'
import { useMainStore } from '@/store/index'
import LongConnection, {
  LongConnectionEvent
} from '@/controller/LongConnection'
import Polling from '@/controller/Polling'
import Skin from '@/controller/Skin'

export const useJackPotStore = defineStore('jackPot', {
  state: (): JackPotState => ({
    /** 彩金池金额 */
    jackPotAmount: '',
    /** 虚拟彩金池 初始化配置数据 */
    virtualBonusPool: [],
    /** 根据展示位置进行分类 */
    /** 虚拟彩金池 轮询数据（推送数据） */
    calculatePolling: [],
    /** 跳动key */
    amountKeys: [],
    /** 当数据始终没有变化的时候 重试次数 */
    retryTimes: 0,
    numberNotDiff: false,
    /** 初始化后的等待请求 */
    delayQueryTiemer: undefined
  }),
  getters: {
    /** 获取指定彩金池 跳动的数据 */
    getAmount:
      (state) =>
      (id: number): string => {
        const { virtualPoolNumbers = [] } =
          state.calculatePolling.find(
            (item) => Number(item.id) === Number(id)
          ) || {}
        const amountKey = state.amountKeys.find(
          (item) => Number(item.id) === Number(id)
        )?.value
        let num = '0.0'
        if (virtualPoolNumbers.length > 0 && amountKey !== undefined) {
          num = virtualPoolNumbers[amountKey]
        }
        return num
      },
    /** 获取某个id的心跳 */
    getAmountKey: (state) => (id: number) => {
      const index = id
        ? state.amountKeys.findIndex((item) => Number(item.id) === Number(id))
        : -1
      const amountKeyItem = index > -1 ? state.amountKeys[index] : undefined
      return { amountKeyItem, index }
    },
    /** 获取 彩金池 配置 */
    getPlaceConfig:
      (state) =>
      (
        place?: PlaceKeyType,
        gameCategoryId?: GameCategoryIds,
        platformId?: number
      ): VirtualBonusPoolData[] => {
        if (!place) {
          return (
            state.virtualBonusPool?.filter(
              (i) => i.showType === ShowType.Independent
            ) || []
          )
        }
        /** 展示位置  */
        const type = showPositionMap.get(place)
        const result = state.virtualBonusPool.filter((item) => {
          const showPosition = JSON.parse(
            item.showPosition || '{}'
          ) as ShowPosition
          if (place === 'platformTop') {
            // 平台需要对传入的 gameCategoryId 进行检查确认
            return (
              showPosition.type === type &&
              item.showType !== ShowType.onPlatform &&
              showPosition.value &&
              gameCategoryId === (Number(showPosition.value) as GameCategoryIds)
            )
          } else if (place === 'platformCard') {
            // 平台卡片上的 数据需要对platformId、gameCategoryId 进行确认
            const configGameCategoryId = Number(showPosition.value)
            const configPlatformId = Number(showPosition.value2)
            return (
              showPosition.type === type &&
              item.showType === ShowType.onPlatform &&
              gameCategoryId &&
              platformId &&
              gameCategoryId === configGameCategoryId &&
              platformId === configPlatformId
            )
          }
          return showPosition.type === type
        })
        // console.log(
        //   '----虚拟彩金池 配置---',
        //   type,
        //   JSON.parse(JSON.stringify(result))
        // )
        return result
      }
  },
  actions: {
    /** 设置普通彩金池 轮询跳动数据 */
    setJackPotAmount(data: JackPotState['jackPotAmount']) {
      this.jackPotAmount = data
    },
    /** 设置彩金池 配置数据 */
    setVirtualBonusPool(data: JackPotState['virtualBonusPool']) {
      this.virtualBonusPool = data
    },
    /**
     * 设置跳动队列数据
     * @param data
     */
    setCalculatePolling(
      data: JackPotState['calculatePolling'],
      refresh = false
    ) {
      const calculatePolling = JSON.parse(
        JSON.stringify(this.calculatePolling)
      ) as CalculatePolling[]

      // 设置旧的彩金池 可以做个针对定制的配置
      setCommonJackPotAmount(data)

      if (refresh) {
        this.amountKeys = []
      }

      // 将每一个彩金池配置进行 更新跳动key以及跳动的数据队列
      data.forEach((item) => {
        const itemIdx = calculatePolling.findIndex(
          (i) => Number(i.id) === Number(item.id)
        )
        if (itemIdx > -1) {
          calculatePolling[itemIdx] = item
        } else {
          // 如果没有这个key则推入集合中
          calculatePolling.push(item)
        }
        // 设置当前跳动key
        const amnoutValue = getJumpIndex(item)
        // console.log(
        //   '----虚拟彩金池 轮询数据 设置索引----- ',
        //   item.id,
        //   amnoutValue,
        //   item
        // )
        this.setAmountKeys(amnoutValue || 0, item.id)
      })
      this.calculatePolling = refresh ? data : calculatePolling
    },
    /**
     * 设置跳动key 只传入value值，则更新所有的心跳
     * @param value 跳动值
     * @param id 彩金池id
     */
    setAmountKeys(value: number, id?: number) {
      const amountKeys = JSON.parse(
        JSON.stringify(this.amountKeys)
      ) as AmounKey[]
      if (id) {
        const { amountKeyItem, index } = this.getAmountKey(id)
        if (amountKeyItem) {
          amountKeys[index].value = value
        } else {
          amountKeys.push({ id, value })
        }
      } else {
        amountKeys.forEach((item) => {
          item.value = value
        })
      }
      this.amountKeys = amountKeys
    },
    /** 启动虚拟彩金池 长连接 websocket 监听挂载 */
    longConnectionJackpot() {
      const { hasLogined } = useMainStore()
      // console.log('xxxxx 虚拟彩金池推送数据 初始化')
      this.getVirtualBonusPool()
      // const { isEuropeanAmerican, isStake, isClassicStyle } =
      //   Skin.currentTheme()
      if (hasLogined && HAS_JACKPOT.includes(Skin.skinType)) {
        LongConnection.ws.on(
          LongConnectionEvent.JACKPOT_DATA,
          (data: CalculatePolling[]) => {
            if (data.length) {
              this.setCalculatePolling(data)
            }
          }
        )
      }
    },
    /** 虚拟彩金池初始化 */
    async getVirtualBonusPool() {
      // 初始化数据
      const { data } = await getVirtualBonusPoolData()

      const calculatePollingInitData =
        data?.map((i) => ({
          id: i.id,
          showType: i.showType,
          virtualPoolNumbers: i.virtualPoolNumbers || [],
          timestamp: i.timestamp || 0,
          virtualPoolTimestamp: i.virtualPoolTimestamp || 0
        })) || []
      // 保存数据配置
      this.setVirtualBonusPool(data as JackPotState['virtualBonusPool'])
      // 设置跑动数据
      this.setCalculatePolling(calculatePollingInitData, true)

      // 设置心跳跳动
      if (calculatePollingInitData.length > 0) {
        this.beating()
      }
    },
    /** 登录后 设置跳动key */
    loginedSetKey() {
      this.calculatePolling.forEach((i) => {
        const amnoutValue = this.getAmountKey(i.id).amountKeyItem?.value || 0

        // 设置旧的彩金池 可以做个针对定制的配置
        setCommonJackPotAmount(this.calculatePolling)

        // 当前跳动key没有超过最大位数 则继续跳动
        if (i.virtualPoolNumbers.length - 1 > amnoutValue) {
          // const key = getJumpIndex(i)
          this.setAmountKeys(amnoutValue + 1, i.id)
        }
      })
    },
    /** 未登录 每次心跳跳动key */
    noLoginedSetKey() {
      // 因为每次跳动都是同步的，所以取第一个值即可
      const virtualPoolNumbers =
        this.calculatePolling?.[0]?.virtualPoolNumbers?.length || 0
      const amnoutValue = this.amountKeys?.[0]?.value || 0

      // console.log(
      //   '----虚拟彩金池 未登录 心跳-----',
      //   amnoutValue,
      //   this.numberNotDiff,
      //   this.retryTimes
      // )

      // 设置旧的彩金池 可以做个针对定制的配置
      setCommonJackPotAmount(this.calculatePolling)

      // 如果有跳动队列 并且当前跳动key没有超过最大位数 则继续跳动 否则停止跳动
      if (virtualPoolNumbers - 1 > amnoutValue) {
        this.setAmountKeys(amnoutValue + 1)
      }
      // 如果是最后一个数据跳动 启动请求下一轮的数据（所以先加5秒） 重试次数30次
      if (virtualPoolNumbers - 1 <= amnoutValue + 1 && this.retryTimes < 1) {
        // 定位需要发起轮询的时刻 需要添加随机值 15秒内 但是，如果是上一个心跳的时候请求的数据有问题则立刻触发
        const delayPollingTime =
          virtualPoolNumbers - 1 === amnoutValue + 1
            ? Math.floor(Math.random() * 15) + 5
            : 0
        this.getQueePolling(delayPollingTime)
        this.retryTimes += 1
      } else if (this.retryTimes > 12 || !this.numberNotDiff) {
        // 1分钟后清除重试 则在下个心跳的时候可以执行重试
        this.retryTimes = 0
      } else if (this.numberNotDiff) {
        // 发现循环数据相同则继续叠加重试轮次
        this.retryTimes += 1
      }
    },
    /** 未登录 获取跳动队列 */
    getQueePolling(delayPollingTime: number) {
      const { hasLogined } = useMainStore()
      // console.log(
      //   '----虚拟彩金池 轮询数据 请求前----- ',
      //   hasLogined,
      //   this.delayQueryTiemer
      // )
      if (!hasLogined && !this.delayQueryTiemer) {
        // 延迟启动轮询
        this.delayQueryTiemer = setTimeout(async () => {
          clearTimeout(this.delayQueryTiemer)
          this.delayQueryTiemer = undefined
          // 获取下一轮播放队列
          const calculatePollingData = await getVirtualBonusPoolData()

          if (
            calculatePollingData?.data &&
            calculatePollingData.data.length > 0
          ) {
            // 因为请求的是json，所以做个简单对比
            this.numberNotDiff =
              calculatePollingData.data.every((i) =>
                this.calculatePolling.some(
                  (j) =>
                    j.id === i.id &&
                    j.virtualPoolTimestamp === i.virtualPoolTimestamp
                )
              ) &&
              calculatePollingData.data.length === this.calculatePolling.length

            this.setCalculatePolling(calculatePollingData.data || [])
          }
        }, delayPollingTime)
      }
    },
    /** 启动 心跳跳动 */
    beating() {
      const { hasLogined } = useMainStore()

      // 创建 心跳跳动轮询 每次跳动5秒
      Polling.create({
        key: Polling.LobbyPollingType.JACKPOT_AMOUNT,
        callback: () => {
          // 因为登录前后获取数据不一样，所以需要分开处理
          if (hasLogined) {
            this.loginedSetKey()
          } else {
            // 未登录 使用接口轮询获取数据，每次都是返回所有数据 所有心跳需要同时进行跳动
            this.noLoginedSetKey()
          }
        },
        interval: 1000 * stepSize
      })
    }
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: 'web.lobby.jackPot',
        storage: createMyPersistedStorage(),
        paths: [
          'jackPotAmount',
          'virtualBonusPool',
          'calculatePolling',
          'amountKeys'
        ]
      }
    ]
  }
})
