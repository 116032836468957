import { ApiGetChannelInfoByIdResponse } from '@/api/channel/type'
import { defineStore } from 'pinia'

export interface ChannelState {
  /**渠道相关信息来自url的cid */
  channelInfoFromUrl: ApiGetChannelInfoByIdResponse | null

  /** 渠道相关信息来自注册的cid*/
  channelInfoFromRegister: ApiGetChannelInfoByIdResponse | null
}

export const useChannelStore = defineStore('channel', {
  state: (): ChannelState => ({
    channelInfoFromUrl: null,
    channelInfoFromRegister: null
  }),
  getters: {
    /**
     * @description
     * 存在渠道的渠道信息，获取注册的cid信息
     * 不存在渠道的渠道信息，那么获取url中的cid
     * 如果都没有那就是null
     *
     */
    channelInfo(): ApiGetChannelInfoByIdResponse | null {
      if (this.channelInfoFromRegister) {
        return this.channelInfoFromRegister
      }
      if (this.channelInfoFromUrl) {
        return this.channelInfoFromUrl
      }
      return null
    }
  },
  actions: {
    setChannelInfoFromUrl(channelInfoFromUrl: ApiGetChannelInfoByIdResponse) {
      this.channelInfoFromUrl = channelInfoFromUrl
    },
    setChannelInfoFromRegister(
      channelInfoFromRegister: ApiGetChannelInfoByIdResponse
    ) {
      this.channelInfoFromRegister = channelInfoFromRegister
    }
  }
})
