import {
  CategoryCanReceive,
  EventDiscountsRedDot
} from '../../../api/apiEventAndDiscountsRedDot'
import { CategoryUsable, State } from './type'
import { TaskCategoryApiCodeEnum as TCACE } from '../../../api/type'
// import { TaskCategory } from '../../../class/TaskCategory'
import { TaskCategory } from '@/views/task/class/taskCategory'
import type { TaskPageStore } from '..'
export type { State, CategoryUsable } from './type'
/** 对应字段codeCategoryMapper */
const codeOrder = [
  TCACE.xrfl,
  TCACE.mrrw,
  TCACE.mzrw,
  TCACE.smrw
] as State['codeCategoryOrder']
/** 初始化state 若切换了多语言,所有接口也是需要重新请求的,所以,所有数据都需要重新初始化一遍 */
export const initState = (): State => {
  return {
    isLoading: false,
    timeoutErrorMapper: {
      ...createApiCodeToAnyMapper(false),
      canUsable: false
    },
    dataChangeFreshView: 0,
    curTabApiCode: TCACE.xrfl,
    taskGlobalStatistics: new EventDiscountsRedDot(),
    categoryUsable: new CategoryUsable(),
    isFetching: false,
    isFetched: false,
    fetchedDataLanguage: '',
    fetchedDataUserId: '',
    fetchedDataCurrency: '',
    codeCategoryOrder: [...codeOrder],
    codeCategoryMapper: createApiCodeToAnyMapper(
      (code) => new TaskCategory(code)
    ),
    codeCateReceiveMapper: createApiCodeToAnyMapper(
      (code: TCACE) => new CategoryCanReceive({ apiCode: code })
    ),
    codeRulesMapper: createApiCodeToAnyMapper([])
  }
}

/** 重置数据,并不重置全部数据,而是挑选出部分需要被重置的内容 */
export const resetState = (store: TaskPageStore) => {
  const newState = {
    isLoading: false,
    timeoutErrorMapper: {
      ...createApiCodeToAnyMapper(false),
      canUsable: false
    },
    taskGlobalStatistics: new EventDiscountsRedDot(),
    codeCateReceiveMapper: createApiCodeToAnyMapper(
      (code: TCACE) => new CategoryCanReceive({ apiCode: code })
    ),
    isFetched: false,
    fetchedDataLanguage: '',
    fetchedDataUserId: '',
    fetchedDataCurrency: '',
    codeRulesMapper: createApiCodeToAnyMapper([])
  }
  Object.assign(store, newState)
  store.categoryUsable.isFechted = false
}

/** 创建apiCode与其它任何值的映射
 * @param {T} defVal 默认值  [基础值:直接赋值成默认值]  [函数:执行函数,将函数的返回值赋值成默认值]
 */
export const createApiCodeToAnyMapper = <T>(
  defVal: T | ((key: TCACE) => T)
) => {
  const keys = [TCACE.xrfl, TCACE.mrrw, TCACE.mzrw, TCACE.smrw]
  return keys.reduce((mapper, key) => {
    if (typeof defVal !== 'function') {
      mapper[key] = defVal as T
    } else {
      mapper[key] = (defVal as (key: TCACE) => T)(key)
    }
    return mapper
  }, {} as Record<TCACE, T>)
}
