import {
  ApiEventGet,
  ApiEventSign,
  ApiEventSignReceiveData,
  ReceiveStatusEnum,
  SignRewardTypeEnum,
  SignStartStatusEnum,
  SignStatusEnum,
  SignTypeEnum,
  SingResult
} from '@/api/event/type'
import { AxiosError, AxiosResponse } from 'axios'
import { Base, Component, Prop, Ref, Watch } from '@/vue-property-decorator'
import { Bind, Debounce } from '@/utils/Decorator'
import { Button } from '@/plugins/ant-design-vue/tsx-support'
import { GlobalConst, GlobalManager } from '@/context'
import { RouteName } from '@/router/config'
import { SignCacheItem } from './store/type'
import { apiActiveSing } from '@/api/event/sign'
import { apiEventGet } from '@/api/event'
import { brazilianCurrencyFormatter } from '@/utils/business-utils/finance'
import { canReceiveReward, taskComplete } from '@/views/event/item/utils'
import { createSvgSprite } from '@/utils/business-utils/assets'
import { occupierReplaceVNode } from '@/utils/occupierReplaceVNode'
import { showErrorMessageModal } from '@/views/event/item/utils'
import { useEventStore } from '../../../store'
import { useI18n } from '@/i18n'
import { useMainStore } from '@/store/index'
import { useSignStore } from './store'
import AppTypes from '@/vue-types'
import AutoShrinkText from '@/components/auto-shrink-text'
import BusinessUtils from '@/utils/business-utils'
import Manager from '@/context/manager'
import Modal from '@/controller/Modal'
import NetRetry, {
  DisplayType
} from '@/components/business-components/net-retry'
import PublicIntroduction from '@/views/event/item/component/discount-code/components/introduction'
import RewardDescription from './rewardDescription'
import RewardPattern from './rewardPattern'
import SuccessMsssageModal from './successMsssageModal'
import TooltipsComp from '@/components/business-components/tooltips'
import style from './style.module.scss'
import to from 'await-to-js'
import type { ApiEventSignItem } from '@/api/event/type'

interface Props {
  /**
   * 活动详情数据
   */
  event?: ApiEventGet
  activeId: number
  isHomeModal: boolean
  /**
   * 刷新数据
   */
  fetchData?: () => void
  goBack?: () => void
}

interface State {
  isSigning: boolean
  refreshing: boolean
}

@Component<EventItemSign>({ name: 'EventItemSign' })
export default class EventItemSign extends Base<State, Props> {
  /**
   * 活动ID
   */
  @Prop(AppTypes.number.isRequired)
  activeId!: Props['activeId']

  @Prop(AppTypes.bool.isRequired)
  isHomeModal!: Props['isHomeModal']

  /**
   * 刷新数据
   */
  @Prop({ required: false })
  fetchData: Props['fetchData']

  /**
   * 返回
   */
  @Prop({ required: false })
  goBack: Props['goBack']

  @Ref()
  public readonly successMsssageModal!: SuccessMsssageModal

  state: State = {
    isSigning: false,
    refreshing: false
  }

  private get signStore() {
    return useSignStore()
  }

  private get event() {
    return this.signStore.signData
  }

  private get activeData() {
    return (this.event.activeData ?? {}) as ApiEventSign
  }

  get isLogin() {
    return useMainStore().hasLogined
  }

  get language() {
    return useMainStore().language
  }
  private getReceiveText(statas: number) {
    const { t } = useI18n()
    return {
      [ReceiveStatusEnum.Processing]: t('lobby.event.sign.signIn'),
      [ReceiveStatusEnum.CanReceive]: t('lobby.event.sign.hasSignIn'),
      [ReceiveStatusEnum.HasReceive]: t('lobby.modal.task.distributed'),
      [ReceiveStatusEnum.Expired]: t('lobby.modal.task.expired'),
      [ReceiveStatusEnum.Cancel]: t('lobby.modal.task.cancel'),
      [ReceiveStatusEnum.PendingCheck]: t('lobby.event.sign.signIn'),
      [ReceiveStatusEnum.PendingApply]: t('lobby.event.pendingApply'),
      [ReceiveStatusEnum.Rejected]: t('lobby.records.rejected')
    }[statas] as string
  }
  get signList(): ApiEventSignItem[] {
    // 按vip阶段奖励
    const vipRuleList = this.activeData.vipRuleList
    if (vipRuleList) {
      let memberLevel = useMainStore().userInfos?.member_level as number
      if (memberLevel == undefined) {
        memberLevel = 1
      }

      const vipRule = vipRuleList.find((it) => {
        const configLevel = it.vipLevels.split(',')
        return configLevel.includes(String(memberLevel))
      })

      // vip等级奖励配置时，自身vip等级在配置中取不到，则取第一个
      if (vipRule) return vipRule.ruleList
      else return vipRuleList[0].ruleList
    }
    return this.activeData.signList
  }

  @Watch('language')
  @Watch('isLogin')
  protected handleChangeStatus() {
    Manager.Modal.close('sign')
  }

  mounted() {
    /** 判断是否是首页弹窗，如果是首页弹窗，读取缓存数据， 反之请求接口 */
    const { signCacheData } = useSignStore()
    const { language, userInfos } = useMainStore()
    const cacheData = signCacheData.find(
      (it) =>
        it.id === this.activeId &&
        it.username === String(userInfos?.username) &&
        it.lang === language
    )
    if (this.isHomeModal && cacheData) {
      this.updateData(cacheData)
    } else {
      this.query()
    }
  }

  private async query() {
    const [err, data] = await to(apiEventGet(this.activeId, true))
    this.signStore.errorInfo = err as AxiosError
    if (err) {
      this.updateData({} as SignCacheItem)
      // console.log(err)
      return
    }
    const { updateSignCacheData, signCacheData } = useSignStore()
    const firstCacheItem = signCacheData[0] as SignCacheItem
    const { userInfos, language } = useMainStore()
    if (
      firstCacheItem &&
      firstCacheItem?.username &&
      userInfos?.username &&
      firstCacheItem?.lang &&
      firstCacheItem.id === data.id &&
      firstCacheItem?.lang === language &&
      String(firstCacheItem?.username) === String(userInfos?.username)
    ) {
      updateSignCacheData(data)
    }
    this.updateData(data)
  }

  // 刷新数据
  private async refresh() {
    this.setState({
      refreshing: true
    })
    await this.query()
    this.setState({
      refreshing: false
    })
  }

  /** 更新数据 */
  private updateData(data: SignCacheItem) {
    this.signStore.updateSignData(data)
    this.updateIsSignModalType()
  }
  /** 记录下弹窗方式 */
  private updateIsSignModalType() {
    const {
      isSignHaveOpenedEver,
      isSignHaveOpenedToday,
      updateIsSignHaveOpenedEver,
      updateIsSignHaveOpenedToday
    } = useSignStore()

    /** 只弹窗一次 */
    if (!isSignHaveOpenedEver) {
      updateIsSignHaveOpenedEver(true)
    }

    /** 记录下弹窗方式：每日只弹一次 */
    if (!isSignHaveOpenedToday) {
      updateIsSignHaveOpenedToday(true)
    }
  }

  /**
   * 是否禁用按钮
   * 1. 充值 + 打码 是否达到
   * 2. 天数判断及状态判断
   */
  private buttonEnable(btnValid: boolean): boolean {
    const { havePay, needPay, haveBet, needBet } = this.event
      .activeData as ApiEventSign
    const validMap = {
      needPay: havePay >= needPay && btnValid,
      needBet: haveBet >= needBet && btnValid
    }

    if (this.notReceiveStatus) {
      return false
    }

    if (needPay && needBet) {
      return validMap.needPay && validMap.needBet
    }

    // 充值
    if (needPay) {
      return validMap.needPay
    }

    // 打码
    if (needBet) {
      return validMap.needBet
    }

    return btnValid
  }

  /**
   * 是否为累计签到模式
   */
  private get isGrandMode() {
    return this.activeData.signType === SignTypeEnum.Grand
  }

  /**
   * 不可领取的状态
   */
  private get notReceiveStatus() {
    const { logStatus } = this.activeData
    // 不可领取状态
    const notReceiveArr = [
      ReceiveStatusEnum.HasReceive,
      ReceiveStatusEnum.Cancel,
      ReceiveStatusEnum.PendingApply,
      ReceiveStatusEnum.Rejected
    ]
    return notReceiveArr.includes(logStatus)
  }

  @Bind
  @Debounce()
  async handleSign(item: ApiEventSignItem, IsAgainSign = false) {
    const { t } = useI18n()
    const mainStore = useMainStore()

    if (this.state.isSigning) {
      return
    }
    if (!mainStore.hasLogined) {
      Modal.openLoginRegisterModal()
      return
    }

    if (this.notReceiveStatus) {
      return
    }

    if (item.signStatus !== SignStatusEnum.UnSignIn) {
      return
    }

    if (!canReceiveReward(this.event.receiveDeviceType)) {
      return
    }
    // 验证任务完成情况
    if (taskComplete(this.event.taskCondition)) {
      return
    }

    this.setState({
      isSigning: true
    })
    const [err, resData] = await to(
      apiActiveSing({
        activeId: this.event.id,
        singDay: item.day,
        isRecoup: item.isRecoup,
        IsAgainSign
      })
    )

    this.setState({ isSigning: false })

    if (err) {
      const response = err as unknown as AxiosResponse
      const errorMsg: string =
        response?.data?.msg ?? t('lobby.reward.base.receivedError').toString()
      GlobalManager.Modal.open('importTips', {
        tips: errorMsg
      })
      return
    }

    const { time, data } = resData
    // 调用接口更新数据
    this.signStore.updateSignData(data.activeData)
    this.signStore.updateSignCacheData(data.activeData)

    // singResult	0 成功 1前台状态与后台不符合，刷新界面 2不满足条件 3 已经领取 4 领取发生错误，具体见 msg
    if (data.singResult === SingResult.success) {
      mainStore.audioReceivePlay()
      const receiveData = data?.receiveData as ApiEventSignReceiveData
      this.successMsssageModal.open({
        prize: data?.receiveData?.prize ?? '',
        onClose: async () => {
          if (receiveData?.extraPrize > 0) {
            mainStore.audioReceivePlay()
            await this.successMsssageModal.open({
              animation: true,
              extraPrize: receiveData?.extraPrize ?? ''
            })
          } else {
            this.successMsssageModal.close()
          }
        }
      })

      mainStore.initGetredDot(true)
      mainStore.updatePrize()
      useEventStore().freshEventCategoryAndActivity()
      mainStore.initGetredDot(true)
      // 更新签到时间
      this.signStore.updatePreSignTime(time * 1000, this.activeId)
    } else if (data.singResult === SingResult.statusError) {
      const errorMessage = t('lobby.event.sign.continueSign', {
        x: (data.activeData?.activeData as ApiEventSign).signDays
      })

      const modal = GlobalManager.Modal.create({
        title: t('lobby.receiveModal.kindTips'),
        width: BusinessUtils.px2rem(600),
        content: <div class={style.signContent}>{errorMessage}</div>,
        closable: true,
        cancelButtonProps: {
          style: {
            display: 'none'
          }
        },
        okButtonProps: {
          props: {
            type: 'success'
          }
        },
        okText: (item.isRecoup
          ? t('lobby.event.sign.reSigning')
          : t('lobby.event.sign.signIn')
        ).toString(),
        onOk: async () => {
          await modal.destroy()
          this.handleSign(item, true)
        }
      })
    } else if (data.singResult === SingResult.conditionError) {
      const activeData = data.activeData?.activeData as ApiEventSign
      const isRecharge = activeData?.havePay < activeData?.needPay
      const { canRecoup } = activeData

      const modal = GlobalManager.Modal.create({
        title: () => {
          return (
            <span class={style.resultModelTitle}>
              <icon-sprite sprite={createSvgSprite('comm_icon_zyts')} />
              {t('lobby.event.sign.signFailed')}
            </span>
          )
        },
        width: BusinessUtils.px2rem(600),
        content: () => {
          return (
            <div class={style.resultModalContent}>
              <p class={style.resultModalBold}>
                {isRecharge
                  ? t('lobby.event.sign.insufficientRecharge')
                  : t('lobby.event.sign.underBetting')}
              </p>
              {canRecoup && <p>（{t('lobby.event.sign.reSigningTip')}）</p>}
            </div>
          )
        },
        closable: true,
        cancelButtonProps: {
          style: {
            display: 'none'
          }
        },
        okText: (isRecharge
          ? t('lobby.event.sign.toRecharge')
          : t('lobby.event.sign.toGame')
        ).toString(),
        onOk: async () => {
          await modal.destroy()
          // 关闭签到弹窗
          Manager.Modal.close('sign')
          if (isRecharge) {
            Modal.open('payModal')
          } else {
            this.$router.push({ name: RouteName.GAME })
          }
        }
      })
    } else {
      data.msg && showErrorMessageModal(data.msg)
    }
  }

  /**
   * 签到信息
   * 签到方式： 连续签到 + 累计签到
   * @returns
   */
  renderSummary() {
    const { t } = useI18n()
    const { signDays, havePay, receiveReward, haveBet, canRecoup } =
      this.activeData

    let { needBet, needPay } = this.activeData
    try {
      const currentDayData = this.signList[signDays] as ApiEventSignItem
      if (currentDayData && this.isHomeModal) {
        needBet = currentDayData.needBet
        needPay = currentDayData.needPay
      }
    } catch (error) {}

    return (
      <div class={style.summary}>
        <div class={style.info}>
          <div>
            {this.signStore.errorInfo ? (
              <div class={style.item}>
                <span>
                  {occupierReplaceVNode(
                    t(
                      `lobby.event.sign.${
                        this.isGrandMode ? 'grand' : 'continued'
                      }`
                    ) as string,
                    '<span>{x}</span>',
                    <NetRetry
                      class={[style.netRetry, style.netRetryMiddle]}
                      loading={this.state.refreshing}
                      axiosError={this.signStore.errorInfo}
                      displayType={DisplayType.InsufficientSpace}
                      onRetry={this.refresh}
                    ></NetRetry>
                  )}
                </span>
              </div>
            ) : (
              <inner-html
                class={[style.item]}
                type="div"
                text={t(
                  `lobby.event.sign.${
                    this.isGrandMode ? 'grand' : 'continued'
                  }`,
                  {
                    x: signDays
                  }
                ).toString()}
              />
            )}

            {this.signStore.errorInfo ? (
              <div class={style.item}>
                <span>
                  {occupierReplaceVNode(
                    t('lobby.event.sign.collected') as string,
                    '<strong>{x}</strong>',
                    <NetRetry
                      class={style.netRetry}
                      loading={this.state.refreshing}
                      axiosError={this.signStore.errorInfo}
                      displayType={DisplayType.InsufficientSpace}
                      onRetry={this.refresh}
                    ></NetRetry>
                  )}
                </span>
              </div>
            ) : (
              <inner-html
                class={[style.item]}
                type="div"
                text={t('lobby.event.sign.collected', {
                  x: brazilianCurrencyFormatter(receiveReward || 0)
                }).toString()}
              />
            )}
          </div>
          {(!!needPay || !!needBet) && (
            <div class={style.receiveRight}>
              {!!needPay && (
                <inner-html
                  class={[style.item]}
                  type="div"
                  text={t('lobby.event.sign.topupNeed', {
                    x: brazilianCurrencyFormatter(havePay),
                    y: brazilianCurrencyFormatter(needPay)
                  }).toString()}
                />
              )}
              {!!needBet && (
                <inner-html
                  class={[style.item]}
                  type="div"
                  text={t('lobby.event.sign.betNeed', {
                    x: brazilianCurrencyFormatter(haveBet),
                    y: brazilianCurrencyFormatter(needBet)
                  }).toString()}
                />
              )}
            </div>
          )}
          {this.signStore.errorInfo && (
            <div class={style.receiveRight}>
              <div class={style.item}>
                <span>
                  {occupierReplaceVNode(
                    t('lobby.event.sign.topupNeed') as string,
                    '<span>{x}<span>/{y}</span></span>',
                    <NetRetry
                      class={style.netRetry}
                      loading={this.state.refreshing}
                      axiosError={this.signStore.errorInfo}
                      displayType={DisplayType.InsufficientSpace}
                      onRetry={this.refresh}
                    ></NetRetry>
                  )}
                </span>
              </div>
              <div class={style.item}>
                <span>
                  {occupierReplaceVNode(
                    t('lobby.event.sign.betNeed') as string,
                    '<span>{x}<span>/{y}</span></span>',
                    <NetRetry
                      class={style.netRetry}
                      loading={this.state.refreshing}
                      axiosError={this.signStore.errorInfo}
                      displayType={DisplayType.InsufficientSpace}
                      onRetry={this.refresh}
                    ></NetRetry>
                  )}
                </span>
              </div>
            </div>
          )}
        </div>
        {canRecoup && (
          <div class={style.receiveTips}>{t('lobby.event.sign.signTip')}</div>
        )}
      </div>
    )
  }

  /**
   * 签到列表项
   */
  private renderItem(item: ApiEventSignItem) {
    const { t } = useI18n()

    const tooltipsCompPositionTop = BusinessUtils.px2rem(-15)
    const tooltipsCompPositionRight = BusinessUtils.px2rem(-15)

    const hasSign = item.signStatus === SignStatusEnum.SignIn
    const itemCanClick =
      (this.event.status as number) === SignStartStatusEnum.Normal &&
      !hasSign &&
      item.day <= this.activeData.signDay

    // 已签到、可签到之后显示天数
    const showDayNum = hasSign || item.day > this.activeData.signDay

    return (
      <div
        class={[style.item, 'sign-item']}
        style={{
          cursor:
            this.buttonEnable(itemCanClick) && !this.notReceiveStatus
              ? 'pointer'
              : 'default'
        }}
        onClick={() => this.handleSign(item)}
      >
        {item?.extraPrize ? (
          <TooltipsComp
            charge={1}
            top={tooltipsCompPositionTop}
            right={tooltipsCompPositionRight}
            text={
              <span class={style.extraPrize}>
                {t('lobby.event.sign.extraPrize')}
              </span>
            }
          />
        ) : null}

        <RewardPattern hasSign={hasSign} chestUrl={item.icon}></RewardPattern>
        <RewardDescription
          hasSign={hasSign}
          text={
            hasSign
              ? brazilianCurrencyFormatter(item.prize)
              : (item.rewardType === SignRewardTypeEnum.Fixed &&
                  brazilianCurrencyFormatter(item.prize)) ||
                item.text
          }
        ></RewardDescription>

        <div class={[style.caption]}>
          {showDayNum
            ? t('lobby.event.sign.dayCount', {
                x: item.day,
                X: item.day
              })
            : this.renderSignBtn(item)}
        </div>
      </div>
    )
  }

  /**
   * 签到按钮
   * @returns
   */
  private renderSignBtn(item: ApiEventSignItem) {
    const { t } = useI18n()
    const { isRecoup, signStatus } = item
    const btnText =
      isRecoup && signStatus === SignStatusEnum.UnSignIn
        ? t('lobby.event.sign.reSigning')
        : this.getReceiveText(signStatus)
    return (
      <Button.Tsx
        disabled={signStatus !== SignStatusEnum.UnSignIn}
        type={'success'}
        class={style.signBtn}
      >
        <AutoShrinkText text={btnText as string} />
      </Button.Tsx>
    )
  }

  /**
   * 签到列表
   * @returns
   */
  private renderList() {
    const { t } = useI18n()
    const { signDays, receiveReward } = this.activeData
    return (
      <div class={[style.list, 'sign-list']}>
        {(this.signList || []).map(this.renderItem)}

        <div class={style.refreshBox}>
          <inner-html
            type="span"
            text={t(
              `lobby.event.sign.${this.isGrandMode ? 'grand2' : 'continued2'}`,
              {
                x: signDays
              }
            ).toString()}
          />
          <span>、 </span>
          <inner-html
            type="span"
            text={t('lobby.event.sign.accumulated', {
              x: brazilianCurrencyFormatter(receiveReward || 0)
            }).toString()}
          />
          <span class={style.refresh} onClick={this.refresh}>
            <icon-sprite
              class={[
                GlobalConst.CssClassName.Animate.Spin,
                GlobalConst.CssClassName.Animate.Repeat.infinite,
                this.state.refreshing
                  ? GlobalConst.CssClassName.Animate.Animated
                  : ''
              ]}
              sprite={createSvgSprite('comm_icon_retry')}
            />
          </span>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div class={[style.wrapper, 'sign-box']}>
        {this.renderSummary()}
        {
          <NetRetry
            class={[this.signStore.errorInfo ? style.list : '']}
            loading={this.state.refreshing}
            axiosError={this.signStore.errorInfo}
            displayType={DisplayType.ListData}
            onRetry={this.refresh}
          >
            {this.renderList()}
          </NetRetry>
        }
        {this.event.id && (
          <PublicIntroduction
            class={style.introduction}
            eventData={this.event}
            isShowBottom={false}
          />
        )}
        <SuccessMsssageModal ref={'successMsssageModal'} />
      </div>
    )
  }
}
