import { AxiosError } from 'axios'
import { Currency } from '@/config/currencies.config'
import { CustomPlatformId, SelfOpertedId } from '@/views/game/consts/platforms'
import {
  DOMAIN_STRING_REGEX,
  GENERAL_DOMAIN_REGEX
} from '@/config/regexp.config'
import { Debounce } from '@/utils/Decorator'
import { GameCategoryIds } from '@/views/game/consts/categorys'
import { GameItemInter } from '@/service/game/entity/gameItem'
import { GameType, HasRateType, SelfOperatedGameUrl } from '@/api/game/type'
import { GlobalConst, GlobalEvents, GlobalService } from '@/context'
import { GlobalEventActionsTypeEnum } from '@/context/events'
import { Language, useI18n } from '@/i18n'
import { LobbyService } from '@/context/network'
import { RouteName } from '@/router/config'
// import { SearchResultId } from '@/views/selfoperated-games/sports/const'
import { SelfSportId } from '@/views/selfoperated-games/sports/const'
import { UserInfos } from '@/api/common/type'
import { VerifyScene } from '@/views/global-modal/modal/verify-bind-phone/const'
import {
  apiGameLogout,
  apiGetGameUrl,
  apiGetLastGameInfo,
  apiSelfOpertedGameLogout
} from '@/api/game'
import { hasDecimal, times } from '@/utils/MathUtils'
import {
  isAndroidThirdPartyApp,
  isSpecialIOS,
  isThirdPartyApp
} from '@/utils/UserAgent'
import { isHtml } from '../utils/index'
import { isWgPackage, windowConfig } from '@/utils/window'
import { merge, pick, remove } from 'lodash'
import { message } from 'ant-design-vue'
import { onAppEvent, windowOpen } from '@/utils/business-utils'
import { raf, urlFormatter } from '@/utils/Tool'
import { trace } from '@/context/tacker'
import { useGameStore } from '@/views/game/store'
import { useMainStore } from '@/store/index'
// import { useSportsStore } from '@/views/selfoperated-games/sports/store'
import CryptoJS from 'crypto-js'
import DownloadAppForceByChannel from '@/layouts/components/downloadApp/download-app-force-by-channel'
import DownloadAppNotRequireForceByChannel from '@/layouts/components/downloadApp/download-app-not-require-force-by-channel'

import Modal from '@/controller/Modal'
import SaveShortcut from '@/layouts/components/saveShortcut/components/save-shortcut-by-channel'
// import actionHandler from '@/views/game/classes/gameCategory/actionHandler'
import Logger from '@/utils/Logger'
import router from '@/router'
import to from 'await-to-js'

interface GameInfo {
  gameName: string
  gameUrl: string
}

interface ServiceErrType {
  data: {
    code?: number
    msg: string
  }
}

/** 点击卡片的事件,所对应的需要的属性 */
type GameInstance = {
  /** 游戏id */
  gameId: number
  /** 平台id */
  platformId: number
  /** 平台名 */
  platformName: string
  /** 游戏分类id */
  gameCategoryId: number
  /** 是否支持试玩 0-否, 1-是 */
  demoModeSupport: boolean
  /** 游戏名称(游戏有,平台无) */
  gameName?: string
  /** 游戏打开方式 0-内嵌, 1-外链 (平台有,游戏没有) */
  isExternalLink?: boolean
  /** 平台名称(平台有,游戏无) */
  brand?: string
  /** 最小准处金额(平台有,游戏无) */
  minimumEntryBalance?: number
  /** 跳转外链 */
  externalLink?: string
  /** 是否主播热门 */
  isAnchorHot?: boolean
  /** 游戏类型 */
  gameType: number
}

class GameOpener {
  /** 储存跳转外链 html 数据的关键字和当前对象的标记。 */
  private HTML_DATA_KEY = 'web_lobby_htmlData'
  private MY_MARKER = 'web_lobby_myMarker'

  /** 监听弹窗的三方游戏关闭时执行下分和更新金币 */
  private tryUpdatePrize(win?: Window) {
    const { setGameScoring, setGamePlaying } = useGameStore()
    const { logoutAndUpdatePrize } = useMainStore()
    if (win) {
      const loop = () => {
        if (win?.closed) {
          setGameScoring(false)
          setGamePlaying(false)
          logoutAndUpdatePrize()
        } else {
          raf(loop)
        }
      }
      loop()
    }
  }

  private navigateToUrlAndUpdatePrize(href: string, win?: Window) {
    if (win) {
      win.opener = null
      const queryParams = isHtml(href)
        ? `htmlKey=${this.HTML_DATA_KEY}`
        : `to=${encodeURIComponent(href)}`
      win.location = `${location.origin}/pages/game/index.html?${queryParams}`
      this.tryUpdatePrize(win)
    } else {
      const dom = document.createElement('a')
      dom.setAttribute('href', href)
      dom.setAttribute('target', '_blank')
      dom.setAttribute('rel', 'noopener noreferrer')
      dom.style.display = 'none'
      document.body.appendChild(dom)
      dom.click()
    }
  }

  private decrypt(value: string): string {
    return CryptoJS.AES.encrypt(
      value,
      CryptoJS.enc.Utf8.parse('thanks,pig4cloud'),
      {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      }
    ).toString()
  }

  /** 是否为自营游戏 */
  private isSelfOpertedGame(platformId: number) {
    return platformId === SelfOpertedId
  }

  /** 生成游戏信息对象 */
  private generateGameInfo(
    game: GameInstance,
    lastGameInfo: SelfOperatedGameUrl
  ): GameInfo {
    const { userInfos, ossHost, language, apiBaseUrl, getCurrencyInfoByCode } =
      useMainStore()
    const { showRidTypes, hasLuckyBet } = useGameStore()
    const sessionkey = this.decrypt(userInfos?.session_key ?? '')
    const userkey = this.decrypt(userInfos?.userkey ?? '')

    const userCurrencyCode = userInfos?.currency
    const currencyInfo = getCurrencyInfoByCode(userCurrencyCode)
    const gameUrl = lastGameInfo?.game_url ?? ''

    // 检查当前地址是否包含域名
    const hasDomain = DOMAIN_STRING_REGEX.test(gameUrl)

    // 如果返回地址有域名而使用域名的，否则走拼接逻辑
    const gameDomain = hasDomain
      ? gameUrl.match(GENERAL_DOMAIN_REGEX)?.[0]
      : `${apiBaseUrl}/gogamesac/`

    const wesocketGameDomain = gameDomain?.replace('https://', 'wss://')

    let selfOperatedGameUrl = urlFormatter(gameUrl ?? '', {
      isExternalLink: game?.isExternalLink ? 1 : 0,
      currencyCode: userCurrencyCode,
      marketCurrencyCode: currencyInfo?.marketCurrencyCode,
      gameId: lastGameInfo?.gameid || lastGameInfo?.gameId,
      route: wesocketGameDomain,
      lang: GlobalConst.ServiceLanguageMap[language as Language],
      sessionkey,
      userkey,
      try: userInfos?.username,
      rid: showRidTypes?.length
        ? Number(!showRidTypes.includes(game.gameCategoryId))
        : Number(!hasLuckyBet),
      timestamp: new Date().getTime(),
      ossDomain: ossHost,
      lobbyDomain: location.origin
    })

    if (!hasDomain) {
      selfOperatedGameUrl = selfOperatedGameUrl.replace(
        /^gogamesac\//,
        // cocos调试地址用
        window?.lobby_game_url || apiBaseUrl + '/gogameccc/'
      )
    }

    /** 是否为自营游戏 */
    const isSelfOperatedGame = this.isSelfOpertedGame(game.platformId)

    /** 三方游戏不需要组装url信息 */
    let gameUrlToUse = isSelfOperatedGame ? selfOperatedGameUrl : gameUrl

    /** 自营游戏cocos调试地址用 */
    if (isSelfOperatedGame && window?.lobby_game_url && hasDomain) {
      gameUrlToUse = gameUrlToUse.replace(
        GENERAL_DOMAIN_REGEX,
        window.lobby_game_url
      )
    }

    return {
      gameName: lastGameInfo?.gameName ?? '',
      gameUrl: gameUrlToUse
    }
  }

  /** 获取自营游戏信息 */
  private async getSelfOperatedGameInfo(game: GameInstance): Promise<GameInfo> {
    const { hasLogined } = useMainStore()
    const { platformId, gameId } = game
    const [error, data] = await to(
      apiGetGameUrl({
        gameid: gameId,
        platfromid: platformId as number,
        user_type: hasLogined ? GameType.FORMAL : GameType.DEMO
      })
    )
    if (error || !data?.game_url) {
      return Promise.reject(error)
    }

    const gameInfo = this.generateGameInfo(game, data as SelfOperatedGameUrl)
    return gameInfo
  }

  /** 获取三方游戏信息 */
  private async getThreeWayGameInfo(game: GameInstance): Promise<GameInfo> {
    const [error, data] = await to(
      apiGetGameUrl({
        gameid: game.gameId,
        platfromid: game.platformId as number,
        // 三方外链游戏才传
        exitUrl: game?.isExternalLink ? location.origin : ''
      })
    )
    if (error) {
      return Promise.reject(error)
    }
    const gameInfo: GameInfo = {
      gameName: data?.gameName ?? '',
      gameUrl: data?.game_url ?? ''
    }

    return gameInfo
  }

  /** 处理错误打开或平台已关闭的游戏 */
  private handleErrorOrClosedGame(game: GameInstance) {
    const {
      setAllGameList,
      allGameList
      // getGameListByCategoryAndPlatform
      // setGameCategoryPlatformList
    } = useGameStore()
    // const platformGameList = getGameListByCategoryAndPlatform({
    //   gameCategoryId: game.gameCategoryId,
    //   platformId: game.platformId
    // }).list

    // 清楚子游戏请求缓存
    GlobalService.cache.cancelCacheUrls(['/api/game/hall/listPlatformGameV2'])

    // 清楚类别对应子游戏列表缓存
    // setGameCategoryPlatformList({
    //   gameCategoryId: game.gameCategoryId,
    //   platformId: game.platformId,
    //   gameList: remove(
    //     platformGameList,
    //     (item) => item.platformId !== game.platformId
    //   )
    // })

    // 清楚所有游戏对应平台缓存
    setAllGameList(
      remove(allGameList, (item) => item.platformId !== game.platformId),
      true
    )
  }

  /** 根据不同错误code特殊处理 */
  private handleServiceCode(error: ServiceErrType, game: GameInstance) {
    const serviceCode = error.data?.code
    const LobbyServiceCode = LobbyService.SERVICE_CODE
    const { t } = useI18n()
    switch (serviceCode) {
      case LobbyServiceCode.DEMO_GAME_LINE_DISABLED:
      case LobbyServiceCode.DEMO_GAME_DISABLED:
      case LobbyServiceCode.TEST_REGISTER_GAME:
        // 试玩游戏没开线路 || 不支持试玩，弹出注册界面
        message.destroy.call(message)
        Modal.openLoginRegisterModal({
          onLoginSuccess: () => {
            return this.navigateToGame(game)
          }
        })
        break
      case LobbyServiceCode.SMS_CODE_GAME:
        // 进入游戏前需要验证手机号码
        Modal.open('veriryBindPhone', {
          verifyScene: VerifyScene.DEPOSIT_GAME_SMS_VERIFY,
          onSuccess: () => this.navigateToGame(game)
        })
        break
      case LobbyServiceCode.FIRST_RECHARGE:
        // 进入游戏前需要首次充值
        message.destroy.call(message)
        const payPromptModal = Modal.create({
          title: t('lobby.common.tips.title'),
          titlePosition: 'center',
          content: () => t('lobby.game.needFirsstRecharge'),
          okText: t('lobby.center.setting.goRecharge') as string,
          onOk: () => {
            payPromptModal.destroy()
            Modal.open('payModal')
          }
        })
        break
      case LobbyServiceCode.DOWNLOAD_APP:
        message.destroy.call(message)
        GlobalEvents.dispatch({ type: 'DOWNLOAD_APP_BY_AFTER_CLICK_GAME' })
        break
      case LobbyServiceCode.DOWNLOAD_APP_CHANNEL_BY_CALL_GAMEAPI:
        message.destroy.call(message)
        GlobalEvents.dispatch({ type: 'DOWNLOAD_APP_REQUIRE_FORCE_BY_CHANNEL' })
        break
      case LobbyServiceCode.BIND_WITHDRAW:
        // 进入游戏前需要绑定提现方式
        Modal.create({
          title: t('lobby.common.tips.title'),
          titlePosition: 'center',
          content: () => t('lobby.game.needBindBankCard'),
          okText: t('lobby.center.setting.goBind') as string,
          onOk: async () => {
            router.push({
              name: RouteName.WITHDRAW,
              query: {
                current: '3'
              }
            })
          }
        })
        break
      default:
        this.handleErrorOrClosedGame(game)
        const { userInfos } = useMainStore()
        // 游戏上分失败埋点上报
        trace('game-login-fail', {
          errInfo: error,
          serviceCode,
          useInfo: pick(userInfos, ['game_gold', 'username', 'platfromid']),
          gameParameters: JSON.stringify(game)
        })
        break
    }
  }

  /** 获取游戏信息 */
  private async queryGameInfo(game: GameInstance): Promise<GameInfo> {
    const { setGameScoring, setGamePlaying, setGameOpening } = useGameStore()
    // 上分状态锁定
    setGameScoring(true)
    let gameInfo: GameInfo | null = null
    try {
      if (this.isSelfOpertedGame(game.platformId)) {
        gameInfo = await this.getSelfOperatedGameInfo(game)
      } else {
        gameInfo = await this.getThreeWayGameInfo(game)
      }
      GlobalEvents.dispatch({
        type: 'GAME_LOGIN_SUCCESS',
        payload: {
          gameInfo: game as GameItemInter,
          gameUrl: gameInfo?.gameUrl
        }
      })

      setGameScoring(false)
    } catch (error) {
      const data = error as ServiceErrType
      this.handleServiceCode(data, game)
      setGameScoring(false)
      setGameOpening(false)
      setGamePlaying(false)
      throw error
    }
    return gameInfo
  }

  /** 处理不同设备外链打开情况 */
  private async openGameInExternalLink(
    game: GameInstance,
    lastGameInfo?: SelfOperatedGameUrl
  ) {
    const { logoutAndUpdatePrize } = useMainStore()

    // 异步函数，根据是否有lastGameInfo返回相应的Promise
    const getGameInfoAsync = async () => {
      if (lastGameInfo) {
        return this.generateGameInfo(game, lastGameInfo)
      } else {
        return this.queryGameInfo(game)
      }
    }

    // 马甲包环境
    if (isWgPackage) {
      const gameInfo = await getGameInfoAsync()
      if (gameInfo?.gameUrl) {
        // 马甲包游戏关闭回调执行游戏退出下分
        window.closeGame = () => {
          logoutAndUpdatePrize()
        }
        windowOpen(gameInfo.gameUrl)
      }
    } else {
      let win: Window | null

      /**
       *  特殊处理 ins 兼容性问题
       *  ins ios手机需要先跳转空白窗口，无法先跳转/pages/game/index.html再打开游戏，会被当广告拦截
       *  只适用于 ios，anroid 如果先打开窗口再请求接口无法跳出游戏
       *  目前 ua 里面 ins 浏览器没有 instagram 标识了，目前找到的区别是判断 ua 里面没有 Safari
       */
      if (isSpecialIOS) {
        win = window.open('', '_blank')
      } else if (!isThirdPartyApp) {
        // 非ins，anroid，ios pc都可以直接跳转外链窗口再赋值
        win = window.open('/pages/game/index.html', '_blank')
      }

      getGameInfoAsync()
        .then((data) => {
          // 安卓 ins 不先打开窗口，无法跳出游戏
          if (isAndroidThirdPartyApp) {
            win = window.open('/pages/game/index.html', '_blank')
          }

          if (data.gameUrl && win) {
            const origin = isSpecialIOS ? window : win
            // 存储标记，主要用于在真机上后退的时候能直接回到大厅
            origin?.sessionStorage?.setItem(this.MY_MARKER, 'true')

            // html格式数据可能过长，浏览器会报414, 故改为sessionStorage
            if (isHtml(data?.gameUrl)) {
              origin?.sessionStorage?.setItem(this.HTML_DATA_KEY, data.gameUrl)
            }

            setTimeout(() => {
              this.navigateToUrlAndUpdatePrize(data.gameUrl, win as Window)
            }, 100)
          } else {
            Logger.myDebug('queryGameInfo gameUrl error：', data, win)
            win?.close()
          }
        })
        .catch((error) => {
          Logger.myDebug('queryGameInfo error：', error)
          win?.close()
        })
    }
  }

  /** 内嵌打开游戏 */
  private async openGameInEmbedded(
    game: GameInstance,
    lastGameInfo?: SelfOperatedGameUrl
  ) {
    const { setPreGameEmbeddedParams, gameParameters, setGameParameters } =
      useGameStore()
    // 跳转前的路由信息，部分机型三方游戏跳转游戏内嵌页面，返回回不到原有路由
    setPreGameEmbeddedParams({
      name: router.currentRoute.name as string,
      query: router.currentRoute.query
    })

    const { gameCategoryId, platformId } = game
    setGameParameters({
      url: '',
      platformId,
      name: game.gameName || game?.brand || ''
    })

    router.push({
      name: RouteName.GAME_EMBEDDED,
      query: {
        platformId: String(platformId),
        gameCategoryId: String(gameCategoryId)
      }
    })

    try {
      const gameInfo = lastGameInfo
        ? // 处理继续游戏
          this.generateGameInfo(game, lastGameInfo)
        : await this.queryGameInfo(game)
      if (gameInfo.gameUrl) {
        const { gameUrl, gameName } = gameInfo
        setGameParameters({
          ...gameParameters,
          url: gameUrl,
          gameInfo: game as GameItemInter,
          name: gameName,
          platformId
        })
      }
    } catch (error) {
      setGameParameters(undefined)
      // 接口返回报错自动跳回上一页面并提示报错信息
      router.replace(useGameStore().preGameEmbeddedParams)
    }
  }

  /** 是否应在外部链接中打开游戏 */
  private shouldOpenInExternalLink(game: GameInstance) {
    const PGPlatformIdList = [14, 200]
    // 马甲包pg电子(html方式)或在第三方app（ins、facebook等）强制内嵌
    return (
      (windowConfig.isWgPackage &&
        PGPlatformIdList.includes(game?.platformId as number)) ||
      isAndroidThirdPartyApp ||
      isSpecialIOS
    )
  }

  /** 处理继续游戏提示框 */
  private handleContinueGamePrompt(
    game: GameInstance,
    lastGameInfo?: SelfOperatedGameUrl
  ) {
    const { t } = useI18n()
    const { logoutAndUpdatePrize } = useMainStore()
    const { setGamePlaying } = useGameStore()
    Modal.create({
      title: t('lobby.common.tips.title'),
      titlePosition: 'left',
      closable: true,
      content: t('lobby.game.continueGameTips'),
      onCancel: async () => {
        setGamePlaying(false)
        await logoutAndUpdatePrize(true)
        this.navigateToGame(game)
      },
      cancelText: t('lobby.game.enterCurrenctGame') as string,
      okText: t('lobby.game.continueGame') as string,
      onOk: () => {
        setGamePlaying(false)
        game.gameName = lastGameInfo?.gameName
        game.gameId = Number(lastGameInfo?.gameId)
        game.platformId = Number(lastGameInfo?.platfromId)
        game.gameCategoryId = Number(lastGameInfo?.categoryId)
        this.navigateToGame(game, lastGameInfo)
      }
    })
  }

  /** 根据不同运行环境打开游戏方式 */
  private async navigateToGame(
    game: GameInstance,
    lastGameInfo?: SelfOperatedGameUrl
  ) {
    const {
      isGameOpening,
      setGameOpening,
      setGamePlaying,
      setTryModeRecentGameListData
    } = useGameStore()
    const { isTryMode } = useMainStore()

    // 如果游戏正在打开中，则直接返回
    if (isGameOpening) return
    setGameOpening(true)

    // 多个页签游戏中，可以直接点其他游戏，需要强制先执行下分
    if (useGameStore().isGamePlaying) {
      const [_err, data] = await to(apiGetLastGameInfo())
      if (_err) {
        console.error(_err)
      }
      setGameOpening(false)

      if (data?.lastGameInfo) {
        // 弹出继续游戏提示框
        this.handleContinueGamePrompt(game, data.lastGameInfo)
      } else {
        setGamePlaying(false)
        this.navigateToGame(game)
      }
      return
    }

    setGamePlaying(true)

    if (this.shouldOpenInExternalLink(game)) {
      game.isExternalLink = false
    }

    onAppEvent('enterGame')
    GlobalEvents.dispatch({
      type: 'MONITOR_EVENT',
      eventName: 'IndicatorC',
      payload: {}
    })

    // 外链打开
    if (game?.isExternalLink) {
      await this.openGameInExternalLink(game, lastGameInfo)
    } else {
      // 内嵌打开
      await this.openGameInEmbedded(game, lastGameInfo)
    }

    setGameOpening(false)

    // 试玩账号存储打开过的试玩游戏至最近游戏store
    if (isTryMode) {
      setTryModeRecentGameListData(game as GameItemInter)
    }
  }

  /** 继续游戏弹框(游戏中没正常退出的引导重新进入游戏) */
  public continueGameDialog(lastGameInfo: SelfOperatedGameUrl) {
    if (router.app.$route.name !== RouteName.GAME_EMBEDDED) {
      const { setGameScoring } = useGameStore()
      const { logoutAndUpdatePrize } = useMainStore()
      setGameScoring(true)
      const { t } = useI18n()
      const modal = Modal.create({
        title: t('lobby.common.tips.title'),
        titlePosition: 'left',
        content: () => t('lobby.game.gameContinueTips'),
        onCancel: () => {
          setGameScoring(false)
          logoutAndUpdatePrize(true)
        },
        onOk: () => {
          const gameInfo = {
            gameId: lastGameInfo.gameId,
            platformId: lastGameInfo.platfromId,
            categoryId: lastGameInfo.categoryId,
            gameCategoryId: lastGameInfo.categoryId,
            gameName: lastGameInfo.gameName,
            isExternalLink: false
          } as unknown as GameInstance
          this.navigateToGame(gameInfo, lastGameInfo)
          modal.destroy()
        }
      })
    }
  }

  /** 退出游戏（下分） */
  public async gameLogout() {
    const { updateUserInfos, userInfos } = useMainStore()
    const { gameParameters, setGameScoring, setGameLogoutNetError } =
      useGameStore()
    // 是否为 wg 自营平台
    const isSelfOpertedId = this.isSelfOpertedGame(
      gameParameters?.platformId as number
    )
    const payload = { platformId: gameParameters?.platformId }
    const [err, data] = await to(
      isSelfOpertedId
        ? apiSelfOpertedGameLogout(payload)
        : apiGameLogout(payload)
    )
    // 获取接口是否网络错误
    const getErrorType = GlobalService.getErrorType(err as AxiosError)
    setGameLogoutNetError(!!getErrorType)
    if (err) {
      // 退出游戏过程中发生错误
      setGameScoring(true)
      trace('game-logout-fail', {
        errInfo: err,
        isSelfOpertedId,
        useInfo: pick(userInfos, ['game_gold', 'username', 'platfromid']),
        gameParameters: JSON.stringify(gameParameters)
      })
      throw err
    }

    if (data) {
      const { lastgameinfo, game_gold, bonus, totalGold, bonusRequireBet } =
        data
      // 下分失败，询问是否继续游戏
      if (lastgameinfo) {
        this.continueGameDialog(lastgameinfo)
      } else {
        setGameScoring(false)
      }

      // 更新用户金币
      updateUserInfos(
        merge({}, useMainStore().userInfos, {
          game_gold,
          bonus,
          totalGold,
          bonusRequireBet
        })
      )
    }
  }

  /** 跳转平台页面 */
  private goToPlateform(item: GameInstance) {
    const { platformId, gameCategoryId } = item
    const { setActiveTabkey } = useGameStore()
    setActiveTabkey(Number(`${gameCategoryId}${CustomPlatformId}`))
    router.push({
      name: RouteName.SUB_GAME,
      query: {
        gameCategoryId: `${gameCategoryId}`,
        platformId: `${platformId}`
      }
    })
  }

  /**
   * 处理自营体育的跳转涉及到三个业务场景
   * 在首页的业务场景是只需要滚动
   * 在WG体育内页的场景 只需要切换tab
   * 在WG在其他内页的场景，需要跳到首页的WG体育
   */
  private handleSelfSportJump() {
    // const { setGameCategoryId, gameCategoryId } = useSportsStore()
    // const routeName = router.currentRoute.name

    router.push({
      name: RouteName.WG_GAME,
      query: {
        gameCategoryId: String(GameCategoryIds.Sports)
      }
    })

    // if (routeName === RouteName.GAME) {
    //   actionHandler.scrollMenuToGameCategory(GameCategoryIds.Sports)
    // } else if (
    //   routeName === RouteName.SPORTS_HOME &&
    //   gameCategoryId === SearchResultId
    // ) {
    //   setGameCategoryId(SelfSportId)
    // } else {
    //   router.push({
    //     name: RouteName.GAME,
    //     query: {
    //       gameCategoryId: String(GameCategoryIds.Sports)
    //     }
    //   })
    // }
  }

  /** 处理主播号内嵌外链跳转 */
  private handleAnchorHotExternalLink(game: GameInstance) {
    const externalLink = game?.externalLink ?? ''
    const url = externalLink.includes('://')
      ? externalLink
      : 'https://' + externalLink
    // 外链打开
    if (game.isExternalLink) {
      windowOpen(url)
    } else {
      // 内嵌打开
      const { gameParameters, setGamePlaying, setGameParameters } =
        useGameStore()
      const { gameName, platformId, gameCategoryId } = game
      setGamePlaying(true)
      setGameParameters({
        ...gameParameters,
        url,
        gameInfo: game as GameItemInter,
        name: gameName ?? '',
        platformId
      })

      router.push({
        name: RouteName.GAME_EMBEDDED,
        query: {
          platformId: platformId?.toString(),
          gameCategoryId: gameCategoryId?.toString()
        }
      })
    }
  }

  /** 打开游戏
   * @param _game 暂时先加上Partial,避免报错,因为老版本的入参,有一些字段是可选项,必须报一堆类型错误
   */
  @Debounce(500)
  public async openGame(_game: Partial<GameInstance>) {
    const game = _game as GameInstance
    const { userInfos, hasLogined, getCurrencyInfoByCode, isDemoMode } =
      useMainStore()
    const { platformExchangeObj } = useGameStore()
    const agent: UserInfos['account_type'] = 5 //代理商
    const { t } = useI18n()

    if (window.ReporterMananger?.searchStatus) {
      const { searchStatus } = window.ReporterMananger
      GlobalEvents.dispatch({
        type: 'REPORT_MANAGER',
        eventName: 'ClickSearchResult',
        payload: {
          key_word: searchStatus?.key_word,
          result_number: searchStatus?.gameList?.length ?? 0,
          position_number: searchStatus?.gameList?.findIndex(
            (el) => el.gameId == _game.gameId
          ),
          game_machine_type: _game.gameCategoryId,
          game_machine_name: _game.gameName,
          game_machine_id: _game.gameId,
          game_machine_cp: _game.platformName
        }
      })
      window.ReporterMananger?.updateSerachStatus()
    }

    // 主播号数据展示在热门游戏列表当中,特殊处理
    if (isDemoMode && game.isAnchorHot) {
      this.handleAnchorHotExternalLink(game)
      return
    }

    // 没有游戏id，当平台处理跳转二级页面
    if (!game.gameId) {
      this.goToPlateform(game as GameInstance)
      return
    }
    // 自营WG体育，需要特许处理，在大厅首页跳转到体育位置
    if (game.gameId === SelfSportId) {
      this.handleSelfSportJump()
      return
    }

    // 进入游戏前需要判断是否有游戏限制包含是否需要通过桌面快捷方式打开，或者通过APP打开
    if (SaveShortcut.canOpenSaveShortCutByChannel) {
      GlobalEvents.dispatch({
        type: GlobalEventActionsTypeEnum.PWA_INSTALL_BY_CHANNEL
      })
      return
    }
    // 进入游戏之前需要检车是否配置了渠道APP下载-放开强制
    if (DownloadAppNotRequireForceByChannel.canOpenDownloadAppByChannel) {
      GlobalEvents.dispatch({
        type: GlobalEventActionsTypeEnum.DOWNLOAD_APP_NOT_REQUIRE_FORCE_BY_CHANNEL
      })

      return
    }
    // 进入游戏之前需要检车是否配置了渠道APP下载-要求强制
    if (DownloadAppForceByChannel.canOpenDownloadAppByChannel) {
      GlobalEvents.dispatch({
        type: GlobalEventActionsTypeEnum.DOWNLOAD_APP_REQUIRE_FORCE_BY_CHANNEL
      })

      return
    }

    // 未登录试玩游戏弹框提醒
    if (!hasLogined) {
      // 试玩游戏弹提示框
      if (game.demoModeSupport) {
        const modal = Modal.create({
          title: t('lobby.common.tips.title'),
          titlePosition: 'center',
          content: t('lobby.game.openDemoGameTip'),
          contentPosition: 'center',
          okText: t('lobby.game.demo') as string,
          cancelText: t('lobby.modal.task.close') as string,
          onOk: () => {
            modal.destroy()
            this.navigateToGame(game)
          }
        })
        return
      }

      //未登录打开游戏默认提示注册框
      Modal.openLoginRegisterModal({
        onLoginSuccess: () => {
          return this.navigateToGame(game)
        }
      })
      return
    }

    // 余额不足弹框提醒
    if (
      !isDemoMode &&
      userInfos &&
      userInfos?.game_gold < (game?.minimumEntryBalance || 0)
    ) {
      const userCurrencyCode = userInfos.currency
      const currencyInfo = getCurrencyInfoByCode(userCurrencyCode)
      const currencySymbol =
        Currency.currenciesFormatConfig()[userCurrencyCode]?.symbol ||
        currencyInfo?.currencySign
      Modal.message({
        type: 'warning',
        content: t('lobby.game.goldInadequate', {
          gold: `${currencySymbol}${game.minimumEntryBalance}`
        })
      })
      return
    }

    // 下分接口（更新金币）还没完成不允许上分
    // 暂时拿掉前端拦截
    // if (isFetchingPrize) {
    //   Modal.message({
    //     type: 'warning',
    //     content: t('lobby.game.fetchingPrize') + `(${localStorage.getItem('prizelock')})`
    //   })
    //   return
    // }

    // 银商账号点击三方游戏提示无权限
    if (
      game.platformId !== SelfOpertedId &&
      userInfos?.account_type === agent
    ) {
      Modal.message({
        type: 'warning',
        content: t('lobby.game.nopermission')
      })
      return
    }

    // 三方额度汇率比例不对弹框提醒
    const platformCurrency = `${game?.platformId}_${userInfos?.currency}`
    const proportion =
      platformExchangeObj?.platformCurrencyExchange[platformCurrency]
    if (proportion && proportion !== HasRateType.Have && userInfos) {
      // 站点限制金额
      const siteAmountLimit =
        platformExchangeObj?.memberIntoThirdLimit[userInfos.currency]
      // 用户带入展示金额（*汇率）
      const userConvertAmount = userInfos?.game_gold
      // 用户金额与限制金额取小
      const amount =
        userConvertAmount < siteAmountLimit
          ? userConvertAmount
          : siteAmountLimit

      const convertedAmount = times(amount, proportion)
      // 有浮点数就保留两位小数
      let modalContentAmount = hasDecimal(convertedAmount)
        ? Math.floor(Number(convertedAmount) * 100) / 100
        : Number(convertedAmount)

      // 105 MP 19 沙巴 22FG 27KA 特殊保留一位小数
      if ([105, 19, 22, 27].includes(game.platformId)) {
        modalContentAmount = Math.floor(Number(convertedAmount) * 10) / 10
      }

      const modal = Modal.create({
        title: t('lobby.common.tips.title'),
        titlePosition: 'center',
        content: t('lobby.game.nounConversionPrompt', {
          balance: amount,
          proportion: `1:${proportion}`,
          amount: modalContentAmount
        }),
        contentPosition: 'center',
        onOk: () => {
          modal.destroy()
          this.navigateToGame(game)
        }
      })
      return
    }

    this.navigateToGame(game)
  }
}

export const _instance = new GameOpener()
