import { ValueOf } from 'type-fest'
import { nodeThemeConfig } from '@/context/const'

/**
 * 厂商注单id
 */
export const VendorId = 'n3z6ng1tvc'
/**
 * 默认赔率
 */
export const DefaultOddsType = 3
export const DefualtPageSize = 6 //分页数量
/**
 * 最多可串关数量
 */
export const MaxParlayLenght = 10
/**
 * 显示锁赔率常量
 */
export const LockPrice = -10000
/** 详情页面中部导航与竖直滚动联动常量，用于id 和 类名 */
export const ScrollIdPrefix = 'betTypeScrollFlag'
/** 下注弹框中部导航与竖直滚动联动常量，用于id 和 类名 */
export const ScrollIdPrefixInPop = 'betTypeScrollFlagInPop'

/**自营体育Id */
export const SelfSportId = 10000
/**全部体育 */
export const AllSportId = 0
/**搜索结果 */
export const SearchResultId = -1

export const wgInHomeSkinType: number[] = [
  nodeThemeConfig.ThemeSkinType.THEME_29,
  nodeThemeConfig.ThemeSkinType.EUROPEAN_AMERICAN
]

export enum Exception {
  /**当前ip没有权限 */
  A003 = 'A003'
}

export enum SportStatusCode {
  Sucess = 200,
  RequestFalied = 400,
  AuthFalied = 401
}

export enum TeamType {
  Home = 'h',
  Away = 'a'
}
/**
 * 兑现状态
 * CannotCashout (目前不支持兑现)
 * NoCashoutTicket (这张注单不能兑现)
 */
export enum CashoutStatus {
  CanCashout = 1,
  CannotCashout = 2,
  NoCashoutTicket = 3,
  TicketNotFound = 4
}
/**
 * 比赛类型id
 */
export const SportType = {
  /***足球*/
  Soccer: 1,

  /***篮球*/
  Basketball: 2,

  /***美式足球*/
  Football: 3,

  /***冰上曲棍球*/
  IceHockey: 4,

  /***网球*/
  Tennis: 5,

  /***排球*/
  Volleyball: 6,

  /***斯诺克/台球*/
  Billiards: 7,

  /***棒球*/
  Baseball: 8,

  /***羽毛球*/
  Badminton: 9,

  /***高尔夫球*/
  Golf: 10,

  /***赛车*/
  Motorsports: 11,

  /***游泳*/
  Swimming: 12,

  /***政治*/
  Politics: 13,

  /***Polo 水球*/
  Water: 14,

  /***跳水*/
  Diving: 15,

  /***拳击*/
  Boxing: 16,

  /***乒乓球*/
  TableTennis: 18,

  /***手球*/
  Handball: 24,

  /***飞镖*/
  Darts: 25,

  /***橄榄球*/
  Rugby: 26,

  /***柔道*/
  Judo: 35,

  /***电子竞技*/
  ESports: 43,

  /***沙滩排球*/
  BeachVolleyball: 45,

  /***金融*/
  Finance: 55,

  /***乐透*/
  Lotto: 56
}

export type SportTypeValueof = ValueOf<typeof SportType>

/**
 * 所有的体育项目名称
 */
export const SportName = {
  /**足球 */
  [SportType.Soccer]: 'lobby.selfoperatedGames.sports.soccer',

  /**篮球 */
  [SportType.Basketball]: 'lobby.selfoperatedGames.sports.basketball',

  /**美式足球 */
  [SportType.Football]: 'lobby.selfoperatedGames.sports.football',

  /**冰上曲棍球 */
  [SportType.IceHockey]: 'lobby.selfoperatedGames.sports.iceHockey',

  /**网球 */
  [SportType.Tennis]: 'lobby.selfoperatedGames.sports.tennis',

  /**排球 */
  [SportType.Volleyball]: 'lobby.selfoperatedGames.sports.volleyball',

  /**斯诺克/台球 */
  [SportType.Billiards]: 'lobby.selfoperatedGames.sports.billiards',

  /**棒球 */
  [SportType.Baseball]: 'lobby.selfoperatedGames.sports.baseball',

  /**羽毛球 */
  [SportType.Badminton]: 'lobby.selfoperatedGames.sports.badminton',

  /**高尔夫球 */
  [SportType.Golf]: 'lobby.selfoperatedGames.sports.golf',

  /**赛车 */
  [SportType.Motorsports]: 'lobby.selfoperatedGames.sports.motorsports',

  /**游泳 */
  [SportType.Swimming]: 'lobby.selfoperatedGames.sports.swimming',

  /**政治 */
  [SportType.Politics]: 'lobby.selfoperatedGames.sports.politics',

  /**Polo	水球 */
  [SportType.Water]: 'lobby.selfoperatedGames.sports.water',

  /** 跳水*/
  [SportType.Diving]: 'lobby.selfoperatedGames.sports.diving',

  /**拳击 */
  [SportType.Boxing]: 'lobby.selfoperatedGames.sports.boxing',

  /**乒乓球 */
  [SportType.TableTennis]: 'lobby.selfoperatedGames.sports.tableTennis',

  /**手球 */
  [SportType.Handball]: 'lobby.selfoperatedGames.sports.handball',

  /**飞镖 */
  [SportType.Darts]: 'lobby.selfoperatedGames.sports.darts',

  /**橄榄球 */
  [SportType.Rugby]: 'lobby.selfoperatedGames.sports.rugby',

  /**柔道 */
  [SportType.Judo]: 'lobby.selfoperatedGames.sports.judo',

  /**电子竞技 */
  [SportType.ESports]: 'lobby.selfoperatedGames.sports.ESports',

  /**沙滩排球 */
  [SportType.BeachVolleyball]: 'lobby.selfoperatedGames.sports.beachVolleyball',

  /**金融 */
  [SportType.Finance]: 'lobby.selfoperatedGames.sports.finance',

  /**乐透 */
  [SportType.Lotto]: 'lobby.selfoperatedGames.sports.lotto'
}

interface sportTypeMenu {
  img: string
  asideSvg?: string // 678体育定制版 侧边栏资源
}

export type SportTypeMapIconType = Record<number, sportTypeMenu>

/**
 * 比赛类型id对应的图片映射关系表
 */
export const SportTypeMapIcon: SportTypeMapIconType = {
  /**足球 */
  [SportType.Soccer]: {
    img: 'sprite_main_wgty_sports_soccer',
    asideSvg: 'icon_dtfl_soccer_0'
  },
  /**篮球 */
  [SportType.Basketball]: {
    img: 'sprite_main_wgty_sports_basketball',
    asideSvg: 'icon_dtfl_basketball_0'
  },
  /**美式足球 */
  [SportType.Football]: {
    img: 'sprite_main_wgty_sports_usa_football',
    asideSvg: 'icon_dtfl_football_0'
  },
  /**冰上曲棍球 */
  [SportType.IceHockey]: {
    img: 'sprite_main_wgty_sports_puck',
    asideSvg: 'icon_dtfl_puck_0'
  },
  /**网球 */
  [SportType.Tennis]: {
    img: 'sprite_main_wgty_sports_tennis',
    asideSvg: 'icon_dtfl_tennis_0'
  },
  /**排球 */
  [SportType.Volleyball]: {
    img: 'sprite_main_wgty_sports_volleyball',
    asideSvg: 'icon_dtfl_volleyball_0'
  },
  /**斯诺克/台球 */
  [SportType.Billiards]: {
    img: 'sprite_main_wgty_sports_billiards',
    asideSvg: 'icon_dtfl_billiards_0'
  },
  /**棒球 */
  [SportType.Baseball]: {
    img: 'sprite_main_wgty_sports_baseball',
    asideSvg: 'icon_dtfl_baseball_0'
  },
  /**羽毛球 */
  [SportType.Badminton]: {
    img: 'sprite_main_wgty_sports_badminton',
    asideSvg: 'icon_dtfl_badminton_0'
  },
  /**高尔夫球 */
  [SportType.Golf]: {
    img: 'sprite_main_wgty_sports_golf',
    asideSvg: 'icon_dtfl_golf_0'
  },
  /**赛车 */
  [SportType.Motorsports]: {
    img: 'sprite_main_wgty_sports_racing',
    asideSvg: 'icon_dtfl_racing_0'
  },
  /**游泳 */
  [SportType.Swimming]: {
    img: 'sprite_main_wgty_sports_swimming',
    asideSvg: 'icon_dtfl_swimming_0'
  },
  /**政治 */
  [SportType.Politics]: {
    img: 'sprite_main_wgty_sports_politics',
    asideSvg: 'icon_dtfl_politics_0'
  },
  /**Polo	水球 */
  [SportType.Water]: {
    img: 'sprite_main_wgty_sports_waterpolo',
    asideSvg: 'icon_dtfl_waterpolo_0'
  },
  /** 跳水*/
  [SportType.Diving]: {
    img: 'sprite_main_wgty_sports_diving',
    asideSvg: 'icon_dtfl_diving_0'
  },
  /**拳击 */
  [SportType.Boxing]: {
    img: 'sprite_main_wgty_sports_boxing',
    asideSvg: 'icon_dtfl_boxing_0'
  },
  /**乒乓球 */
  [SportType.TableTennis]: {
    img: 'sprite_main_wgty_sports_table_tennis',
    asideSvg: 'icon_dtfl_table_tennis_0'
  },
  /**手球 */
  [SportType.Handball]: {
    img: 'sprite_main_wgty_sports_handball',
    asideSvg: 'icon_dtfl_handball_0'
  },
  /**飞镖 */
  [SportType.Darts]: {
    img: 'sprite_main_wgty_sports_darts',
    asideSvg: 'icon_dtfl_darts_0'
  },
  /**橄榄球 */
  [SportType.Rugby]: {
    img: 'sprite_main_wgty_sports_football',
    asideSvg: 'icon_dtfl_football_0'
  },
  /**柔道 */
  [SportType.Judo]: {
    img: 'sprite_main_wgty_sports_fighting',
    asideSvg: 'icon_dtfl_fighting_0'
  },
  /**电子竞技 */
  [SportType.ESports]: {
    img: 'sprite_main_wgty_sports_esports',
    asideSvg: 'icon_dtfl_esports_0'
  },
  /**沙滩排球 */
  [SportType.BeachVolleyball]: {
    img: 'sprite_main_wgty_sports_beach_volleyball',
    asideSvg: 'icon_dtfl_beach_volleyball_0'
  },
  /**金融 */
  [SportType.Finance]: {
    img: 'sprite_main_wgty_sports_finance',
    asideSvg: 'icon_dtfl_finance_0'
  },
  /**乐透 */
  [SportType.Lotto]: {
    img: 'sprite_main_wgty_sports_lotto',
    asideSvg: 'icon_dtfl_lotto_0'
  }
}

/** 体育项目列表 */
export const sportList = Object.values(SportType).map((type) => ({
  icon: SportTypeMapIcon[type].img,
  sportName: SportName[type],
  sportType: type
}))

export type SportList = typeof sportList

/**
 * 比赛排序类型
 */
export const CompetitionLevels = {
  /**联赛 */
  League: 0,
  /**时间 */
  Time: 1
}

export type CompetitionLevelsValueof = ValueOf<typeof CompetitionLevels>

/*** 区域盘口类型 ,改成接口要的类型*/
export enum HandicapAreas {
  /** 欧洲盘*/
  Europe = 3,
  /**香港盘 */
  Hongkong = 2
}

export type HandicapAreasValueof = ValueOf<typeof HandicapAreas>
/**
 * 三方盘口下注类型
 */
export enum BetType {
  /** 让球 */
  Handicap = 1,
  /** 上半场让球*/
  OneXHandicap = 7,
  /**下半场让球 */
  TwoXHandicap = 17,
  MatchPointHandicap = 704, // 比赛让分盘 h : Home Team (主队);a : Away Team (客队)--羽毛球
  /** 大小盘 */
  OverUnder = 3,
  OneXOverUnder = 8,
  TwoXOverUnder = 18, // 3:Over_Under 大小盘，8：上半场，18下半场	h : for Over (大); a : for Under (小)
  /** 胜平负 */
  FTp1X2 = 5, // 5:FTp1X2, 胜平负：全场, 独赢盘	1 : for home (主队); x : for even (和局); 2 : for away
  OneFtp1x2 = 15,
  TwoFtp1x2 = 177,
  /** 胜负盘 */
  MoneyLine = 20, // Money Line,胜负盘	h : for home team win (主队);a : for away team win (客队)
  OneMoneyLine = 21, // 1st Money Line,胜负盘	h : for home team win (主队);a : for away team win (客队)
  /** 比分 */
  score = 413,
  scoreUp = 414,
  scoreDown = 405,
  /** 单双 */
  singleDouble = 2,
  singleDoubleUp = 12,
  singleDoubleDown = 184,
  singleDoubleDown2 = 428,
  /** 双重机会 */
  doubleChance = 24,
  doubleChanceUp = 151,
  doubleChanceUp2 = 410,
  doubleChanceDown = 186,
  doubleChanceDown2 = 431,
  /** 总进球 */
  totalGoal = 6,
  totalGoalUp = 126,
  rightTotalGoal = 159,
  rightHomeTotalGoal = 161,
  rightAwayTotalGoal = 162,
  rightTotalGoalUp = 179,
  rightTotalGoalDown = 187,
  /** 零失球 */
  zeroLose = 13,
  zeroLoseWinner = 27,
  /** 半场/全场 */
  halfAll = 16,
  /** 特殊 */
  special = -1,
  /** 胜负 */
  winLose = 20
}

export type BetTypeValueof = ValueOf<typeof BetType>
/**
 * 盘口赔率类型
 */
export enum Oddsoption {
  AutoReceiveHighPirce = 1, //  1：只接受更好的赔率;
  NotChangePirce = 0 // 0：不接受盘口变更(预设) ;
}
/**
 * 支持的串关类型枚举
 */
export enum OddsType {
  Doubles = 'Doubles', //	0 二串一
  Trebles = 'Trebles', //	1 三串一
  Fold4 = 'Fold4', //	4 四串一
  Fold5 = 'Fold5', //	7 五串一
  Fold6 = 'Fold6', // 10	六串一
  Fold7 = 'Fold7', // 13	七串一
  Fold8 = 'Fold8', // 16	八串一
  Fold9 = 'Fold9', // 19	九串一
  Fold10 = 'Fold10', // 20	十串一
  Trixie = 'Trixie', // 2	三串四
  Yankee = 'Yankee', // 5	四串 11
  Canadian = 'Canadian', // 8	五串 26
  Heinz = 'Heinz', // 11	六串 57
  SuperHeinz = 'SuperHeinz', // 14	七串 120
  Goliath = 'Goliath' // 17 八串 247
  // Fold9_Fold100 = 19110 //	19 ~ 110	 九串一 ~ 一百串一
}
export enum ParlayStatus {
  PointDisappear = 6, // 球头已不存在
  OddsChanged = 8, //赔率已改变
  OddsAdjust = 12, // 赔率调整中
  OddsError = 17, // 赔率错误
  GameStatusChanged = 18, //赛事状态改变
  GameScoreChanged = 22, // 赛事比分改变
  GamePointChanged = 23 //赛事球头改变
}

/** 最低串关赛事数量*/
export const MinParlayCount = 1

export const MarketStatus = {
  /**（进行中）：表示比赛或事件正在进行中，投注仍然可以进行 */
  running: 'running',
  /**（暂停）：表示比赛或事件暂时停止，投注暂时无法进行。 */
  suspend: 'suspend',
  /**（封盘）：表示即将结束投注的时间窗口，此后将不再接受新的投注。在封盘状态下，赔率不再变化，并且投注者只能等待比赛结果。 */
  closePrice: 'closePrice',
  /** 已结束）：表示比赛或事件已经结束，结果已经确定。在这个状态下，不再接受任何投注，并且开始结算赌注，根据比赛结果计算获胜和输掉的投注。*/
  closed: 'closed'
}

// 筛选赛事快捷按钮首字母
export const FIRST_LETTER = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z'
]
/** 我方UI 展示类别 */
export enum MarketType {
  ALL = '100',
  HOT = '99',
  /** 特殊投注 */
  SPECIAL = '98',
  /** 让球 */
  HANDICA = '1',
  /** 单双盘 */
  OddEven = '2',
  /** 大小盘 */
  OverUnder = '3',
  /** 比分 */
  SCORE = '4',
  /** 全场, 独赢盘 */
  SINGLE_DOUBLE = '5',
  /** 双重机会 */
  DOUBLE_CHANCE = '6',
  /** 总进球 */
  TOTAL_GOAL = '7',
  /** 零失球 */
  ZERO_LOSE = '8',
  /** 半场/全场 */
  HALF_ALL = '9',
  /** 胜负 */
  WIN_LOSE = '10'
}

/** 盘口分类*/
export const TabType = {
  /**滚球 */
  Rolling: 'rolling',
  /**今日 */
  Today: 'today',
  /**早盘 */
  Morning: 'morning',
  /**串关 */
  PassLeve: 'passLeve',
  /**冠军 */
  Champion: 'champion',
  /**关注 */
  FocusOn: 'focusOn'
}

export const FilterCondition = {
  /**联赛和时间排序 */
  CompetitionLevelKey: 'competitionLevelKey',
  /**欧洲盘和香港盘筛选 */
  HandicapAreasKey: 'handicapAreasKey'
}

export type FilterConditionValue = ValueOf<typeof FilterCondition>

/**saba登录得错误码 */
export const SabaLoginErrorCode = 41023009

/**saba登录得错误码 */
export const SabaLoginCode = 3009

/** saba登录报错没有这个消息*/
export const SabaLoginNotGameErrorCode = 41020
/**
 * 参考文档
 * https://github.com/Saba-sports/OddsDirectAPI_CN/wiki/Parlay-Combo-Enumeration
 * 将英文映射中文
 */

export const ComboTypeEnMapCs: Record<string, string> = {
  Double: '2串1',
  Doubles: '2串1',
  Treble: '3串1',
  Trebles: '3串1',
  Trixie: '3串4',
  Fold4: '4串1',
  Fold4s: '4串1',
  Yankee: '4串 11',
  Fold5: '5串1',
  Fold5s: '5串1',
  Canadian: '5串 26',
  Fold6: '6串1',
  Heinz: '6串 57',
  Fold7: '7串一',
  SuperHeinz: '7串 120',
  Fold8: '8串1',
  Goliath: '8串 247',
  '3-Fold': '3串1',
  '3-Folds': '3串1',
  '4-Fold': '4串1',
  '4-Folds': '4串1',
  '5-Fold': '5串1',
  '5-Folds': '5串1',
  '6-Fold': '6串1',
  '6-Folds': '6串1',
  '7-Fold': '7串1',
  '7-Folds': '7串1',
  '8-Fold': '8串1',
  '8-Folds': '8串1',
  '9-Fold': '9串1',
  '9-Folds': '9串1',
  '10-Fold': '10串1',
  '4-Fold~100-Fold': '9串1 ~ 100串1'
}

/**全组合的串关组合 */
export const FullCombinationsComboTypeList: Array<string> = [
  '3串4',
  '4串 11',
  '5串 26',
  '6串 57',
  '7串 120',
  '8串 247'
]

/**投注成功或者失败状态 */
export const BetStatus = {
  /**下注成功 */
  success: 0,
  /**下注失敗 */
  fail: 1
}

/**时间间隔 */
export const TimeInterval = 10000

export const EventBus = {
  /**自营体育联赛列表 */
  selfOperatedGamesLeagueIdToMatchEventList:
    'SelfOperatedGamesLeagueIdToMatchEventList'
}

/**自营体育接口时区偏差 */
export const UTCOffset = 0
