import {
  AccountDetailsData,
  BettingRecordData,
  PersonalStatementListData
} from '@/api/center/report/type'
import { createMyPersistedStorage } from '@/utils/business-utils'
import { defineStore } from 'pinia'
import type { GlobalEventActions } from '@/context/events'
interface AccountState {
  response?: {
    data: AccountDetailsData[]
    total: number
  }
  language: string
}
interface BettingState {
  response?: {
    data: BettingRecordData[]
    total: number
  }
}
interface PersonalState {
  response?: {
    data: PersonalStatementListData[]
    total: number
  }
}
export interface NoticeState {
  accountState: AccountState
  bettingState: BettingState
  personalState: PersonalState
  statistics: StatisticsState
}
export interface StatisticsState {
  total_charge_amount?: number
  total_withdraw_amount?: number
  total_discount_amount?: number
  total_valid_bet?: number
  total_item_count?: number
  betitemNum?: number
}

export const useReportStore = defineStore('report', {
  state: (): NoticeState => ({
    accountState: {} as AccountState,
    bettingState: {} as BettingState,
    personalState: {} as PersonalState,
    statistics: {}
  }),
  persist: {
    enabled: true,
    strategies: [
      {
        key: 'web.lobby.report',
        storage: createMyPersistedStorage(),
        paths: ['accountState', 'bettingState', 'personalState']
      }
    ]
  },
  getters: {
    cacheAccountState(): AccountState {
      return this.accountState
    },
    cacheBettingState(): BettingState {
      return this.bettingState
    },
    cachePersonalState(): PersonalState {
      return this.personalState
    }
  },
  actions: {
    setCacheAccountState(cache: AccountState) {
      this.accountState = cache
    },
    setCacheBettingState(cache: BettingState) {
      this.bettingState = cache
    },
    setCachePersonalState(cache: PersonalState) {
      this.personalState = cache
    },
    setStatisticsState(cache: StatisticsState) {
      this.statistics = cache
    },
    /**
     * 用于给外部调用的统一钩子
     * @param type
     */
    hooksActions(type: GlobalEventActions['type']) {
      switch (type) {
        case 'LOGOUT_BEFORE': {
          this.accountState = {}
          this.bettingState = {}
          this.personalState = {}
          break
        }

        default:
          break
      }
    },
    getVisibleDropdownNode() {
      const dropdownNodes = document.querySelectorAll('.recalculateLeft')

      const visibleDropdownNode = Array.from(dropdownNodes).find((node) => {
        return window.getComputedStyle(node).display !== 'none'
      })

      return visibleDropdownNode as unknown as HTMLDivElement
    },

    // 设置select下拉内容区left 确保内容不超出页面
    recalculateDropdownLeft() {
      setTimeout(() => {
        // 获取 display 不为 none 的元素
        const dropdownNode = this.getVisibleDropdownNode()

        if (dropdownNode) {
          const dropdownRect = dropdownNode.getBoundingClientRect()

          const bodyRect = document.body.getBoundingClientRect().right
          const rightDistance = bodyRect - dropdownRect.right
          // 重新设置left
          if (rightDistance < 0) {
            dropdownNode.style.transition = 'left 0.1s'
            dropdownNode.style.left = bodyRect - dropdownRect.width + 'px'
          }
        }
      }, 500)
    }
  }
})
