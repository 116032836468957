import {
  ActivityTemplate,
  ApiDepositEventGet,
  ApiEventGetList,
  ApiEventListedRewards,
  CycleTypeEnumNew as CycleTypeEnum,
  DepositRuleTypeEnum,
  EventSignTypeEnum,
  ReceiveStatusEnum,
  ReceiveTimeRemark,
  ReceiveTimeTypeEnum,
  orderDetail
} from '@/api/event/type'
import { Base, Component, Prop } from '@/vue-property-decorator'
import { Bind, Debounce } from '@/utils/Decorator'
import { ComTableColumnType } from '@/components/business-components/table/type'
import { CommonTable } from '@/components/business-components'
import { Currency } from '@/config/currencies.config'
import { VNode } from 'vue'
import { brazilianCurrencyFormatter } from '@/utils/business-utils/finance'
import { occupierReplaceVNode } from './occupierReplaceVNode'
import { useI18n } from '@/i18n'
import { useMainStore } from '@/store/index'
import BusinessUtils from '@/utils/business-utils'
import CountDown from '@/layouts/components/countDown'
import EventItemListedRewardsButton from '@/views/event/item/component/listed-rewards/button'
import moment from 'moment'
import style from './style.module.scss'

export enum StyleTypeEnum {
  /** 活动页 */
  EventPage,
  /** 活动弹窗 */
  EventModal,
  /** 充值弹窗 */
  PayAfterModal
}

interface Props {
  /**
   * 类型 0：活动页，1：活动弹窗
   */
  styleType?: StyleTypeEnum
  /**
   * 活动详情数据
   */
  event: ApiDepositEventGet
  /**
   * 刷新数据
   */
  fetchData?: () => void
  forwardCallback?: () => void
}
/**
 * 活动奖励列表（充值活动、打码活动）
 */
@Component<EventItemListedRewards>({ name: 'EventItemListedRewards' })
export default class EventItemListedRewards extends Base<unknown, Props> {
  /**
   * 活动详情数据
   */
  @Prop()
  styleType?: Props['styleType']
  /**
   * 活动详情数据
   */
  @Prop({ required: true })
  event!: Props['event']

  /**
   * 刷新数据
   */
  @Prop()
  fetchData?: Props['fetchData']
  /**
   * 前往按钮的回调
   */
  @Prop()
  forwardCallback?: Props['forwardCallback']

  /**
   * 是否展示动效
   */
  private rewardAniVisible = false

  /** 动效类型 */
  private aniType = 0

  /**
   * 获取奖励时限
   */
  get timeMappings(): Record<Key, BaseResult> {
    const { t } = useI18n()
    return {
      [CycleTypeEnum.Daily]: t('lobby.event.listedRewards.perDay'),
      [CycleTypeEnum.Weekly]: t('lobby.event.listedRewards.perWeek'),
      [CycleTypeEnum.Monthly]: t('lobby.event.listedRewards.perMonth')
    } as Record<Key, BaseResult>
  }

  /**
   * 规则列表条件表头
   */
  private get ruleListFirstTitle() {
    // ruleType 充值活动专用 0累计 1单笔 2首存
    // cycletype 0单次改月 1日循环 2周循环
    const { activeData } = this.event
    const { ruleType } = activeData as ApiEventListedRewards
    const cycleType = this.event.cycleType as unknown as CycleTypeEnum
    let result = ''
    const { t } = useI18n()

    const map = new Map([
      [
        this.event.template !== ActivityTemplate.Deposit,
        t('lobby.center.report.statistics.validBetTotal')
      ],
      [
        this.event.template === ActivityTemplate.Deposit &&
          cycleType === CycleTypeEnum.Monthly &&
          ruleType === DepositRuleTypeEnum.Accumulative,
        t('lobby.event.listedRewards.perMonthTotalPay')
      ],
      [
        this.event.template === ActivityTemplate.Deposit &&
          cycleType === CycleTypeEnum.Weekly &&
          ruleType === DepositRuleTypeEnum.Accumulative,
        t('lobby.event.listedRewards.weeklyTotalPay')
      ],
      [
        this.event.template === ActivityTemplate.Deposit &&
          cycleType === CycleTypeEnum.Daily &&
          ruleType === DepositRuleTypeEnum.Accumulative,
        t('lobby.event.cumulativereCharge')
      ],
      [
        this.event.template === ActivityTemplate.Deposit &&
          cycleType === CycleTypeEnum.Monthly &&
          ruleType === DepositRuleTypeEnum.Single,
        t('lobby.event.listedRewards.perMonthPay')
      ],
      [
        this.event.template === ActivityTemplate.Deposit &&
          cycleType === CycleTypeEnum.Weekly &&
          ruleType === DepositRuleTypeEnum.Single,
        t('lobby.event.listedRewards.weeklyPay')
      ],
      [
        this.event.template === ActivityTemplate.Deposit &&
          cycleType === CycleTypeEnum.Daily &&
          ruleType === DepositRuleTypeEnum.Single,
        t('lobby.event.listedRewards.ruleTabe.single')
      ],
      [
        this.event.template === ActivityTemplate.Deposit &&
          ruleType === DepositRuleTypeEnum.FirstDeposit,
        t('lobby.event.listedRewards.firstRecharge')
      ]
    ])
    result = map.get(true) as string
    return result
  }

  /**
   * 获取活动星期限时
   * @private
   */
  private get validDayMappings(): Record<Key, BaseResult> {
    const { t } = useI18n()
    return {
      1: t('lobby.event.validDay.monday'),
      2: t('lobby.event.validDay.tuesday'),
      3: t('lobby.event.validDay.wednesday'),
      4: t('lobby.event.validDay.thursday'),
      5: t('lobby.event.validDay.friday'),
      6: t('lobby.event.validDay.saturday'),
      7: t('lobby.event.validDay.sunday')
    }
  }

  /** 领取提示文案映射 */
  private receiveTipsMapText(item: orderDetail): string {
    const { t } = useI18n()
    const { receiveTimeRemark, canReceiveTime } = item
    let receiveObject = {} as ReceiveTimeRemark

    try {
      receiveObject =
        typeof receiveTimeRemark === 'string'
          ? JSON.parse(receiveTimeRemark)
          : {}
    } catch (error) {}

    const {
      receiveTimeDay,
      receiveTimeStart,
      receiveTimeEnd,
      receiveTimeType
    } = receiveObject || {}

    const mapper = {
      // 实时领取
      [ReceiveTimeTypeEnum.Now]: () => {
        return t('lobby.event.listedRewards.canReceiveTip1', {
          day: BusinessUtils.formatSiteOffsetTime(canReceiveTime, 'ymdhms')
        }) as string
      },
      // 次日领取
      [ReceiveTimeTypeEnum.NextDay]: () => {
        return t('lobby.event.listedRewards.canReceiveTip1', {
          day: BusinessUtils.formatSiteOffsetTime(canReceiveTime, 'ymdhms')
        }) as string
      },
      // 下周
      [ReceiveTimeTypeEnum.NextWeek]: () => {
        return t('lobby.event.listedRewards.canReceiveTip1', {
          day: BusinessUtils.formatSiteOffsetTime(canReceiveTime, 'ymdhms')
        }) as string
      },
      // 下月
      [ReceiveTimeTypeEnum.NextMonth]: () => {
        return t('lobby.event.listedRewards.canReceiveTip1', {
          day: BusinessUtils.formatSiteOffsetTime(canReceiveTime, 'ymdhms')
        }) as string
      },
      // 每日
      [ReceiveTimeTypeEnum.EveryDaily]: () => {
        return t('lobby.event.listedRewards.canReceiveTip2', {
          startTime: this.getTime(receiveTimeStart),
          endTime: this.getTime(receiveTimeEnd)
        }) as string
      },
      // 每周
      [ReceiveTimeTypeEnum.EveryWeek]: () => {
        return t('lobby.event.listedRewards.canReceiveTip3', {
          week: this.validDayMappings[receiveTimeDay],
          startTime: this.getTime(receiveTimeStart),
          endTime: this.getTime(receiveTimeEnd)
        }) as string
      },
      // 每月
      [ReceiveTimeTypeEnum.Monthly]: () => {
        return t('lobby.event.listedRewards.canReceiveTip4', {
          day: receiveTimeDay,
          startTime: this.getTime(receiveTimeStart),
          endTime: this.getTime(receiveTimeEnd)
        }) as string
      }
    }

    return mapper[receiveTimeType as unknown as ReceiveTimeTypeEnum]?.() ?? ''
  }

  private getTime(seconds: number) {
    return moment().startOf('day').seconds(seconds).format('HH:mm:ss')
  }

  @Bind()
  @Debounce()
  private freshData() {
    this.fetchData?.()
  }

  private receiveTipContent(receiveDuration?: number) {
    const { t } = useI18n()

    if (receiveDuration) {
      return (
        <span class={style.rewardTimeTips}>
          {occupierReplaceVNode(
            t('lobby.reward.vip.rewardMap.rewardTips0') as string,
            '{x}',
            <CountDown
              hasDay
              hasTextBorder={false}
              onOver={() => this.freshData()}
              duration={Math.abs(receiveDuration)}
            />
          )}
        </span>
      )
    }
  }

  get ruleList() {
    const { list = [] } = this.event || {}
    return list
  }

  private renderReward(
    /**
     * 奖励条目
     */
    item: ApiEventGetList
  ) {
    const { userInfos } = useMainStore()
    const currencyIconMap = Currency.commonIconMap()

    const progressArr = item.progress || ''
    const getAcount = () => {
      let acount
      switch (item?.signType) {
        case EventSignTypeEnum.Regular:
          acount = brazilianCurrencyFormatter(item.reward ?? 0)
          break
        case EventSignTypeEnum.Proportion:
          acount = item.ratio + '%'
          break
        case EventSignTypeEnum.Interregional:
          if (progressArr) {
            acount = `${brazilianCurrencyFormatter(
              progressArr.split(',')[0]
            )} - ${brazilianCurrencyFormatter(progressArr.split(',')[1])}`
          }
          break
        default:
          acount = brazilianCurrencyFormatter(item.reward ?? 0)
          break
      }
      return acount ?? ''
    }

    const spriteIcon =
      currencyIconMap[
        (userInfos?.currency ||
          'CNY') as unknown as keyof typeof currencyIconMap
      ]?.PAY_JB

    return (
      <div class={style.reward}>
        {spriteIcon && <icon-sprite sprite={spriteIcon} />}
        <span>{getAcount()}</span>
      </div>
    )
  }

  /** 奖励列表，包含尾号、连号、任意位置号码，以及固定奖金和投注倍数奖金 */
  private get columnsRewards(): Array<ComTableColumnType> {
    const { t } = useI18n()
    const ruleList = this.ruleList

    const signType = ruleList.length && ruleList[0]?.signType

    // 充值-固定奖励 signType === 0 || 充值-随机金额 signType === 2
    const signType0 = [
      {
        title: t('lobby.event.listedRewards.ruleTabe.awardAmount'),
        dataIndex: 'reward',
        key: 'reward',
        customRender: (value: string) => {
          if (signType === 2) {
            const regex = /[-,]/
            const matches = regex.exec(value)
            let randomAmount: string[]
            if (matches) {
              randomAmount = value.split(matches[0])
            } else {
              randomAmount = []
            }
            return (
              <span class="reward-finance">
                {randomAmount?.length
                  ? `${brazilianCurrencyFormatter(
                      randomAmount[0]
                    )}-${brazilianCurrencyFormatter(randomAmount[1])}`
                  : 0}
              </span>
            )
          }
          return (
            <span class="reward-finance">
              {(value && brazilianCurrencyFormatter(value)) || 0}
            </span>
          )
        }
      }
    ] as Array<ComTableColumnType>
    // 充值-比例奖励 signType === 1
    const signType1 = [
      {
        title: t('lobby.event.listedRewards.ruleTabe.rewardRatio'),
        dataIndex: 'ratio',
        key: 'ratio',
        customRender: (value: string) => {
          return <span>{value}%</span>
        }
      },
      {
        title: t('lobby.event.listedRewards.ruleTabe.awarMaxLimit'),
        dataIndex: 'limitReward',
        key: 'limitReward',
        customRender: (value: number) => {
          return (
            <span>
              {brazilianCurrencyFormatter(value)?.replace(/(.|,)00$/, '') ||
                t('lobby.event.discountCode.notProfitLimit')}
            </span>
          )
        }
      }
    ] as Array<ComTableColumnType>
    // 充值-随机金额 signType === 2

    const column = [
      {
        title: this.ruleListFirstTitle,
        dataIndex: 'amount',
        key: 'amount',
        customRender: (value: number) => {
          return (
            <span>
              ≥{brazilianCurrencyFormatter(value)?.replace(/(.|,)00$/, '')}
            </span>
          )
        }
      }
    ] as Array<ComTableColumnType>

    switch (signType) {
      case EventSignTypeEnum.Interregional:
        column.push(...signType0)
        break
      case EventSignTypeEnum.Proportion:
        column.push(...signType1)
        break
      default:
        column.push(...signType0)
        break
    }

    return column
  }

  private get eventList() {
    const { activeData } = this.event
    if (!activeData || !this.event) {
      return []
    }
    const active = activeData as ApiEventListedRewards
    return active.receiveList || []
  }

  /**
   * 渲染奖励设置
   * @param item  奖励条目
   * @param index 数组索引
   * @private
   */
  private renderRewardsTable(list: ApiEventGetList[]) {
    return (
      <CommonTable
        query={async () => {
          return {
            data:
              list?.map((row, idx) => ({
                ...row,
                rowKey: idx
              })) ?? []
          }
        }}
        class={[style.table, style.rewardsTable]}
        columns={this.columnsRewards}
        rowKey={'rowKey'}
        emptyHeight={'auto'}
        isHiddenPagination={true}
      />
    )
  }

  /**
   * 渲染奖励条目
   * @param item  奖励条目
   * @param index 数组索引
   * @private
   */
  private renderItem(item: orderDetail, index: number): VNode {
    const { list = [], activeData, ruleType } = this.event
    // 首充无角标
    const typePopup = this.styleType === StyleTypeEnum.EventPage
    const { rechargeEachOrder, receiveTimeType } =
      (activeData as ApiEventListedRewards) || {}

    return (
      <div class={style.item}>
        {!typePopup && ruleType !== DepositRuleTypeEnum.FirstDeposit && (
          <div
            class={{
              [style.time]: true
              // [style.bgYellow]: CycleTypeEnum.Daily === this.event.cycleType,
              // [style.bgBlue]:
              //   CycleTypeEnum.Weekly === this.event.cycleType ||
              //   this.event.cycleType === CycleTypeEnum.Monthly
            }}
          >
            {this.timeMappings[String(this.event.cycleType)]}
          </div>
        )}
        <div class={style.info}>
          <div class={style.caption}>
            {occupierReplaceVNode(
              item.text,
              '{reward}',
              this.renderReward(item)
            )}
          </div>
          {!!item.competedTime && (
            <div class={style.successTime}>
              {BusinessUtils.formatSiteOffsetTime(item.competedTime, 'ymdhms')}
            </div>
          )}
          {/* <MyProgress current={item.nowAmount ?? 0} total={item.amount ?? 0} /> */}
        </div>
        <div class={style.operator}>
          {typePopup ? (
            <EventItemListedRewardsButton
              receiveStatus={ReceiveStatusEnum.Processing}
              giveType={this.event.giveType as number}
              template={ActivityTemplate.Deposit}
              callback={() => this.freshData()}
              forwardCallback={this.forwardCallback}
              orderNo={(list?.length && list[index]?.subactiveid) || ''}
              rechargeEachOrder={rechargeEachOrder}
              items={item}
              receiveTimeType={receiveTimeType as number}
              activeId={this.event.id}
              receiveTipsText={this.receiveTipsMapText(item)}
              receiveDuration={item.receiveDuration}
            />
          ) : (
            <EventItemListedRewardsButton
              activeId={this.event.id}
              currentAmount={item.nowAmount ?? 0}
              receiveId={item.receiveId as number}
              giveType={this.event.giveType as number}
              receiveStatus={item.receiveStatus as number}
              template={this.event.template}
              receiveDeviceType={this.event.receiveDeviceType}
              callback={() => this.freshData()}
              taskCondition={this.event.taskCondition}
              canReceiveTime={item.canReceiveTime}
              forwardCallback={this.forwardCallback}
              receiveDuration={item.receiveDuration}
              items={item}
              receiveTimeType={receiveTimeType as number}
              orderNo={(list?.length && list[index]?.subactiveid) || ''}
              rechargeEachOrder={rechargeEachOrder}
              receiveTipsText={this.receiveTipsMapText(item)}
            />
          )}

          {/* {(typePopup || !isWeb) && this.renderReward(item)} */}
          {/* 可领取倒计时 */}
          {this.receiveTipContent(item.receiveDuration)}
        </div>
      </div>
    )
  }

  render(): VNode {
    const { t } = useI18n()
    const { isWeb } = useMainStore()
    const ruleList = this.ruleList

    return (
      <div
        class={{
          [style.main]: isWeb,
          [style.popupList]: this.styleType === StyleTypeEnum.EventModal
        }}
      >
        <div
          class={{
            [style.list]: this.styleType !== StyleTypeEnum.EventModal,
            [style.noReceiveContent]: !this.eventList?.length
          }}
        >
          {this.eventList?.length ? (
            this.eventList?.map(this.renderItem)
          ) : (
            <div class={style.noReceive}>
              <span>{t('lobby.event.listedRewards.noRewards')}</span>
            </div>
          )}
        </div>
        {ruleList?.length > 0 && (
          <div class={style.content}>{this.renderRewardsTable(ruleList)}</div>
        )}
      </div>
    )
  }
}
