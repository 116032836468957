import { Base, Component } from '@/vue-property-decorator'
import { RouteName } from '@/router/config'
import { useI18n } from '@/i18n'
import { useSportsStore } from '../../store'
import BusinessUtils from '@/utils/business-utils'
import TooltipsComp from '@/components/business-components/tooltips'
import style from './style.module.scss'

@Component<ParlayBetEntrance>({
  name: 'ParlayBetEntrance'
})
export default class ParlayBetEntrance extends Base {
  private sportsStore = useSportsStore()

  private doParlay() {
    this.$router.push({
      name: RouteName.SPORTS_PARLAYBET
    })
  }

  render() {
    if (!this.sportsStore.parlayBetCount) {
      return <span></span>
    }
    const { t } = useI18n()
    return (
      <div class={style.parlayBetBadge} onClick={this.doParlay}>
        <div class={style.badgeA}>
          {t('lobby.selfoperatedGames.sports.passLevel')}
          <TooltipsComp
            text={<span>{this.sportsStore.parlayBetCount}</span>}
            top={BusinessUtils.px2rem('5px')}
            right={BusinessUtils.px2rem('10px')}
            charge={this.sportsStore.parlayBetCount}
          />
        </div>
      </div>
    )
  }
}
