import { AfterRechargeActivityItem } from '@/api/event/type'
import { Bind, Throttle } from 'lodash-decorators'
import { CommonTabsH5 } from '@/components/business-components'
import { Component, Ref } from '@/vue-property-decorator'
import { GlobalManager } from '@/context'
import { RenderItem, TabTypes } from '@/components/business-components/tabs-h5'
import { RouteName } from '@/router/config'
import { useEventStore } from '@/views/event/store'
import { useI18n } from '@/i18n'
import AfterRechargeActivityTabItem from './tabItem'
import BusinessUtils from '@/utils/business-utils'
import GuidingArrow from '@/views/game/business-components/baseSubGamePage/guidingArrow'
import ModalBase from '@/mixins/business/modal/modal-base'
import autoModalManager from '@/context/autoModalManager'
import router from '@/router'
import style from './style.module.scss'

type State = {
  /** 当前选中tab */
  activeTab: number | string
  isLeft: boolean
  isRight: boolean
  showContent: boolean
}

export const openAfterRechargeActivityModal = async () => {
  const {
    getAfterRechargeActivityList,
    afterRechargeActivityList,
    afterRechargeActivityModalOpening
  } = useEventStore()
  if (!afterRechargeActivityModalOpening) {
    if (router.currentRoute.name === RouteName.GAME) {
      // console.log('xxxxxxx openAfterRechargeActivityModal')
      autoModalManager.add({
        name: 'afterRechargeActivity',
        load: async () => {
          await getAfterRechargeActivityList()
        },
        show: () => {
          // console.log(
          //   'xxxxxxx afterRechargeActivity show',
          //   JSON.parse(JSON.stringify(afterRechargeActivityList))
          // )
          if (+afterRechargeActivityList?.length === 0) {
            return false
          }
          GlobalManager.Modal.open('afterRechargeActivity')
          return true
        }
      })
    } else {
      const data = await useEventStore().getAfterRechargeActivityList()
      // console.log('xxxxxxx openAfterRechargeActivityModal')
      if (data?.length > 0) {
        GlobalManager.Modal.open('afterRechargeActivity')
      }
    }
    // console.log(
    //   'xxxxxxx openAfterRechargeActivityModal after',
    //   autoModalManager
    // )
  }
}

@Component<AfterRechargeActivity>({ name: 'AfterRechargeActivity' })
export default class AfterRechargeActivity extends ModalBase<State> {
  state: State = {
    isLeft: false,
    isRight: false,
    activeTab: 0,
    showContent: false
  }

  @Ref()
  private readonly scrollRef!: CommonTabsH5

  // 监听滚动,控制左右箭头显示隐藏
  @Bind()
  @Throttle(100)
  private scrollChange() {
    if (this.scrollRef?.getTabsNavDiv) {
      const { scrollWidth, clientWidth, scrollLeft } =
        this.scrollRef?.getTabsNavDiv
      const scrollRight = scrollWidth - clientWidth - scrollLeft
      this.setState({
        isLeft: scrollLeft <= 20,
        isRight: scrollRight <= 20
      })
    }
  }

  toLeft() {
    // 点击左侧箭头向最左侧滑动
    this.scrollRef?.getTabsNavDiv?.scrollTo({
      left: 0,
      behavior: 'smooth'
    })
  }

  toRight() {
    // 点击右侧箭头向最右侧滑动
    this.scrollRef?.getTabsNavDiv?.scrollTo({
      left: this.scrollRef?.getTabsNavDiv?.scrollWidth,
      behavior: 'smooth'
    })
  }

  mounted() {
    setTimeout(() => {
      this.scrollChange()
    }, 0)
  }

  /** 选中数据 */
  private get activeData() {
    const eventItem = this.cateIdToActivity.find(
      (i) => +i.id === +this.state.activeTab
    )
    if (eventItem) {
      return eventItem as unknown as AfterRechargeActivityItem
    }
    return {} as AfterRechargeActivityItem
  }

  /**
   * tab切换 的列表数据
   */
  private get cateIdToActivity() {
    const { afterRechargeActivityList } = useEventStore()
    return afterRechargeActivityList
  }

  /**
   * tab切换列表的时候
   */
  private tabChange(activeTab: number) {
    // console.log('xxxxx tabChange')
    if (!activeTab) {
      return
    }
    // 设置key
    this.setState({
      activeTab
    })
    return
  }

  async open() {
    const { setAfterRechargeActivityModalOpening } = useEventStore()
    if (this.cateIdToActivity?.length) {
      this.baseOpen()
      setAfterRechargeActivityModalOpening(true)
      this.tabChange(+this.cateIdToActivity?.[0]?.id)
      setTimeout(() => {
        this.scrollChange()
      }, 0)
    }
  }

  async close() {
    const { setAfterRechargeActivityModalOpening } = useEventStore()
    autoModalManager.onModalClose('afterRechargeActivity')
    this.baseClose()
    setAfterRechargeActivityModalOpening(false)
  }

  render() {
    const { t } = useI18n()
    return this.renderModal({
      modalConfig: {
        props: {
          title:
            this.cateIdToActivity.length === 1
              ? this.activeData.name
              : t('lobby.modal.event.payAfter'),
          closable: true,
          width: BusinessUtils.px2rem(710),
          wrapClassName: style.main
        }
      },
      renderContent: () => {
        // console.log(
        //   'xxxxxx cateIdToActivity',
        // this.event.template === ActivityTemplate.DiscountCode &&
        //   this.activeData.activeType ===
        //     AfterRechargeActivityTypeEnum.CommonActivity,
        //   JSON.parse(JSON.stringify(this.cateIdToActivity))
        // )
        const { activeTab } = this.state
        return (
          <div class="event-modal-content">
            <div class="scroll-tab-bar-content">
              <div
                v-show={!this.state.isLeft}
                class="left-box"
                onClick={this.toLeft}
              >
                <GuidingArrow />
              </div>
              <CommonTabsH5
                ref={'scrollRef'}
                soleId={'scrollTabbar-after-recharge-activity-modal'}
                class="scroll-tab-bar-tabs"
                needAutoCenter
                itemHeight={80}
                itemSpace={0}
                tabType={TabTypes.LARGE_TEXT}
                persistedActiveName={''}
                v-model={this.state.activeTab}
                tabsNavHiddenForOne={true}
                listenScroll
                onScroll={this.scrollChange}
                renderList={
                  this.cateIdToActivity.map((tab) => {
                    return {
                      value: tab.id,
                      label: () => tab.name
                    }
                  }) as RenderItem[]
                }
                itemWidth={'auto'}
                isScrollX={true}
              />
              <div
                v-show={!this.state.isRight}
                class="right-box"
                onClick={this.toRight}
              >
                <GuidingArrow />
              </div>
            </div>
            {this.cateIdToActivity.map((i) => (
              <div class="item" key={i.id} cate-id={i.id}>
                {i.id && activeTab && String(activeTab) === String(i.id) && (
                  <AfterRechargeActivityTabItem activeTab={i.id} />
                )}
              </div>
            ))}
            <div class="after-recharge-ativity-dots">
              {this.cateIdToActivity.map((i) => (
                <span
                  key={i.id}
                  cate-id={i.id}
                  class={[
                    'after-recharge-ativity-dot-item',
                    this.state.activeTab === i.id
                      ? 'after-recharge-ativity-dot-active'
                      : ''
                  ]}
                ></span>
              ))}
            </div>
          </div>
        )
      }
    })
  }
}
