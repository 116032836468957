import { JudgePopupType } from '../../common/util/judgePopupType'
import { ReceiveLogPopSwitchEnum } from '@/api/event/type'
import { WaitingGetStore } from '.'
import { judgeIsForbidPopByPageAddress } from '../../common/util/judgeIsForbidPopByPageAddress'
import { useMainStore } from '@/store/index'
import { userRecord } from './initState/userOpeningWaitingGetRecord'
export type OpenModalParams = {
  store: WaitingGetStore
  /** 是否登录触发 */
  isLoginTrigger: boolean
}
/** 判断是否可以打开待领取弹窗 */
export const proxyPreJudgeOpenWaitingGet = (params: OpenModalParams) => {
  const { store, isLoginTrigger } = params
  // 判断后台配置 必须登陆后
  const { activesAuthInfos, hasLogined } = useMainStore()
  if (
    activesAuthInfos.disableReceiveLogPop === ReceiveLogPopSwitchEnum.NoPop ||
    !hasLogined
  ) {
    return false
  }
  // 先判断用户勾选
  if (judgeUserCheckedDisabled(store)) {
    return false
  }
  // 根据后台配置的弹窗类型对应相关数据来判断当前是否弹出
  if (!JudgePopupType.waitingGet.run({ isLoginTrigger })) {
    return false
  }
  // 根据当前页面地址判断是否禁用弹窗
  if (judgeIsForbidPopByPageAddress()) {
    return false
  }
  return true
}

/** 判断用户是否勾选了禁用弹框 */
const judgeUserCheckedDisabled = (store: WaitingGetStore) => {
  // 后台禁用本项|勾选永不提示|勾选本次不提示|业务层面要求只弹一次,任何一个为true则不再弹框
  const { isNeverRemind } = store
  // 后台配置的,不显示弹窗
  const { isServerDisabled } = store
  // 是否勾选过今日不再弹出 今日是否已经弹出过
  const { isHaveOpenedToday, isCheckDayNoOpen } =
    userRecord.getUserOpeningRecord()
  if (
    isNeverRemind ||
    (isHaveOpenedToday && isCheckDayNoOpen) ||
    isServerDisabled
  ) {
    return true
  } else {
    return false
  }
}
