import { Base, Component } from '@/vue-property-decorator'
import { CPFDateConditionEnum } from '../../type'
import { ComTableColumnType } from '@/components/business-components/table/type'
import { CommonLoading } from '@/components/business-components'
import { DataList } from '@/components/business-components/data-list'
import { RechargeItem, RechargeListPayload } from '@/api/rechargeFund/type'
import { currencyFormatter } from '@/utils/FormatterUtils'
import { defaultSeachTime, rechargeFundQueryFormat } from '../../util'
import { merge } from 'lodash'
import { useI18n } from '@/i18n'
import { useMainStore } from '@/store/index'
import BusinessUtils from '@/utils/business-utils'
import DateRangePickerH5 from '@/components/business-components/date-range-picker-h5'
import DateUtils from '@/utils/DateUtils'
import NetRetry, {
  DisplayType
} from '@/components/business-components/net-retry'
import moment, { Moment } from 'moment'
import style from '../../style.module.scss'
import useRechargeFundStore from '../../store'

interface State {
  loading: boolean
  condition: Partial<
    Omit<RechargeListPayload, 'query_start_time' | 'query_end_time'> & {
      startTime: Moment
    } & { endTime: Moment }
  >
}

@Component<AccumulHistory>({ name: 'AccumulHistory' })
export default class AccumulHistory extends Base<State> {
  state: State = {
    loading: false,
    condition: {
      current: 1,
      size: 20,
      startTime: defaultSeachTime().startTime,
      endTime: defaultSeachTime().endTime
    }
  }

  /**
   * 查询
   * @param pageNo
   * @param pageSize
   * @param condition
   * @returns
   */
  private async query(
    pageNo: number,
    pageSize: number,
    condition: State['condition']
  ) {
    const startTime = moment(condition.startTime).unix()
    const endTime = moment(condition.endTime).unix()

    const payload = merge(
      {},
      {
        query_start_time: startTime,
        query_end_time: endTime,
        current: pageNo,
        size: pageSize
      }
    )
    const res = await useRechargeFundStore().getRechargeList(payload)
    const { userInfos, siteInfos } = useMainStore()
    res.data.forEach((item) => {
      item.recharge_time2 = DateUtils.format(
        item.recharge_time,
        'YYYY-MM-DD HH:mm:ss',
        {
          utcOffset: siteInfos?.timeZone ?? ''
        }
      )
      item.add_fund_rate2 = item.add_fund_rate + '%'
      item.recharge_amount2 = currencyFormatter(item.recharge_amount, {
        code: userInfos?.currency,
        symbol: ''
      })

      item.add_fund_amount2 = currencyFormatter(item.add_fund_amount, {
        code: userInfos?.currency,
        symbol: ''
      })
    })
    if (res.err) {
      return {
        errorMessage: res.err,
        data: [],
        total: 0
      }
    }
    return rechargeFundQueryFormat(res, pageNo, pageSize)
  }

  get isMobile() {
    return !useMainStore().isWeb
  }

  /**
   * web 列数据渲染方式
   */
  get defaultColumns() {
    const { t } = useI18n()
    const def: Array<ComTableColumnType> = [
      {
        title: t('lobby.cpf.time'),
        dataIndex: 'recharge_time2',
        key: 'recharge_time2',
        width: BusinessUtils.px2rem(130)
      },
      {
        title: t('lobby.cpf.rechargeAmount'),
        dataIndex: 'recharge_amount2',
        key: 'recharge_amount2',
        width: BusinessUtils.px2rem(110)
      },
      {
        title: t('lobby.cpf.addFundRate'),
        dataIndex: 'add_fund_rate2',
        key: 'add_fund_rate2',
        width: BusinessUtils.px2rem(110)
      },
      {
        title: t('lobby.cpf.addFundAmount'),
        dataIndex: 'add_fund_amount2',
        key: 'add_fund_amount2',
        customRender: (text: string) => (
          <span class={style.tableSend}>{{ text }}</span>
        )
      }
    ]

    return def
  }

  get dateTypes() {
    return [
      CPFDateConditionEnum.TODAY,
      CPFDateConditionEnum.YESTERDAY,
      CPFDateConditionEnum.LAST_SEVEN_DAY,
      CPFDateConditionEnum.LAST_FIFTEEN_DAY,
      CPFDateConditionEnum.LAST_THIRTY_DAY,
      CPFDateConditionEnum.LAST_SIXTY_DAY
    ]
  }

  private refresh() {
    const { condition } = this.state
    const params = {
      query_start_time: moment(condition.startTime).unix(),
      query_end_time: moment(condition.endTime).unix(),
      current: 1,
      size: 20
    }
    useRechargeFundStore().updateData(params)
  }

  /**
   * 日历确认后的回调函授
   * @param res
   * @param res.startTime 开始时间
   * @param res.endTime 结束时间
   */
  private onPickerConfirm(res: { startTime: Moment; endTime: Moment }) {
    const { condition } = this.state
    const { startTime, endTime } = res

    this.setState({
      condition: { ...condition, startTime, endTime }
    })
  }

  /**
   * 表头
   */
  private listRenderHead() {
    const { t } = useI18n()
    return (
      <div class={[style.listHead, style.listCommon]}>
        <div class={style.rechargeTime}>{t('lobby.cpf.time')}</div>
        <div class={style.rechargeAmount}>{t('lobby.cpf.rechargeAmount')}</div>
        <div class={style.addFundRate}>{t('lobby.cpf.addFundRate')}</div>
        <div class={style.addFundAmount}>{t('lobby.cpf.addFundAmount')}</div>
      </div>
    )
  }

  /**
   * 表格单项
   */
  private listRenderItem(record: RechargeItem) {
    return (
      <div class={[style.listItem, style.listCommon]}>
        <div class={style.rechargeTime}>{record.recharge_time2}</div>
        <div class={style.rechargeAmount}>{record.recharge_amount2}</div>
        <div class={style.addFundRate}>{record.add_fund_rate2}</div>
        <div class={style.addFundAmount}>{record.add_fund_amount2}</div>
      </div>
    )
  }

  render() {
    const { t } = useI18n()
    const { totalRecharge, currentPeriodReward, isRefresh, listError } =
      useRechargeFundStore()
    const { condition } = this.state
    const startTime = condition.startTime as unknown as string
    const endTime = condition.endTime as unknown as string

    return (
      <section class={style.accumulHistoryWrapper}>
        <div class={style.selectWrapper}>
          <DateRangePickerH5
            class={style.datePickTimeRange}
            startTime={startTime}
            endTime={endTime}
            // 要放开日期限制，有的业主设置周期一年的，或者不限制的。和测试沟通后，限制10年的时间
            limiSearchDays={3650}
            limiDays={3650}
            onPickerConfirm={this.onPickerConfirm}
          />
          <div class={style.totalAmountWrapper}>
            {t('lobby.cpf.totalAmount')}
            {listError ? (
              <NetRetry
                class={style.miniTry}
                loading={isRefresh}
                axiosError={listError}
                displayType={DisplayType.InsufficientSpace}
                onRetry={this.refresh}
              />
            ) : (
              <span class={style.totalAmount}>
                {currencyFormatter(totalRecharge || 0, {
                  code: currentPeriodReward?.currency,
                  template: '%v%s',
                  symbol: ''
                })}
              </span>
            )}
          </div>
        </div>
        <CommonLoading spinning={this.state.loading}>
          <div class={style.rechargeFundDataList}>
            {this.listRenderHead()}
            <DataList
              ref="DataList"
              query={this.query}
              condition={this.state.condition}
              listRenderItem={this.listRenderItem}
            ></DataList>
          </div>
        </CommonLoading>
      </section>
    )
  }
}
