import { JackPotJumpEvent } from '@/api/aside/type'
import { PatchData, PatchRulesFilter, proxyPatchRules } from './proxyPatchRules'
import { RouteName } from '@/router/config'
import {
  State,
  createApiCodeToAnyMapper,
  initState,
  resetState
} from './initState'
import {
  TaskCategoryApiCodeEnum as TCACE,
  TaskCategoryApiCodeEnum
} from '../../api/type'
import { TaskRule } from '../../class/taskRule'
import { createMyPersistedStorage } from '@/utils/business-utils'
import { defineStore } from 'pinia'
import { proxyCodeOrderRulesMapper } from './proxyCodeOrderRulesMapper'
import { proxyFreshAllCodeCateReceive } from './proxyFreshAllCodeCateReceive'
import { proxyFreshCategoriesUsableAndRules } from './proxyFreshCategoriesUsableAndRules'
import { proxyFreshInTabsCategoryDataByCode } from './proxyFreshInTabsCategoryDataByCode'
import { random } from '@/utils/number'
// import { useMainStore } from '@/store/index'
// import { useTaskModalConstant } from '@/views/global-modal/modal/tasks-modal-dialog/store/task-modal-constant'
import { freshCategoryUsable } from './common/freshCategoryUsable'
import { useMainStore } from '@/store/index'
import { useTaskModalConstant } from '@/views/global-modal/modal/tasks-modal-dialog/store/task-modal-constant'
import Skin from '@/controller/Skin'
import router from '@/router'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Any = any
/** store的类型守卫 */
export const isTaskPageStore = (data: Any): data is TaskPageStore => {
  return data.$id === 'taskPageStore'
}
/** 本store的状态 */
export type TaskPageStore = ReturnType<typeof useTaskPageStore>
/** 任务弹框的store */
export const useTaskPageStore = defineStore('taskPageStore', {
  state: initState as () => State,
  getters: {
    /** [apiCode + ruleid]与rule的映射 */
    apiCodeIdToRuleMapper(state: State) {
      const idRuleMap: Record<`${TCACE}_${string}`, TaskRule> = {}
      for (const apiCode in state.codeRulesMapper) {
        const _apiCode = apiCode as unknown as TCACE
        const rules = state.codeRulesMapper[_apiCode]
        rules.forEach((rule) => {
          idRuleMap[`${_apiCode}_${rule.ruleid}`] = rule
        })
      }
      return idRuleMap
    },
    /** webId(apiCode + ruleid  + receiveLogId)与rule的映射 */
    webIdToRuleMapper(state: State) {
      const idRuleMap: Record<string, TaskRule> = {}
      for (const apiCode in state.codeRulesMapper) {
        const _apiCode = apiCode as unknown as TCACE
        const rules = state.codeRulesMapper[_apiCode]
        rules.forEach((rule) => {
          idRuleMap[rule.webId] = rule
        })
      }
      return idRuleMap
    },
    /** apiCode对应排序后的rulesMapper */
    codeOrderRulesMapper() {
      const orderMapper = proxyCodeOrderRulesMapper(this)
      return orderMapper
    },
    /** 是否显示任务页面入口  */
    isShowTaskPageEntrance() {
      /** 后台配置是否允许开启任务 */
      const isEnableServerConfig = Boolean(
        useMainStore().getActivesAuthInfos?.taskCount
      )
      /** 任务规则不为空 */
      const isTaskRulesNotEmpty = !useTaskModalConstant().isAllTaskRulesEmpty
      return isEnableServerConfig && isTaskRulesNotEmpty
    }
  },
  actions: {
    /** 刷新tabs任务[类型的可用状态]和[规则列表] */
    async freshCategoriesUsableAndRules(isForceFreshUsable?: boolean) {
      await proxyFreshCategoriesUsableAndRules(this, isForceFreshUsable)
    },
    /** 在tabs的层面上刷新类型数据 */
    async freshInTabsCategoryDataByCode(apiCode: TCACE) {
      await proxyFreshInTabsCategoryDataByCode(this, apiCode)
    },
    /** 发送请求->刷新任务tabs的[小红点/金额/ruleId]等相关信息 */
    async freshCodeCateReceive(isRefresh = false) {
      await proxyFreshAllCodeCateReceive(this, isRefresh)
    },
    /** 更新请求状态 */
    switchIsFetchingStatus(flag: boolean) {
      this.isFetching = flag
    },
    /** 局部更新rules函数类型 */
    patchRules(patchData: PatchData, filter?: PatchRulesFilter) {
      // 代理执行函数(逻辑抽离)
      proxyPatchRules(this, patchData, filter)
    },
    /** 打开包含所有任务项汇总的tabs,参数用于指定当前打开的默认任务类型 */
    async gotoTaskPage(apiCode?: TaskCategoryApiCodeEnum) {
      // TODO66 tab栏要隐藏的需求，目前仅针对通用版式隐藏tab栏的任务入口
      // TODO66 tab栏隐藏了, 则路由跳转也要拦截
      if (Skin.isUniversalThemeType && !this.isShowTaskPageEntrance) {
        return
      }
      // 如果用户有传入指定的apiCode,则先选中传入的Tab项
      if (apiCode) {
        this.curTabApiCode = apiCode
      }
      // 先将页面跳转过来
      router.push({
        name: RouteName.TASK
      })
      // 进行一次可用类型的校验,万一当前的apiCode不可用,则自动切换到一个可用的apiCode
      await freshCategoryUsable(this)
      // 将最终可用的apiCode赋值到路由中,它俩得保持双向交互,该赋值不能内聚到freshCategoryUsable绑定,就第一次需要主动赋值,后面都由asideTabs组件自动双向
      if (this.curTabApiCode) {
        router.replace({
          name: RouteName.TASK,
          query: {
            ...router.currentRoute.query,
            curTask: this.curTabApiCode
          } as Any
        })
      }
    },
    /** 我的重置*/
    myReset() {
      resetState(this)
    },
    /** 刷新视图 */
    freshView() {
      this.dataChangeFreshView = random(1000000)
    },
    /** 设置一个状态 */
    setIsTimeoutError(key: TCACE | 'canUsable', isTimeoutError: boolean) {
      this.timeoutErrorMapper[key] = isTimeoutError
    },
    /** 重置超时提示的mapper的状态 */
    resetIsTimeoutErrorMapper() {
      this.timeoutErrorMapper = {
        ...createApiCodeToAnyMapper(false),
        canUsable: false
      }
    },
    /** 设置loading状态 */
    setIsLoading(isLoading: boolean) {
      this.isLoading = isLoading
    }
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: 'web.lobby.taskModal',
        paths: [
          'isFetched',
          'fetchedDataLanguage',
          'codeThisTimeNoReminderMapper',
          'codeCategoryMapper',
          'codeRulesMapper'
        ],
        storage: createMyPersistedStorage()
      }
    ]
  }
})

/** 彩金池的codeMapper与apiCode的映射 */
const jackPotCodeApiCodeMapper = {
  [JackPotJumpEvent.NewcomerBenefits]: TaskCategoryApiCodeEnum.xrfl,
  [JackPotJumpEvent.DailyTasks]: TaskCategoryApiCodeEnum.mrrw,
  [JackPotJumpEvent.WeeklyTasks]: TaskCategoryApiCodeEnum.mzrw,
  [JackPotJumpEvent.MysteriousTasks]: TaskCategoryApiCodeEnum.smrw
} as Record<JackPotJumpEvent, TaskCategoryApiCodeEnum>

/** 获得apiCode通过后端返回的彩金池的code */
export const getApiCodeByJackPotCode = (jackPotCode: JackPotJumpEvent) => {
  return jackPotCodeApiCodeMapper[jackPotCode]
}
