import { Base, Component, Ref } from '@/vue-property-decorator'
import ChampionBet from '../selfoperated-games/sports/global-modal/championBet'
import DevtoolsModal from './modal/devtools-modal'
import Modal from '@/controller/Modal'
import SearchLeague from '../selfoperated-games/sports/global-modal/searchLeague'
import SearchSportEvent from '../selfoperated-games/sports/global-modal/searchSportEvent'
import type AfterRechargeActivityModal from '@/views/global-modal/modal/after-recharge-activity'
import type AssetSecurity from './modal/asset-security'
import type CutOnce from '@/views/global-modal/modal/cut-once'
import type DiscountCode from '@/views/global-modal/modal/discountCode'
import type DownloadAppBySite from '@/layouts/components/downloadApp/download-app-by-site'
import type FirstChargeConstant from '@/views/global-modal/modal/first-charge-constant'
import type ForceChangePwd from '@/views/global-modal/modal/force-change-pwd'
import type ForcePopup from '@/views/global-modal/modal/force-popup'
import type ForgotPasswordModal from '@/views/global-modal/modal/forgot-password'
import type IframePopup from '@/views/global-modal/modal/iframePopup'
import type LoginAuthModal from '@/views/global-modal/modal/loginAuth'
import type LoginPrompter from '@/views/global-modal/modal/login-prompter'
import type LoginRegisterModal from '@/views/global-modal/modal/login-registry'
import type LoginRegisterSuccessModal from '@/views/global-modal/modal/login-registry-success'
import type MaintainModal from '@/views/global-modal/modal/maintain'
import type MusicModal from './modal/music'
import type NewBenefitsModal from '@/views/global-modal/modal/tasks-modal-dialog/new-benefits'
import type NewCutOnce from '@/views/global-modal/modal/new-cut-once'
import type ParlayBetResult from '@/views/selfoperated-games/sports/global-modal/parlayBetResult'
import type PassModal from './modal/password'
import type PayModal from './modal/pay'
import type PayQRCodeModal from './modal/pay-qrcode'
import type PayRecordModal from './modal/pay-record'
import type PingModal from '@/views/global-modal/modal/ping'
import type ReceiveAnimation from './modal/receive-animation'
import type RedPocketModal from './modal/red-pocket'
import type Sign from '@/views/global-modal/modal/sign'
import type SmartPayModal from '@/views/global-modal/modal/smart-pay'
import type SportsBet from '@/views/selfoperated-games/sports/global-modal/sportsBet'
import type SportsLeagueFilter from '@/views/selfoperated-games/sports/global-modal/sportsLeagueFilter'
import type SwitchCurrencyPopup from '@/components/business-components/switch-currency/modal'
import type SwitchLangPopup from '@/components/business-components/switch-lang/modal'
import type TasksAllTabs from '@/views/global-modal/modal/tasks-modal-dialog/task-all-tabs/index'
import type TasksDaily from '@/views/global-modal/modal/tasks-modal-dialog/tasks-daily'
import type TasksEvery3Day from './modal/tasks-modal-dialog/tasks-every-3day'
import type TasksWeekly from '@/views/global-modal/modal/tasks-modal-dialog/tasks-weekly'
// import type TaskModal from '@/views/global-modal/modal/task'
import ImportTips from './modal/import-tips'
import type VerifyUserPassword from '@/views/global-modal/modal/verify-user-password'
import type VeriryBindPhone from '@/views/global-modal/modal/verify-bind-phone'
import type WaitingGet from './modal/waiting-get'
const modalList = [
  'loginRegisterModal',
  'loginRegisterSuccessModal',
  //TODO66 使用新的tasksAllTabs组件赋值到 taskModal 上，兼容旧的有些 taskModal 还未改的内容，后期必须删除它
  'taskModal',
  'forgotPasswordModal',
  'payModal',
  'payRecordModal',
  'passModal',
  'forceChangePwd',
  'forcePopup',
  'devtoolsModal',
  'newBenefitsModal',
  'veriryBindPhone',
  'loginAuthModal',
  'verifyUserPassword',
  'discountCode',
  'tasksDaily',
  'tasksEvery3Day',
  'tasksWeekly',
  'firstChargeConstant',
  // 活动弹窗
  'afterRechargeActivity',
  // 'tasksAllTabs',
  'sportsBet',
  'championBet',
  'searchSportEvent',
  'searchLeague',
  'parlayBetResult',
  'sportsLeagueFilter',
  'maintainModal',
  'iframePopup',
  'iframeAccessRestrictedPopup',
  'iframeMaintainPopup',
  'switchLangPopup',
  'switchCurrencyPopup',
  'pingModal',
  'redPocketModal',
  'musicModal',
  'waitingGet',
  'cutOnce',
  'sign',
  'newCutOnce',
  'smartPayModal',
  'receiveAnimation',
  'assetSecurity',
  'loginPrompter',
  'payQRCodeModal',
  'downloadAppBySite',
  'importTips'
] as const
@Component<GlobalModal>({
  name: 'GlobalModal',
  components: {
    loginRegisterModal: () =>
      import('@/views/global-modal/modal/login-registry'),
    LoginRegisterSuccessModal: () =>
      import('@/views/global-modal/modal/login-registry-success'),
    forgotPasswordModal: () =>
      import('@/views/global-modal/modal/forgot-password'),
    payModal: () => import(/* webpackChunkName: "pay" */ './modal/pay'),
    payRecordModal: () =>
      import(
        /* webpackChunkName: "payRecord" */ '@/views/global-modal/modal/pay-record'
      ),
    passModal: () => import('./modal/password'),
    forceChangePwd: () => import('@/views/global-modal/modal/force-change-pwd'),
    forcePopup: () => import('@/views/global-modal/modal/force-popup'),
    devtoolsModal: () => import('@/views/global-modal/modal/devtools-modal'),
    newBenefitsModal: () =>
      import('@/views/global-modal/modal/tasks-modal-dialog/new-benefits'),
    veriryBindPhone: () =>
      import('@/views/global-modal/modal/verify-bind-phone'),
    loginAuthModal: () => import('@/views/global-modal/modal/loginAuth'),
    verifyUserPassword: () =>
      import('@/views/global-modal/modal/verify-user-password'),
    discountCode: () => import('@/views/global-modal/modal/discountCode'),
    tasksDaily: () =>
      import('@/views/global-modal/modal/tasks-modal-dialog/tasks-daily'),
    tasksEvery3Day: () =>
      import('@/views/global-modal/modal/tasks-modal-dialog/tasks-every-3day'),
    tasksWeekly: () =>
      import('@/views/global-modal/modal/tasks-modal-dialog/tasks-weekly'),
    firstChargeConstant: () =>
      import('@/views/global-modal/modal/first-charge-constant/index'),
    // tasksAllTabs: () =>
    //   import(
    //     '@/views/global-modal/modal/tasks-modal-dialog/task-all-tabs/index'
    //   ),
    //TODO66 使用新的tasksAllTabs组件赋值到 taskModal 上，兼容旧的有些 taskModal 还未改的内容，后期必须删除它
    taskModal: () =>
      import(
        '@/views/global-modal/modal/tasks-modal-dialog/task-all-tabs/index'
      ),
    redPocketModal: () => import('@/views/global-modal/modal/red-pocket/index'),
    afterRechargeActivity: () =>
      import('@/views/global-modal/modal/after-recharge-activity'),
    sportsBet: () =>
      import('@/views/selfoperated-games/sports/global-modal/sportsBet'),
    championBet: () =>
      import('@/views/selfoperated-games/sports/global-modal/championBet'),
    searchSportEvent: () =>
      import('@/views/selfoperated-games/sports/global-modal/searchSportEvent'),
    searchLeague: () =>
      import('@/views/selfoperated-games/sports/global-modal/searchLeague'),
    parlayBetResult: () =>
      import('@/views/selfoperated-games/sports/global-modal/parlayBetResult'),
    sportsLeagueFilter: () =>
      import(
        '@/views/selfoperated-games/sports/global-modal/sportsLeagueFilter'
      ),
    maintainModal: () => import('@/views/global-modal/modal/maintain'),
    iframePopup: () => import('@/views/global-modal/modal/iframePopup'),
    iframeAccessRestrictedPopup: () =>
      import('@/views/global-modal/modal/iframePopup'),
    iframeMaintainPopup: () => import('@/views/global-modal/modal/iframePopup'),
    pingModal: () => import('@/views/global-modal/modal/ping'),
    musicModal: () => import('@/views/global-modal/modal/music'),
    switchLangPopup: () =>
      import('@/components/business-components/switch-lang/modal'),
    switchCurrencyPopup: () =>
      import('@/components/business-components/switch-currency/modal'),
    waitingGet: () => import('@/views/global-modal/modal/waiting-get/index'),
    cutOnce: () => import('@/views/global-modal/modal/cut-once/index'),
    sign: () => import('@/views/global-modal/modal/sign/index'),
    newCutOnce: () => import('@/views/global-modal/modal/new-cut-once/index'),
    smartPayModal: () => import('@/views/global-modal/modal/smart-pay'),
    receiveAnimation: () =>
      import('@/views/global-modal/modal/receive-animation'),
    payQRCodeModal: () => import('@/views/global-modal/modal/pay-qrcode'),
    assetSecurity: () =>
      import('@/views/global-modal/modal/asset-security/index'),
    loginPrompter: () => import('@/views/global-modal/modal/login-prompter'),
    downloadAppBySite: () =>
      import('@/layouts/components/downloadApp/download-app-by-site'),
    importTips: () => import('@/views/global-modal/modal/import-tips')
  }
})

/** 用来管理所有的modal的组件,
 * 在组件层级上,本组件管理了所有其它子组件,
 * 但在htmlElement元素上,并没有嵌套关系.
 */
export default class GlobalModal extends Base {
  @Ref()
  public readonly loginRegisterModal!: LoginRegisterModal
  @Ref()
  public readonly afterRechargeActivity!: AfterRechargeActivityModal
  @Ref()
  public readonly loginRegisterSuccessModal!: LoginRegisterSuccessModal
  @Ref()
  public readonly taskModal!: TasksAllTabs
  @Ref()
  public readonly forgotPasswordModal!: ForgotPasswordModal
  @Ref()
  public readonly payModal!: PayModal
  @Ref()
  public readonly payRecordModal!: PayRecordModal
  @Ref()
  public readonly passModal!: PassModal
  @Ref()
  public readonly forceChangePwd!: ForceChangePwd
  @Ref()
  public readonly forcePopup!: ForcePopup
  @Ref()
  public readonly devtoolsModal!: DevtoolsModal
  @Ref()
  public readonly newBenefitsModal!: NewBenefitsModal
  @Ref()
  public readonly veriryBindPhone!: VeriryBindPhone
  @Ref()
  public readonly loginAuthModal!: LoginAuthModal
  @Ref()
  public readonly verifyUserPassword!: VerifyUserPassword
  @Ref()
  public readonly discountCode!: DiscountCode
  @Ref()
  public readonly tasksDaily!: TasksDaily
  @Ref()
  public readonly tasksEvery3Day!: TasksEvery3Day
  @Ref()
  public readonly tasksWeekly!: TasksWeekly
  @Ref()
  public readonly firstChargeConstant!: FirstChargeConstant
  // @Ref()
  // public readonly tasksAllTabs!: TasksAllTabs
  @Ref()
  public readonly sportsBet!: SportsBet
  @Ref()
  public readonly championBet!: ChampionBet
  @Ref()
  public readonly searchSportEvent!: SearchSportEvent
  @Ref()
  public readonly searchLeague!: SearchLeague
  @Ref()
  public readonly parlayBetResult!: ParlayBetResult
  @Ref()
  public readonly sportsLeagueFilter!: SportsLeagueFilter
  public readonly maintainModal!: MaintainModal
  @Ref()
  public readonly iframePopup!: IframePopup
  @Ref()
  public readonly iframeMaintainPopup!: IframePopup
  @Ref()
  public readonly iframeAccessRestrictedPopup!: IframePopup
  @Ref()
  public readonly pingModal!: PingModal
  @Ref()
  public readonly musicModal!: MusicModal
  @Ref()
  public readonly switchLangPopup!: SwitchLangPopup
  @Ref()
  public readonly switchCurrencyPopup!: SwitchCurrencyPopup
  @Ref()
  public readonly waitingGet!: WaitingGet
  @Ref()
  public readonly loginPrompter!: LoginPrompter
  @Ref()
  public readonly cutOnce!: CutOnce
  @Ref()
  public readonly sign!: Sign
  @Ref()
  public readonly newCutOnce!: NewCutOnce
  @Ref()
  public readonly smartPayModal!: SmartPayModal
  @Ref()
  public readonly receiveAnimation!: ReceiveAnimation
  @Ref()
  public readonly assetSecurity!: AssetSecurity
  @Ref()
  public readonly redPocketModal!: RedPocketModal
  @Ref()
  public readonly payQRCodeModal!: PayQRCodeModal
  @Ref()
  public readonly downloadAppBySite!: DownloadAppBySite
  @Ref()
  public readonly importTips!: ImportTips

  public status: Record<
    typeof modalList[number],
    'wait' | 'loaded' | 'loading'
  > = {
    loginRegisterModal: 'wait',
    loginRegisterSuccessModal: 'wait',
    //TODO66 使用新的tasksAllTabs组件赋值到 taskModal 上，兼容旧的有些 taskModal 还未改的内容，后期必须删除它
    taskModal: 'wait',
    forgotPasswordModal: 'wait',
    payModal: 'wait',
    payRecordModal: 'wait',
    passModal: 'wait',
    forceChangePwd: 'wait',
    forcePopup: 'wait',
    devtoolsModal: 'wait',
    newBenefitsModal: 'wait',
    veriryBindPhone: 'wait',
    loginAuthModal: 'wait',
    verifyUserPassword: 'wait',
    discountCode: 'wait',
    tasksDaily: 'wait',
    tasksEvery3Day: 'wait',
    tasksWeekly: 'wait',
    firstChargeConstant: 'wait',
    afterRechargeActivity: 'wait',
    // tasksAllTabs: 'wait',
    sportsBet: 'wait',
    championBet: 'wait',
    searchSportEvent: 'wait',
    searchLeague: 'wait',
    parlayBetResult: 'wait',
    sportsLeagueFilter: 'wait',
    maintainModal: 'wait',
    redPocketModal: 'wait',
    iframePopup: 'wait',
    iframeMaintainPopup: 'wait',
    iframeAccessRestrictedPopup: 'wait',
    pingModal: 'wait',
    musicModal: 'wait',
    switchLangPopup: 'wait',
    switchCurrencyPopup: 'wait',
    waitingGet: 'wait',
    cutOnce: 'wait',
    sign: 'wait',
    newCutOnce: 'wait',
    smartPayModal: 'wait',
    receiveAnimation: 'wait',
    assetSecurity: 'wait',
    loginPrompter: 'wait',
    payQRCodeModal: 'wait',
    downloadAppBySite: 'wait',
    importTips: 'wait'
  }
  render() {
    return (
      <div class="global-modal">
        {modalList.map((modalCom) => {
          return (
            this.status[modalCom] !== 'wait' && (
              <modalCom
                key={modalCom}
                ref={modalCom}
                {...{
                  on: {
                    'modal:close': () => {
                      Modal.removeModalName(modalCom)
                    }
                  }
                }}
              />
            )
          )
        })}
      </div>
    )
  }
}

/* eslint-disable @typescript-eslint/no-explicit-any*/
type Any = any
/** 取得弹窗名称与它对应的open方法的映射 */
export type GetModalNameToOpen<
  ModalNames extends string = typeof modalList[number],
  Modal = GlobalModal
> = {
  [K in ModalNames]: K extends keyof Modal
    ? Modal[K] extends { open: (...para: Any[]) => Any }
      ? Modal[K]['open']
      : never
    : never
}
/** 使用【ModalNameToOpen】生成函数重载(类似效果) */
export type ModalOpenFun = <Name extends keyof GetModalNameToOpen>(
  modalName: Name,
  ...options: Parameters<GetModalNameToOpen[Name]>
) => ReturnType<GetModalNameToOpen[Name]>
