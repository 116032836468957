import {
  AmountTypeEnum,
  ApiEvent,
  ApiEventGet,
  BindPhoneEnum,
  CheckPhoneEnum,
  ContentConfig,
  ContentConfigAmountItem,
  DisableShowRewardEnum,
  DiscountCodeMethod,
  DiscountCodeResp,
  DiscountCodeStatusEnum,
  DiscountCodeTypeEnum,
  ReceiveStatusEnum
} from '@/api/event/type'
import { AxiosResponse } from 'axios'
import { Base, Component, Emit, Prop, Ref } from '@/vue-property-decorator'
import { Button, Input } from '@/plugins/ant-design-vue/tsx-support'
import { ComTableColumnType } from '@/components/business-components/table/type'
import {
  CommonLoading,
  CommonPaste,
  CommonTable
} from '@/components/business-components'
import { GlobalConst, GlobalManager } from '@/context'
import { IsShowDiscountResp } from '@/api/event/type'
import { NormalLoginFormData } from '@/api/common/type'
import { Portal } from 'portal-vue'
import {
  apiCheckCodePhoneNumber,
  apiEventGet,
  apiUseDiscountCode
} from '@/api/event'
import { apiRiskBindPhone, apiVerifyPhone } from '@/api/common'
import { brazilianCurrencyFormatter } from '@/utils/business-utils/finance'
import {
  canReceiveReward,
  getMosaicValidTips,
  goBack,
  showErrorMessageModal
} from '@/views/event/item/utils'
import { createSvgSprite } from '@/utils/business-utils/assets'
import { openHack } from '@/utils/business-utils'
import { useI18n } from '@/i18n'
import { useMainStore } from '@/store/index'
import AppTypes from '@/vue-types'
import AutoShrinkText from '@/components/auto-shrink-text'
import BusinessUtils from '@/utils/business-utils'
import Modal from '@/controller/Modal'
import NetRetry, {
  DisplayType
} from '@/components/business-components/net-retry'
import PublicIntroduction from './components/introduction'
import QRCode from '@/components/business-components/qr-code'
import VeriryBindPhone from './components/verify-bind-phone'
import style from './style.module.scss'
import to from 'await-to-js'
type tabsItem = {
  label: string
  value: number
}

enum RiskBindPhoneEnum {
  /**
   * 未绑定
   */
  Unbound,
  /**
   * 已绑定
   */
  Bound
}

interface State {
  discountCode?: string
  loading: boolean
  eventData: ApiEventGet | null
  bindPhoneForm: NormalLoginFormData | null
  tabsList: tabsItem[]
  activeTabsIndex: number
  discountConfigData: IsShowDiscountResp[] | null
  riskBindPhoneStatus: RiskBindPhoneEnum
  hasReceived: boolean
  isTimeoutError: boolean
  refreshing: boolean
}

interface Props {
  title?: string
  isModal?: boolean
  isAfterRechargeModal?: boolean
  // contentConfig: ContentConfig
  activeId: number
  /**
   * 活动详情数据
   */
  event?: ApiEventGet
}
@Component<DiscountCode>({
  name: 'DiscountCode'
})
export default class DiscountCode extends Base<State, Props> {
  state: State = {
    discountCode: undefined,
    loading: false,
    eventData: null,
    bindPhoneForm: null,
    tabsList: [],
    activeTabsIndex: 0,
    discountConfigData: null,
    riskBindPhoneStatus: RiskBindPhoneEnum.Unbound,
    hasReceived: false, // 是否已领取
    isTimeoutError: false,
    refreshing: false
  }

  @Ref()
  veriryBindPhone!: VeriryBindPhone

  // @Prop(AppTypes.object.isRequired)
  // contentConfig!: Props['contentConfig']

  @Prop(AppTypes.number.isRequired)
  activeId!: Props['activeId']

  /**
   * 活动详情数据
   */
  @Prop({ required: false })
  title!: Props['title']

  @Prop({ required: false })
  event!: Props['event']

  @Prop(AppTypes.bool.def(false))
  isModal!: Props['isModal']

  @Prop()
  isAfterRechargeModal!: Props['isAfterRechargeModal']

  @Emit('success')
  private onSuccess() {
    return
  }

  @Emit('changeCodeType')
  private onChangeCodeType() {
    return this.contentConfig?.codeType
  }

  private get eventData() {
    if (this.event) {
      return this.event
    }
    return this.state.eventData
  }

  get contentConfig() {
    if (!this.eventData) return null
    const { activeData } = this.eventData! || {}
    if (!activeData) return null
    const { contentConfig } = activeData as ApiEvent
    return contentConfig as ContentConfig
  }

  get columns(): Array<ComTableColumnType> {
    const { t } = useI18n()
    const { amountType } = this.contentConfig || {}

    // 充值-固定奖励
    const signType0 = [
      {
        title: t('lobby.event.listedRewards.ruleTabe.awardAmount'),
        dataIndex: 'rewardAmount',
        key: 'reward',
        customRender: (value: string) => {
          return (
            <span class={style.tableAmount}>
              {brazilianCurrencyFormatter(value)}
            </span>
          )
        }
      }
    ] as Array<ComTableColumnType>
    // 充值-比例奖励
    const signType1 = [
      {
        title: t('lobby.event.listedRewards.ruleTabe.rewardRatio'),
        dataIndex: 'ratio',
        key: 'ratio',
        customRender: (value: string) => {
          return <span class={style.tableAmount}>{value}%</span>
        }
      },
      {
        title: t('lobby.event.listedRewards.ruleTabe.awarMaxLimit'),
        dataIndex: 'limitReward',
        key: 'limitReward',
        customRender: (value: number) => {
          return (
            <span>
              {brazilianCurrencyFormatter(value)?.replace(/(.|,)00$/, '') ||
                t('lobby.event.discountCode.notProfitLimit')}
            </span>
          )
        }
      }
    ] as Array<ComTableColumnType>
    let column = [
      {
        title: t('lobby.event.discountCode.firstDepositCount'),
        dataIndex: 'rechargeAmount',
        key: 'rechargeAmount',
        customRender: (value: number) => {
          return (
            <span>
              ≥{brazilianCurrencyFormatter(value)?.replace(/(.|,)00$/, '')}
            </span>
          )
        }
      }
    ] as Array<ComTableColumnType>

    if (amountType === AmountTypeEnum.FirstChargeRatio) {
      column = [...column, ...signType1]
    } else if (amountType === AmountTypeEnum.Fixed) {
      column = [...column, ...signType0]
    }

    return column
  }

  // 未开始或者已结束
  private get notInTimeRange() {
    const { startTime, endTime } = this.eventData || {}
    const now = +new Date()
    const startTimeDate: number = startTime
      ? parseInt(String(startTime * 1000))
      : 0
    const endTimeDate: number = endTime ? parseInt(String(endTime * 1000)) : 0

    return startTimeDate - now > 0 || endTimeDate - now < 0
  }

  get bindPhone() {
    if (!this.eventData) return null
    const { activeData } = this.eventData!
    const { bindPhone } = activeData as ApiEvent
    return bindPhone
  }

  async mounted() {
    await this.featchData()
  }

  private async refresh() {
    if (this.state.refreshing) return
    this.setState({
      refreshing: true
    })
    await this.featchData()
    this.setState({
      refreshing: false
    })
  }

  private async featchData() {
    const [err, data] = await to(apiEventGet(this.activeId, true))
    this.setState({
      isTimeoutError: !!err
    })
    if (err) {
      return
    }
    this.setState({
      eventData: data
    })
    this.$nextTick(() => {
      this.onChangeCodeType()
    })
  }

  private renderQRCode() {
    const { eventData } = this
    const receiveDeviceType: string = eventData?.receiveDeviceType ?? ''
    const { errorMessage } = getMosaicValidTips(receiveDeviceType)
    return (
      <div>
        <div>{errorMessage}</div>
        <QRCode />
      </div>
    )
  }

  /**
   * 是否显示获取兑换码按钮
   */
  private get showDiscountCodeLinkBtn() {
    return this.contentConfig?.getCodeMethod === DiscountCodeMethod.LinkToGet
  }

  /**
   * 获取兑换码
   */
  private getDiscountCodeLink() {
    // bug 164585 修改跳转链接为手机端
    if (!this.contentConfig?.getCodeAppLink) {
      return
    }
    openHack(this.contentConfig.getCodeAppLink)
  }

  /**
   * 邀请链接彩金领取逻辑（codeType = 6）
   */
  private async handleInvitationLinkReceive() {
    const params = {
      codeType:
        this.contentConfig?.codeType ?? DiscountCodeTypeEnum.InvitationLink,
      discountCode: '',
      activeId: Number(this.activeId)
    }

    this.setState({ loading: true })
    const [err, data] = await to(apiUseDiscountCode(params))
    this.setState({ loading: false })
    if (err) {
      const response = err as unknown as AxiosResponse
      const errorMsg: string = response?.data?.msg ?? ''
      showErrorMessageModal(errorMsg)
      this.featchData()
      return
    }
    this.handleReceiveSuccess(data)
  }

  private async useDiscountCode(
    verifyParam?: Record<string, string>,
    codeType?: DiscountCodeTypeEnum
  ) {
    const { hasLogined } = useMainStore()
    if (!hasLogined) {
      Modal.openLoginRegisterModal()
      return
    }
    const { discountCode, loading, riskBindPhoneStatus } = this.state
    const { eventData } = this
    if (loading || !discountCode) return
    this.state.loading = true
    const { t } = useI18n()
    // 已绑定手机优惠码
    if (
      verifyParam &&
      codeType === DiscountCodeTypeEnum.BoundPhone &&
      this.bindPhone === BindPhoneEnum.Bound
    ) {
      const [err] = await to(
        apiVerifyPhone({
          phone: verifyParam.phone,
          captcha: verifyParam.verifyCode,
          flag: discountCode
        })
      )
      this.state.loading = false
      if (err) throw err
    }

    // 未绑定手机优 先绑定手机，再领取彩金
    if (
      verifyParam &&
      codeType === DiscountCodeTypeEnum.UnboundPhone &&
      this.bindPhone === BindPhoneEnum.Unbound &&
      riskBindPhoneStatus !== RiskBindPhoneEnum.Bound
    ) {
      const [err] = await to(
        apiRiskBindPhone({
          phone: verifyParam.phone?.includes('+')
            ? verifyParam.phone
            : `${this.contentConfig?.areaCode}-${verifyParam.phone}`,
          captcha: verifyParam.verifyCode,
          flag: discountCode
        })
      )
      this.state.loading = false
      if (err) {
        this.state.riskBindPhoneStatus = RiskBindPhoneEnum.Unbound
        throw err
      } else {
        this.state.riskBindPhoneStatus = RiskBindPhoneEnum.Bound
      }
    }

    if (discountCode) {
      // 判断领取入口
      if (!canReceiveReward(eventData?.receiveDeviceType, this.renderQRCode)) {
        this.state.loading = false
        return
      }

      if (codeType === DiscountCodeTypeEnum.UnboundPhone) {
        // 校验手机是否可用
        const checkAreaCode = verifyParam?.phone?.includes('-')
          ? verifyParam.phone?.split('-')[0] ?? ''
          : this.contentConfig?.areaCode ?? ''

        const checkPhoneNumber = verifyParam?.phone?.includes('-')
          ? verifyParam.phone?.split('-')[1] ?? ''
          : verifyParam?.phone ?? ''

        const { status } = await apiCheckCodePhoneNumber({
          areaCode: checkAreaCode,
          phoneNumber: checkPhoneNumber,
          activeId: Number(this.activeId)
        })
        /** 0成功 1活动ID无效 2活动id不是电话彩金 3区域码不正确 4电话号码不存在 5电话号码已被使用 */
        if (status) {
          GlobalManager.Modal.message({
            type: 'error',
            content: () => {
              switch (status) {
                case CheckPhoneEnum.Invalid:
                  return t('lobby.event.discountCode.activityIDInvalid')
                case CheckPhoneEnum.NotPhoneWinnings:
                  return t('lobby.event.discountCode.notPhoneGold')
                case CheckPhoneEnum.IncorrectAreaCode:
                  return t('lobby.event.discountCode.areaCodeIncorrect')
                case CheckPhoneEnum.PhoneNotExist:
                  return t('lobby.event.discountCode.phoneNumberNothingness')
                case CheckPhoneEnum.PhoneAlreadyUse:
                  return t('lobby.event.discountCode.phoneNumberBeUsed')
                default:
                  return ''
              }
            }
          })
          this.state.loading = false
          return Promise.reject()
        }
      }

      const params = {
        codeType:
          this.contentConfig?.codeType || DiscountCodeTypeEnum.Universal,
        discountCode:
          this.contentConfig?.codeType ===
            DiscountCodeTypeEnum.InvitationLink ||
          (this.contentConfig?.codeType === DiscountCodeTypeEnum.UnboundPhone &&
            (this.bindPhone === BindPhoneEnum.HasPhoneNumber ||
              this.state.riskBindPhoneStatus === RiskBindPhoneEnum.Bound))
            ? ''
            : discountCode,
        activeId: Number(this.activeId)
      }

      const [err, data] = await to(apiUseDiscountCode(params))
      this.state.loading = false
      if (err) {
        const response = err as unknown as AxiosResponse
        const errorMsg: string = response?.data?.msg ?? ''
        showErrorMessageModal(errorMsg)
        this.featchData()
        return
      }

      this.handleReceiveSuccess(data)
    }
  }

  /**
   * 彩金领取成功后回调展示
   */
  private handleReceiveSuccess(data: DiscountCodeResp) {
    const { t } = useI18n()
    const { audioReceivePlay } = useMainStore()
    if (!data?.status) {
      if (data.withdrawLimit) {
        GlobalManager.Modal.create({
          class: style.receiveModal,
          title: t('lobby.receiveModal.kindTips'),
          titleType: 'success',
          width: BusinessUtils.px2rem(600),
          content: () => [
            <inner-html
              key={'1'}
              text={t('lobby.event.discountCode.success', {
                x: `<span class="${GlobalConst.CssClassName.Lobby.Color.Yellow}">
                        ${data.amount}
                      </span>`
              })}
              type="div"
            ></inner-html>,
            <div key={'2'}>
              {t('lobby.event.discountCode.needRecharge', {
                x: data.withdrawLimit
              })}
            </div>
          ],
          hiddenBtns: true,
          closable: true
        })
      } else {
        GlobalManager.Modal.message({
          type: 'success',
          content: () =>
            t('lobby.event.discountCode.success', {
              x: brazilianCurrencyFormatter(data.amount)
            })
        })
      }
      this.state.hasReceived = true
      audioReceivePlay()
      this.featchData()
      this.onSuccess()
    } else {
      const isMustPay =
        data.status === DiscountCodeStatusEnum.RechargeAtLeastOnce
      GlobalManager.Modal.create({
        class: style.receiveModal,
        title: t('lobby.receiveModal.kindTips'),
        width: BusinessUtils.px2rem(600),
        content: () => data.message,
        hiddenBtns: !isMustPay,
        closable: true,
        okText: t('lobby.event.discountCode.payBtn').toString(),
        onOk: () => {
          GlobalManager.Modal.open('payModal')
        }
      })
    }
  }

  /**
   * 渲染兑换码组件
   */
  private renderDiscountCodeComponent() {
    const { t } = useI18n()
    return (
      <div class={style.input}>
        <Input.Tsx
          v-model={this.state.discountCode}
          disabled={this.notInTimeRange}
          placeholder={t('lobby.event.discountCode.placeholder').toString()}
          prefix={() => (
            <span class={style.dhmSvg}>
              <icon-sprite sprite={createSvgSprite('input_icon_dhm')} />
            </span>
          )}
          suffix={() => (
            <CommonPaste
              class={[
                style.paste,
                this.notInTimeRange ? style.pasteDisabled : ''
              ]}
              onComplete={(val) => {
                if (!this.notInTimeRange) this.state.discountCode = val
              }}
            />
          )}
        />
      </div>
    )
  }

  /**
   * 渲染验证手机号和短信验证码组件
   */
  private renderVeriryBindPhoneComponent() {
    const { codeType, areaCode } = this.contentConfig || {}
    return (
      <VeriryBindPhone
        class={[
          style.veriryBindPhoneWrapper,
          codeType === DiscountCodeTypeEnum.UnboundPhone
            ? style.unboundPhone
            : ''
        ]}
        ref="veriryBindPhone"
        activeId={this.activeId}
        codeType={codeType}
        bindPhone={this.bindPhone}
        areaCode={areaCode}
        item={this.eventData}
        onComplete={(form: NormalLoginFormData) => {
          this.state.bindPhoneForm = form
        }}
      />
    )
  }

  /**
   * 状态文案
   */
  private get getStatusText() {
    const { t } = useI18n()
    const mapper = {
      [DiscountCodeTypeEnum.Universal]: () =>
        t('lobby.event.discountCode.congratulationsReCode') as string,
      [DiscountCodeTypeEnum.Unique]: () =>
        t('lobby.event.discountCode.congratulationsReCode') as string,
      [DiscountCodeTypeEnum.BoundPhone]: () =>
        t('lobby.event.discountCode.congratulations') as string,
      [DiscountCodeTypeEnum.UnboundPhone]: () =>
        t('lobby.event.discountCode.congratulations') as string,
      [DiscountCodeTypeEnum.FirstCharge]: () =>
        t('lobby.event.discountCode.congratulationsReCode') as string,
      [DiscountCodeTypeEnum.InvitationLink]: () =>
        t('lobby.event.discountCode.completedRegistration') as string
    }
    return mapper[this.contentConfig?.codeType as keyof typeof mapper] || ''
  }

  /**
   * 彩金已领取成功后的展示组件
   */
  renderReceivedSuccessComponent() {
    return (
      <div class={style.discountCodesuccessTip}>
        <div class={style.successIcon}>
          <icon-sprite sprite={createSvgSprite('comm_icon_gou')} />
        </div>
        <span>{this.getStatusText()}</span>
      </div>
    )
  }

  /**
   * 按钮文案
   */
  private getBtnText(status: ReceiveStatusEnum) {
    const { t } = useI18n()
    const mapper = {
      [ReceiveStatusEnum.Processing]: t(
        'lobby.event.discountCode.confirmTheExchange'
      ) as string,
      [ReceiveStatusEnum.HasReceive]: t('lobby.modal.task.collected') as string,
      [ReceiveStatusEnum.Distributed]: t(
        'lobby.event.discountCode.distributed'
      ) as string
    }
    return (
      mapper[status as keyof typeof mapper] ||
      t('lobby.event.discountCode.confirmTheExchange')
    )
  }

  /**
   * 渲染活动领取按钮
   */
  private renderDiscountBtn() {
    const { isWeb, userInfos, hasLogined } = useMainStore()
    const { codeType } = this.contentConfig || {}
    const { activeData } = this.eventData || {}
    const activeDetail = activeData as ApiEvent
    const { t } = useI18n()
    const onOk = async () => {
      if (!hasLogined) {
        Modal.openLoginRegisterModal()
        return
      }

      if (
        (codeType === DiscountCodeTypeEnum.BoundPhone &&
          this.bindPhone === BindPhoneEnum.Unbound) ||
        (codeType === DiscountCodeTypeEnum.UnboundPhone &&
          this.bindPhone === BindPhoneEnum.Bound)
      ) {
        GlobalManager.Modal.create({
          title: t('lobby.receiveModal.kindTips'),
          width: BusinessUtils.px2rem(600),
          hiddenBtns: true,
          closable: true,
          content: () => (
            <div>
              <p>
                {codeType === DiscountCodeTypeEnum.BoundPhone &&
                this.bindPhone === BindPhoneEnum.Unbound
                  ? t('lobby.event.discountCode.notEligibleEvent')
                  : t('lobby.event.discountCode.activityDissatisfy')}
              </p>
            </div>
          )
        })
        return
      }
      if (
        this.event &&
        !canReceiveReward(this.event.receiveDeviceType, this.renderQRCode)
      ) {
        return
      }
      if (this.veriryBindPhone) {
        const verifyCode = await this.veriryBindPhone.onSubmit()
        if (!verifyCode) return
        this.state.discountCode = `active_code_${this.activeId}_${
          userInfos!.username
        }`
        this.useDiscountCode(verifyCode, codeType)
      } else {
        // 判断是否已绑定手机 && 完成首充
        if (
          codeType === DiscountCodeTypeEnum.UnboundPhone &&
          this.bindPhone === BindPhoneEnum.HasPhoneNumber &&
          activeDetail?.amount === 0
        ) {
          this.state.discountCode = `active_code_${this.activeId}_${
            userInfos!.username
          }`
          this.useDiscountCode()
        } else {
          // 单独判断是否是邀请链接
          if (codeType === DiscountCodeTypeEnum.InvitationLink) {
            return this.handleInvitationLinkReceive()
          }

          this.useDiscountCode() // 兑换码领取
        }
      }
    }

    /**
     * 置灰领取彩金按钮
     *  1.兑换码 是否有填写
     *  2.已绑定手机 补全手机号及短信验证码
     *  3.未绑定手机 是否有填写手机号和短信验证码 以及绑定是否绑定成功
     * codeType（1通用优惠码，2唯一优惠码，3已绑定手机，4未绑定手机）
     */
    const handleIsDisabled = () => {
      const { activeData } = this.eventData || {}
      const activeDetail = activeData as ApiEvent
      if (this.notInTimeRange || activeDetail?.amount) {
        return true
      } else {
        switch (codeType) {
          case DiscountCodeTypeEnum.Universal:
          case DiscountCodeTypeEnum.FirstCharge:
          case DiscountCodeTypeEnum.Unique:
            return !this.state.discountCode
          case DiscountCodeTypeEnum.BoundPhone:
          case DiscountCodeTypeEnum.UnboundPhone:
            if (
              this.bindPhone === BindPhoneEnum.Unbound ||
              this.bindPhone === BindPhoneEnum.Bound
            )
              return false
            if (
              this.bindPhone === BindPhoneEnum.HasPhoneNumber &&
              activeDetail?.amount === 0
            ) {
              return false
            }
            return !(
              this.state.bindPhoneForm &&
              this.state.bindPhoneForm.phone &&
              this.state.bindPhoneForm.captcha
            )
          case DiscountCodeTypeEnum.InvitationLink:
            if (activeDetail?.receiveStatus === ReceiveStatusEnum.HasReceive) {
              return true
            }
            return false
          default:
            return true
        }
      }
    }

    const content = (
      <div
        class={{
          [style.btn]: true,
          'discount-btn-box': true,
          [style.btnWrapper]: this.showDiscountCodeLinkBtn
        }}
      >
        {this.showDiscountCodeLinkBtn ? (
          <Button.Tsx
            class={style.linkBtn}
            type="primary"
            ghost
            nativeOnClick={this.getDiscountCodeLink}
          >
            <AutoShrinkText
              class={style.linkBtnText}
              text={t('lobby.event.discountCode.getRedeemCode') as string}
            />
          </Button.Tsx>
        ) : null}

        <Button.Tsx
          class={{
            [style.receiveBtn]: true,
            [style.linkBtn]: this.showDiscountCodeLinkBtn
          }}
          loading={this.state.loading}
          type="success"
          nativeOnClick={onOk}
          disabled={
            this.notInTimeRange || handleIsDisabled() || this.state.hasReceived
          }
        >
          <AutoShrinkText
            text={
              this.getBtnText(
                activeDetail.receiveStatus as ReceiveStatusEnum
              ) as string
            }
          />
        </Button.Tsx>
      </div>
    )
    if (!isWeb && !this.isModal && !this.isAfterRechargeModal) {
      return (
        // 此div高度用于占位
        <Portal to={GlobalConst.PortalName.ActivityBottom}>
          <div class={style.bottomBtns}>
            <Button.Tsx class={['H5BottomReturnBtn']} onClick={goBack}>
              <span>{t('lobby.common.tips.return')}</span>
            </Button.Tsx>
            {content}
          </div>
        </Portal>
      )
    }

    // 传出到充值后弹窗下方
    if (this.isAfterRechargeModal) {
      return (
        <Portal to={GlobalConst.PortalName.AfterRechargeActivityBottom}>
          {content}
        </Portal>
      )
    }

    return content
  }

  /**
   * 表格
   */
  private renderRewardsTable(list: Array<ContentConfigAmountItem>) {
    return (
      <CommonTable
        query={async () => {
          return {
            data:
              list?.map((row, idx) => ({
                ...row,
                rowKey: idx
              })) ?? []
          }
        }}
        class={[style.table, this.hiddenTips ? style.hiddenTips : '']}
        columns={this.columns}
        rowKey={'rowKey'}
        emptyHeight={'auto'}
        isHiddenPagination={true}
      />
    )
  }

  /** 是否隐藏提示 */
  private get hiddenTips() {
    const activeDetail = (this.eventData?.activeData || {}) as ApiEvent
    const { contentConfig } = activeDetail || {}
    return (
      activeDetail &&
      (contentConfig?.disableShowReward === DisableShowRewardEnum.HIDE ||
        (this.contentConfig &&
          this.contentConfig.codeType === DiscountCodeTypeEnum.FirstCharge &&
          ((activeDetail.amount && activeDetail.amount <= 0) ||
            !activeDetail.amount)))
    )
  }

  render() {
    const { t } = useI18n()
    const {
      amount,
      amountType,
      rate,
      codeType,
      profitLimit,
      withdrawLimit,
      showAmountRange
    } = this.contentConfig || {}
    const { activeData } = this.eventData || {}
    const activeDetail = activeData as ApiEvent
    const { isWeb } = useMainStore()

    const getTips = () => {
      switch (amountType) {
        case AmountTypeEnum.Fixed:
        case AmountTypeEnum.FirstDepositBonus:
          if (
            this.contentConfig &&
            this.contentConfig.codeType === DiscountCodeTypeEnum.FirstCharge &&
            amountType === AmountTypeEnum.Fixed
          ) {
            return (
              activeDetail?.amount &&
              brazilianCurrencyFormatter(activeDetail?.amount ?? 0)
            )
          }
          return amount ? brazilianCurrencyFormatter(amount) : amount
        case AmountTypeEnum.FirstChargeRatio:
          if (activeDetail && activeDetail?.amount) {
            return brazilianCurrencyFormatter(activeDetail?.amount)
          }
          return t('lobby.event.discountCode.tips3', {
            x: rate,
            y: amount ? brazilianCurrencyFormatter(amount) : amount
          })
        case AmountTypeEnum.Random:
          if (activeDetail && activeDetail?.amount) {
            return brazilianCurrencyFormatter(activeDetail?.amount)
          }
          // return showAmountRange?.replace?.(',', '-')
          return `${brazilianCurrencyFormatter(
            showAmountRange?.split(',')[0] ?? 0
          )}-${brazilianCurrencyFormatter(showAmountRange?.split(',')[1] ?? 0)}`
      }
    }

    const renderTipsLable = (disableShowReward: number, amount: number) => {
      const { t } = useI18n()
      if (
        disableShowReward === DisableShowRewardEnum.HIDE ||
        (this.contentConfig &&
          this.contentConfig.codeType === DiscountCodeTypeEnum.FirstCharge &&
          amount <= 0)
      ) {
        return
      }
      const label =
        amount > 0
          ? `${t('lobby.event.discountCode.alreadyWon')}:`
          : t('lobby.event.discountCode.tipsLable')

      return (
        <div>
          {label}
          <span>{getTips()}</span>
        </div>
      )
    }

    /**
     * 温馨提示语 显示逻辑
     * profitLimit 盈利上限
     * withdrawLimit  提现门槛
     * @returns string
     */
    const renderKindTipsText = () => {
      if (!profitLimit && !withdrawLimit) return ''

      if (profitLimit !== 0 && withdrawLimit === 0) {
        return t('lobby.event.discountCode.tipsProfitUpperLimit', {
          x:
            profitLimit !== 0
              ? `<span> ${
                  brazilianCurrencyFormatter(profitLimit ?? 0)?.replace(
                    /(.|,)00$/,
                    ''
                  ) ||
                  brazilianCurrencyFormatter(
                    this.contentConfig?.amount ?? 0
                  )?.replace(/(.|,)00$/, '')
                } </span>`
              : `<span>
          ${t('lobby.event.discountCode.notProfitLimit')}
        </span>`
        }).toString()
      } else if (profitLimit === 0 && withdrawLimit !== 0) {
        return t('lobby.event.discountCode.tipstipsNeedRechargeAfterProfit', {
          y: `<span> ${brazilianCurrencyFormatter(withdrawLimit ?? 0)?.replace(
            /(.|,)00$/,
            ''
          )} </span>`
        }).toString()
      } else {
        return `${t('lobby.event.discountCode.tipsProfitUpperLimit', {
          x:
            profitLimit !== 0
              ? `<span> ${brazilianCurrencyFormatter(
                  profitLimit || amount || 0
                )?.replace(/(.|,)00$/, '')} </span>`
              : `<span> ${t('lobby.event.discountCode.notProfitLimit')} </span>`
        })}, ${t('lobby.event.discountCode.tipstipsNeedRechargeAfterProfit', {
          y: `<span> ${brazilianCurrencyFormatter(withdrawLimit ?? 0)?.replace(
            /(.|,)00$/,
            ''
          )} </span>`
        })}`
      }
    }

    const renderTitle = () => {
      const { isWeb } = useMainStore()
      const { eventData } = this
      const size = {
        height: isWeb ? 60 : 80,
        top1Width: isWeb ? 62 : 84,
        top3Width: isWeb ? 61 : 74,
        fontSize: isWeb ? 24 : 30
      }

      return (
        <div class={style.title}>
          <my-img
            src={'/lobby_asset/common/common/event/xrcj_top1.png'}
            options={{ type: 'div' }}
            height={size.height}
            width={size.top1Width}
          ></my-img>
          <my-img
            class={style.top2}
            src={'/lobby_asset/common/common/event/xrcj_top2.png'}
            options={{ type: 'div' }}
            height={size.height}
          >
            <AutoShrinkText
              fontSize={size.fontSize}
              class={style.text}
              text={eventData?.name ?? ''}
            ></AutoShrinkText>
          </my-img>
          <my-img
            class={style.top3}
            src={'/lobby_asset/common/common/event/xrcj_top3.png'}
            options={{ type: 'div' }}
            height={size.height}
            width={size.top3Width}
          ></my-img>
        </div>
      )
    }

    const kindTipsText: string = renderKindTipsText()
    const { contentConfig } = activeDetail || {}
    return this.state.isTimeoutError ? (
      <NetRetry
        loading={this.state.refreshing}
        isTimeoutError={this.state.isTimeoutError}
        displayType={DisplayType.ListData}
        height={BusinessUtils.px2rem('1040px')}
        onRetry={this.refresh}
      />
    ) : (
      <CommonLoading spinning={!this.state.eventData}>
        <div class={[style.discountCodeCotent, 'discountCodeCotent']}>
          {/* 验证已绑定手机号-不满足条件 */}
          <div class={[style.topContent, 'topContent', 'boxShadow']}>
            {!this.isAfterRechargeModal && renderTitle()}
            <div>
              {codeType &&
                [
                  DiscountCodeTypeEnum.Universal,
                  DiscountCodeTypeEnum.FirstCharge,
                  DiscountCodeTypeEnum.Unique
                ].includes(codeType) &&
                !activeDetail?.amount &&
                this.renderDiscountCodeComponent()}

              {/* 已绑定手机 展示绑定手机组件 */}
              {codeType === DiscountCodeTypeEnum.BoundPhone &&
                (this.bindPhone === BindPhoneEnum.Bound ||
                  this.bindPhone === BindPhoneEnum.Unbound) &&
                !activeDetail?.amount &&
                this.renderVeriryBindPhoneComponent()}
              {/* 未绑定手机 展示绑定手机组件*/}
              {codeType === DiscountCodeTypeEnum.UnboundPhone &&
                (this.bindPhone === BindPhoneEnum.Bound ||
                  this.bindPhone === BindPhoneEnum.Unbound ||
                  this.bindPhone === BindPhoneEnum.HasPhoneNumber) &&
                !activeDetail?.amount &&
                this.renderVeriryBindPhoneComponent()}

              {/* 领取成功 展示组件 */}
              {activeDetail?.amount
                ? this.renderReceivedSuccessComponent()
                : null}
            </div>
            {this.contentConfig &&
              this.contentConfig.codeType ===
                DiscountCodeTypeEnum.FirstCharge &&
              [AmountTypeEnum.Fixed, AmountTypeEnum.FirstChargeRatio].includes(
                this.contentConfig.amountType
              ) &&
              contentConfig.disableShowReward === DisableShowRewardEnum.SHOW &&
              this.contentConfig?.amountList &&
              this.renderRewardsTable(this.contentConfig?.amountList)}
            {this.eventData && !this.hiddenTips && (
              <div class={style.discountCodeTips}>
                {renderTipsLable(
                  contentConfig?.disableShowReward,
                  activeDetail?.amount ?? 0
                )}

                {/* 温馨提示 */}
                {kindTipsText && (
                  <inner-html
                    type="p"
                    text={`${t('lobby.modal.pay.mark')}: ${kindTipsText}`}
                  />
                )}
              </div>
            )}
            {this.eventData && isWeb && this.renderDiscountBtn()}
          </div>
          <div
            class={[
              style.discountContent,
              'discount-introduction-box',
              'boxShadow'
            ]}
            v-show={this.isModal}
          >
            {this.eventData && (
              <PublicIntroduction
                eventData={this.eventData}
                isShowBottom={false}
              />
            )}
          </div>
          {this.eventData && !isWeb && (
            <div class={[style.bottomBlank, 'bottom-blank']} />
          )}
          {this.eventData && !isWeb && this.renderDiscountBtn()}
        </div>
      </CommonLoading>
    )
  }
}
