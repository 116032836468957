import { CollectLeague, SearchHistory, SportsState } from './type'
import {
  CompetitionLevels,
  CompetitionLevelsValueof,
  HandicapAreas,
  SelfSportId,
  SportType,
  SportTypeValueof,
  sportList
} from '../const'
import {
  IParlayBetInfo,
  League,
  Market
} from '@/api/selfoperated-games/sports/type'
import { createMyPersistedStorage } from '@/utils/business-utils'
import { defineStore } from 'pinia'
import { getBetTypeList } from '../utils/markets'
import Vue from 'vue'

export const useSportsStore = defineStore('sports', {
  state: (): SportsState => ({
    showLive: false,
    streamingUrl: '',
    streamingUrlH5: '',
    showGame: true,
    sabaUrl: '',
    gameCategoryId: SelfSportId,
    gameListSearched: false,
    searchInputValue: '',
    handicapAreas: HandicapAreas.Europe,
    parlayBetCount: 0,
    parlayBetList: [],
    currentSports: undefined,
    marketCategory: 'rolling',
    /**收藏的联赛ID的list */
    collectLeagueList: [],
    searchHistory: [],
    sports: {
      rolling: [],
      today: [],
      morning: [],
      passLeve: [],
      champion: [],
      focusOn: []
    },
    sportList,
    leaguesList: {
      rolling: {},
      today: {},
      morning: {},
      passLeve: {},
      champion: {},
      focusOn: {}
    },
    sportKey: SportType.Soccer,
    competitionLevelKey: CompetitionLevels.Time,
    leagueIdToglobalShowTime: {},
    ipAccess: true
  }),
  getters: {
    /** 筛选了盘口数据和球类数据的联赛列表*/
    filterLeaguesList(): League[] {
      const { sportKey, marketCategory, leaguesList } = this
      return leaguesList[marketCategory][sportKey]
    },
    handicapAreasKey(): SportsState['handicapAreas'] {
      return this.handicapAreas
    },
    getParlayBetCount(): number {
      return this.parlayBetCount
    },
    getBetTypes(): IParlayBetInfo[] {
      return this.parlayBetList
    },
    getCurrentSports(): SportsState['currentSports'] {
      return this.currentSports
    },
    getCurrentMarketList(): Market[] {
      const { currentSports } = this
      const markets = currentSports?.markets ?? []
      const betTypeList = getBetTypeList()
      const filteredMarkets = markets.filter(({ betType }) =>
        betTypeList.includes(betType)
      )
      return filteredMarkets
    },
    getMarketCategory(): SportsState['marketCategory'] {
      return this.marketCategory
    },
    getSports(): SportsState['sports'] {
      return this.sports
    }
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: 'web.selfoperatedGames.sports.handicapAreas',
        storage: createMyPersistedStorage(),
        paths: ['handicapAreas']
      },
      {
        key: 'web.selfoperatedGames.sports.currentSports',
        storage: createMyPersistedStorage(),
        paths: ['currentSports']
      },
      {
        key: 'web.selfoperatedGames.sports.collectLeagueList',
        storage: createMyPersistedStorage(),
        paths: ['collectLeagueList']
      },
      {
        key: 'web.selfoperatedGames.sports.parlayBetList',
        storage: createMyPersistedStorage(),
        paths: ['parlayBetList']
      },
      {
        key: 'web.selfoperatedGames.sports.searchHistory',
        storage: createMyPersistedStorage(),
        paths: ['searchHistory']
      }
    ]
  },
  actions: {
    setShowLive(showLive: boolean) {
      this.showLive = showLive
    },
    setStreamingUrl(streamingUrl: string) {
      this.streamingUrl = streamingUrl
    },
    setStreamingUrlH5(streamingUrlH5: string) {
      this.streamingUrlH5 = streamingUrlH5
    },
    setShowGame(showGame: boolean) {
      this.showGame = showGame
    },
    setSabaUrl(sabaUrl: string) {
      this.sabaUrl = sabaUrl
    },

    setGameCategoryId(gameCategoryId: number) {
      this.gameCategoryId = gameCategoryId
    },
    setSearchInputValue(searchInputValue: string) {
      this.searchInputValue = searchInputValue
    },
    setGameListSearched(gameListSearched: boolean) {
      this.gameListSearched = gameListSearched
    },
    /**设置联赛对应赛事得开赛时间 */
    setLeagueIdToglobalShowTime(value: Record<number, number>) {
      const { leagueIdToglobalShowTime } = this
      this.leagueIdToglobalShowTime = { ...leagueIdToglobalShowTime, ...value }
    },
    /** 更新当前球类 */
    setSportKey(sportKey: SportTypeValueof) {
      this.sportKey = sportKey
    },
    /**
     * 更新联赛列表
     * @param leagues 联赛列表
     */
    setLeaguesList(leagues: Array<League>) {
      const { sportKey, marketCategory } = this
      Vue.set(this.leaguesList[marketCategory], sportKey, leagues)
    },
    /**
     * 设置被收藏的赛事
     * @param leagueId 联赛id
     */
    setCollectLeagueList(CollectLeague: CollectLeague) {
      const { eventId } = CollectLeague
      const collectEventIdList = this.collectLeagueList.map(
        (item) => item.eventId
      )
      if (collectEventIdList.includes(eventId)) {
        this.collectLeagueList = this.collectLeagueList.filter(
          (item) => item.eventId !== eventId
        )
      } else {
        this.collectLeagueList = [CollectLeague]
          .concat(this.collectLeagueList)
          .filter((league) => league.eventId)
      }
    },
    /**
     * 更新被收藏的赛事
     */
    setCollectedEvents(collectEvents: CollectLeague[]) {
      this.collectLeagueList = collectEvents
    },
    setSearchHistory(searchHistoryData: SearchHistory[]) {
      this.searchHistory = searchHistoryData
    },
    /** 切换排序的规则是联赛或者时间 */
    changeCompetitionLevelKey(key: CompetitionLevelsValueof) {
      this.competitionLevelKey = key
    },
    /** 更改地区盘口赔率类型 */
    changeHandicapAreasKey(key: SportsState['handicapAreas']) {
      this.handicapAreas = key
    },
    changeParlayBetCount(key: SportsState['parlayBetCount']) {
      this.parlayBetCount = key
    },
    appendBetType(key: IParlayBetInfo) {
      this.changeParlayBetCount(this.parlayBetList.push(key))
    },
    setParlayBetList(parlayBetList: IParlayBetInfo[]) {
      this.parlayBetList = parlayBetList
      this.changeParlayBetCount(this.parlayBetList.length)
    },
    removeBetType(key: IParlayBetInfo) {
      this.parlayBetList = this.parlayBetList.filter(
        (e) => e.market.marketId !== key.market.marketId
      )
      this.changeParlayBetCount(this.parlayBetList.length)
    },
    clearBetType() {
      this.parlayBetList = []
      this.changeParlayBetCount(0)
    },
    changeCurrentSports(data: SportsState['currentSports']) {
      this.currentSports = data
    },
    setMarketCategory(payload: SportsState['marketCategory']) {
      this.marketCategory = payload
    },
    setSports(payload: SportsState['sports']) {
      this.sports = payload
    }
  }
})
