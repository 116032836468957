import { Base, Component } from '@/vue-property-decorator'
import { GameType } from '@/api/game/type'
import { GlobalManager } from '@/context'
import { apiGetGameUrl } from '@/api/game'
import { useMainStore } from '@/store/index'
import CryptoJS from 'crypto-js'
import Site from '@/controller/Site'
// import Splash from '@/splash'
import { GameItemInter } from '@/service/game/entity/gameItem'
import { RouteName } from '@/router/config'
import { urlFormatter } from '@/utils/Tool'
import { useGameStore } from '../game/store'
import router from '@/router'
import to from 'await-to-js'

@Component<PromoteGame>({ name: 'PromoteGame' })
export default class PromoteGame extends Base {
  // private splash!: Splash

  // public doDestroy() {
  //   this.splash.destroy()
  // }
  render() {
    return <div></div>
  }
  async beforeCreate() {
    // this.splash = new Splash()
    try {
      await Site.initSystemInfos(true)
      GlobalManager.User.mockUnLoginedUserInfo()
      const { hasLogined, apiBaseUrl, userInfos } = useMainStore()
      const route = this.$route
      const { SelfOperatedGameId, cid, languageCode, currency } = route.query
      // 试玩游戏推广链接直接进入试玩游戏
      if (!hasLogined && SelfOperatedGameId) {
        const [_err, data] = await to(
          apiGetGameUrl(
            {
              gameid: Number(SelfOperatedGameId),
              platfromid: 13,
              user_type: GameType.DEMO,
              promoteInfo: JSON.stringify({
                ...route.query,
                regUrl: encodeURIComponent(
                  `${location.origin}?type=4&cid=${cid}&languageCode=${languageCode}&currency=${currency}`
                )
              })
            },
            { currency: String(currency) }
          )
        )
        const decrypt = (value: string) => {
          return CryptoJS.AES.encrypt(
            value,
            CryptoJS.enc.Utf8.parse('thanks,pig4cloud'),
            {
              mode: CryptoJS.mode.ECB,
              padding: CryptoJS.pad.Pkcs7
            }
          ).toString()
        }
        if (_err) throw _err
        const sessionkey = decrypt(userInfos?.session_key || '')
        const userkey = decrypt(userInfos?.userkey || '')
        const gameDomain = apiBaseUrl || location.origin
        const WSurl = gameDomain?.replace('https://', 'wss://')
        const gameUrl = urlFormatter(data?.game_url || '', {
          gameId: data?.gameid,
          route: `${WSurl}/gogamesac/`,
          lang: languageCode,
          sessionkey,
          userkey,
          try: userInfos?.username
        })

        const { setGameParameters, setGamePlaying } = useGameStore()

        const gameInfo = {
          gameUrl,
          gameName: data?.gameName
        } as unknown as GameItemInter

        setGamePlaying(true)
        setGameParameters({
          url: gameUrl,
          gameInfo,
          name: data?.gameName || '',
          platformId: data?.platfromid,
          screenDirection: Boolean(data?.direction)
        })

        router.push({
          name: RouteName.GAME_EMBEDDED,
          query: {
            platformId: String(data?.platfromid),
            gameCategoryId: String(data?.categoryId)
          }
        })
      } else {
        location.href = '/'
      }
    } catch (error) {
      if (error) console.error(error)
      location.href = '/'
    }
  }
  beforeDestroy() {
    // this.doDestroy()
  }
}
