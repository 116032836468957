/* eslint-disable @typescript-eslint/no-explicit-any*/
import Vue from 'vue'
type MyKey = string | number
/** map接口类型 */
type MyMapInter<K extends MyKey, T> = Pick<
  Map<K, T>,
  'get' | 'delete' | 'has' | 'size'
> & {
  /** 设置值 */
  set(key: K, value: T): void
  /** 遍历mapper(同数组的forEach) */
  forEach(callback: (value: T, key: K, index: number) => void): void
  /** 取得所有键 */
  keys(): K[]
  /** 遍历mapper(同数组的map) */
  map<NewT = any>(
    callback: (value: T, key: K, index: number) => NewT
  ): Array<NewT>
}
/** 我的map映射(响应式) */
export class MyMap<K extends MyKey, T> implements MyMapInter<K, T> {
  /** 键值对印射 */
  mapper = {} as Record<K, T>
  /** 排序列表,用来控制map的排序 */
  orderList: K[] = []
  /** map中内容长度 */
  public get size() {
    return this.orderList.length
  }
  /** 判断该元素是否存在与mapper中 */
  has(key: K): boolean {
    return Object.prototype.hasOwnProperty.call(this.mapper, key)
  }
  /** 返回键对应的元素 */
  get(key: K): T | undefined {
    return this.mapper[key]
  }
  /** 取得所有key值 */
  keys(): K[] {
    return [...this.orderList]
  }
  /** 设置元素 */
  set(key: K, value: T) {
    // 设置以前,需要先查看一下,该键值是否已存在
    if (!this.has(key)) {
      // 如果不存在的话,将key添加到排序中
      this.orderList.push(key)
    }
    Vue.set(this.mapper, key, value)
  }
  /** 删除某个元素 */
  delete(key: K): boolean {
    if (!this.has(key)) {
      return false
    }
    const index = this.orderList.indexOf(key)
    this.orderList.splice(index, 1)
    Vue.delete(this.mapper, key)
    return true
  }
  /** 遍历map(同数组forEach) */
  forEach(callbackfn: (value: T, key: K, index: number) => void): void {
    this.orderList.forEach((key, index) => {
      const val = this.mapper[key]
      callbackfn(val, key, index)
    })
  }
  /** 遍历map(同数组map) */
  map<NewT = any>(
    callbackfn: (value: T, key: K, index: number) => NewT
  ): Array<NewT> {
    return this.orderList.map((key, index) => {
      const val = this.mapper[key]
      return callbackfn(val, key, index)
    })
  }
}
