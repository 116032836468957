import { AxiosError } from 'axios'
import { createMyPersistedStorage } from '@/utils/business-utils'
import { defineStore } from 'pinia'
export const xInitValue = Number.MAX_VALUE
export const yInitValue = Number.MAX_VALUE

export interface ServiceState {
  expandQuestionList: number[]
  /**三方客服的标识List */
  thirdServiceMarkList: string[]
  /**是否已经初始化三方客服 */
  hasInitThirdService: boolean
  /**三方客服按钮的x和y坐标值 */
  thirdServiceButtonCoordinate: Record<'x' | 'y', number>

  /**访问客服接口报错时候的错误对象 */
  serviceAxiosError: AxiosError | null
  /**客服接口的载入状态 */
  loading: boolean
}

export const useServiceStore = defineStore('service', {
  state: (): ServiceState => ({
    thirdServiceButtonCoordinate: {
      x: xInitValue,
      y: yInitValue
    },
    expandQuestionList: [],
    thirdServiceMarkList: [
      '[id="ukefu-point"]',
      /**livechat三方客服 */
      '[id="chat-widget-container"]',
      /**美恰三方客服 */
      '[id="MKEFU-INVITE-BTN"]'
    ],
    hasInitThirdService: false,
    serviceAxiosError: null,
    loading: false
  }),

  actions: {
    setExpandQuestionList(list: number[]) {
      this.expandQuestionList = list
    },
    setThirdServiceMarkList(thirdServiceMarkList: string[]) {
      this.thirdServiceMarkList = thirdServiceMarkList
    },
    setHasInitThirdService(hasInitThirdService: boolean) {
      this.hasInitThirdService = hasInitThirdService
    },
    setThirdServiceButtonCoordinate(
      thirdServiceButtonCoordinate: Record<'x' | 'y', number>
    ) {
      this.thirdServiceButtonCoordinate = thirdServiceButtonCoordinate
    },
    setServiceAxiosError(serviceAxiosError: AxiosError | null) {
      this.serviceAxiosError = serviceAxiosError
    },
    setLoading(loading: boolean) {
      this.loading = loading
    }
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: 'web.lobby.service',
        storage: createMyPersistedStorage(),
        paths: ['thirdServiceButtonCoordinate']
      }
    ]
  }
})
