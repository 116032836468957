import { TaskPageStore } from '.'
import { freshCategoryUsable } from './common/freshCategoryUsable'
import { useMainStore } from '@/store/index'

/** 刷新tabs任务[类型的可用状态]和[规则列表] */
export const proxyFreshCategoriesUsableAndRules = async (
  store: TaskPageStore,
  isForceFreshUsable?: boolean
) => {
  store.setIsLoading(true)
  // 1.如果未刷新过是否可用,则需要刷新一下(此处的调用相比单个分类的调用区别,此处可以设置强制刷新,单个调用中没有强制刷新)
  await freshCategoryUsable(store, isForceFreshUsable)
  // 2.刷新任务列表
  await freshRules(store)
  // 3.刷新当前保存的任务列表的状态[用户/语言/币种]
  freshFetchedDataStatus(store)
  // 4.加载完成后store需要标记已加载过数据
  store.isFetched = true
  store.setIsLoading(false)
}

/** 刷新tabs中的任务列表 */
const freshRules = async (store: TaskPageStore) => {
  const apiCodes = store.codeCategoryOrder
  const freshAll = apiCodes.map((code) => {
    return store.freshInTabsCategoryDataByCode(code)
  })
  await Promise.all(freshAll)
}

/** 刷新 【当前加载的数据所属[用户/语言/币种]】 */
const freshFetchedDataStatus = (store: TaskPageStore) => {
  const { language, userInfos, currentTryCurrency } = useMainStore()
  const { username, currency } = userInfos || {}
  // 当前加载的数据所属[用户/语言/币种]
  store.fetchedDataUserId = String(username)
  store.fetchedDataLanguage = language
  store.fetchedDataCurrency = String(currency || currentTryCurrency)
}
