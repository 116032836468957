import { RouteName } from '@/router/config'
import { SmartPayScene, StoreState } from './types'
import { UserInfos } from '@/api/common/type'
import {
  apiFinanceGetSmartPayContent,
  apiFinanceGetSmartPaySettingAndSlogans
} from '@/api/finance'
import { createMyPersistedStorage } from '@/utils/business-utils'
import { defineStore } from 'pinia'
import { shuffle } from 'lodash'
import { useMainStore } from '@/store/index'
import Logger from '@/utils/Logger'
import LongConnection, {
  LongConnectionEvent
} from '@/controller/LongConnection'
import Modal from '@/controller/Modal'
import moment from 'moment'
import router from '@/router'
import useFinancePayStore from '../pay/store'
export const useSmartPayStore = defineStore('finance/smartPay', {
  state: (): StoreState => {
    return {
      username: undefined,
      loading: false,
      depositeCount: 0,
      timer: undefined,
      triggerScenes: [],
      lastModalScene: undefined,
      lastTimeShowScene2: undefined,
      titleConfig: undefined,
      modalContent: undefined,
      config: undefined
    }
  },

  getters: {
    hasShowScene2: (state: StoreState) => {
      const userInfos = useMainStore().userInfos

      // 同一个user
      if (state.lastTimeShowScene2?.userId !== userInfos?.username) {
        return false
      }

      // 同一天
      return moment().isSame(state.lastTimeShowScene2?.timestamp, 'day')
    },

    /**
     * 检测是否首充
     */
    hasFirstCharge: (state: StoreState) => {
      const { userInfos } = useMainStore()
      return state.depositeCount > 0 || (userInfos?.deposit_count ?? 0) > 0
    }
  },

  actions: {
    init(userInfos: UserInfos | null) {
      try {
        const { username, account_type } = userInfos ?? {}
        // 主播 试玩 银商 不需要弹窗
        if (account_type && [1, 4, 5].includes(account_type)) {
          return
        }

        if (!username) {
          clearTimeout(this.timer)
          this.username = undefined
          return
        }

        // 已经初始化过了
        if (this.username === username) {
          return
        }

        LongConnection.ws.on(LongConnectionEvent.PAY_ORDER_SUCCESS, () => {
          this.depositeCount++
        })

        this.username = username
        this.getConfig()
      } catch (error) {
        Logger.error('An error occurred in useSmartPayStore init:', error)
      }
    },

    /**
     * 获取场景提示语 在打乱的数组里面循环获取
     */
    getTitle(scene: SmartPayScene) {
      const titleConfig = this.titleConfig?.[scene]
      if (!titleConfig) {
        return ''
      }

      const index =
        titleConfig.index + 1 === titleConfig.list.length
          ? 0
          : ++titleConfig.index

      return titleConfig.list[index]
    },

    /**
     * 倒计时
     */
    countDown() {
      const interval = this.config?.publicSetting.timeInterval
      if (interval) {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.check()
        }, interval * 1000 * 60)
      } else {
        this.getConfig()
      }
    },

    /**
     * 自动检测是否弹窗
     * 检测不符合的话，继续倒计时
     */
    async check() {
      const userInfos = useMainStore().userInfos!

      // 没有配置项
      if (!this.config || !this.titleConfig) {
        this.getConfig()
        return
      }

      // 在游戏页面
      if (router.currentRoute.name === RouteName.GAME_EMBEDDED) {
        this.countDown()
        return
      }

      // 智能弹窗打开中，充值弹窗打开中
      if (Modal.hasOpeningModal()) {
        this.countDown()
        return
      }

      try {
        if (this.hasFirstCharge) {
          // 场景1
          const targetMoney = Number(
            this.config.scene1Setting?.[userInfos.currency] ?? 0
          )
          if (targetMoney !== 0 && (userInfos.game_gold ?? 0) <= targetMoney) {
            this.triggerScenes = [SmartPayScene.LowBalance]
          }

          // 场景2
          if (!this.hasShowScene2) {
            this.triggerScenes.push(SmartPayScene.LoginAndHasRecharge)
          }
        } else {
          // 场景3
          this.triggerScenes.push(SmartPayScene.NoRecharge)
        }

        if (!this.triggerScenes.length) {
          this.countDown()
          return
        }

        this.showModal()
      } catch (err) {
        // 请求失败，如果已经有触发的场景，就直接显示，否则进入下一个倒计时
        if (this.triggerScenes.length) {
          this.showModal()
        } else {
          this.countDown()
        }
      }
    },

    async showModal() {
      const userInfos = useMainStore().userInfos!

      let scene = this.triggerScenes.shift() as SmartPayScene
      if (this.triggerScenes.length && scene === this.lastModalScene) {
        scene = this.triggerScenes.pop()!
      }

      // 该场景没有宣传语 不展示弹窗
      if (!this.titleConfig?.[scene as SmartPayScene]) {
        return
      }

      if (scene === SmartPayScene.LoginAndHasRecharge) {
        this.lastTimeShowScene2 = {
          timestamp: Date.now(),
          userId: userInfos.username
        }
      }

      const res = await apiFinanceGetSmartPayContent({
        scene,
        currency: userInfos.currency
      }).catch(() => {
        /**
         */
      })

      this.modalContent = res?.data.data

      if (
        this.modalContent &&
        router.currentRoute.name !== RouteName.GAME_EMBEDDED &&
        this.modalContent?.recommendedAmount !== '0'
      ) {
        Modal.open('smartPayModal', {
          scene
        })

        // 记录上一次弹窗场景
        this.lastModalScene = scene
      } else {
        this.countDown()
      }
    },

    /**
     * 获取初始配置
     */
    async getConfig() {
      try {
        const userInfos = useMainStore().userInfos!

        const globalConfig = await apiFinanceGetSmartPaySettingAndSlogans({
          currency: userInfos.currency
        })
        const config = globalConfig.data.data
        this.titleConfig = config?.slogans?.reduce((obj, item) => {
          return {
            ...obj,
            [item.scene]: {
              index: 0,
              list: shuffle(item.slogan)
            }
          }
        }, {}) as StoreState['titleConfig']

        if (!config || !this.titleConfig) {
          throw new Error('load smart-pay config fail.')
        }

        const scene1SettingObject = config.sceneSettings.find((c) => {
          return c.scene === SmartPayScene.LowBalance
        })

        const scene1MinBalanceMap = scene1SettingObject?.minBalance?.reduce(
          (obj, pre) => {
            return {
              ...obj,
              [pre.currency]: pre.amount
            }
          },
          {}
        )

        this.config = {
          publicSetting: config.publicSetting,
          scene1Setting: scene1MinBalanceMap
        }

        this.countDown()
      } catch (err) {
        Logger.error('智能弹窗接口请求失败')
      }
    },

    edit() {
      const payStore = useFinancePayStore()
      payStore.rootCategoryIndex = this.modalContent!.payKind // 选中对应tab
      payStore.activePlatformId = this.modalContent!.payTypeId // 选中大类
      payStore.activeChannelId = this.modalContent!.channelId // 选中通道
      payStore.money = this.modalContent!.recommendedAmount // 金额

      Modal.close('smartPayModal')
      Modal.open('payModal')
    }
  },

  persist: {
    enabled: true,
    strategies: [
      {
        key: 'web.lobby.smart-pay',
        storage: createMyPersistedStorage(),
        paths: ['lastTimeShowScene2']
      }
    ]
  }
})
