import {
  AgentModeGetterType,
  BooleanType,
  CustomerRenderParams,
  RenderAmountParams,
  RenderContentParams,
  RenderTitleParams,
  TableType
} from './type'
import { Base, Component } from '@/vue-property-decorator'
import { Button } from '@/plugins/ant-design-vue/tsx-support'
import { DateConditionEnum } from '@/components/business-components/date-radio'
import { GlobalConst } from '@/context'
import { RouteName } from '@/router/config'
import { TabsTypeEnum } from '../tabList/type'
import { currencyFormat } from '@/utils/business-utils/finance'
import { useI18n } from '@/i18n'
import { useMainStore } from '@/store/index'
import BusinessUtils from '@/utils/business-utils'
import NetRetry from '@/components/business-components/net-retry'
import moment from 'moment'
import style from './style.module.scss'
import usePromoteStore from '../../store'

export { TableType, BooleanType }

@Component<BaseMixins>({
  name: 'BaseMixins'
})
export default class BaseMixins extends Base {
  /**
   * 账号规则4-16
   * 密码规则6-16
   * 最大长度均为16
   */
  protected inputMaxLength = 16

  /**
   * 判断当前代理模式
   */
  protected get getAgentMode(): AgentModeGetterType {
    const { AgentMode } = GlobalConst
    const agent_id = usePromoteStore().getAgentModeData.agent_id
    return {
      /**
       * 无限极差
       */
      isInfinite: agent_id === AgentMode.Infinite,
      /**
       * 一级代理
       */
      isFirstLevel: agent_id === AgentMode.FirstLevel,
      /**
       * 净盈利
       */
      isNetProfit: agent_id === AgentMode.NetProfit,
      /**
       * 三级净盈利
       */
      isThreeNetProfix: agent_id === AgentMode.ThreeNetProfix,
      /**
       * 全民代理
       */
      isNational: agent_id === AgentMode.National
    }
  }
  /**
   * 预设起始结束时间
   */
  public get defaultSeachTime() {
    const { siteInfos } = useMainStore()
    return {
      startTime: moment
        .utc()
        .utcOffset(siteInfos?.timeZone ?? '')
        .startOf('day'),
      endTime: moment
        .utc()
        .utcOffset(siteInfos?.timeZone ?? '')
        .endOf('day')
    }
  }
  /**
   * 金额
   */
  protected renderAmount(options: RenderAmountParams) {
    return (
      <span
        class={[
          'currencyAmount',
          {
            yellowColor: options.isYellow ?? false,
            greenColor: options.isGreen ?? false,
            redColor: options.isRed ?? false
          }
        ]}
      >
        {options.sign && Number(options.amount) > 0 ? '+' : ''}
        {typeof options.amount == 'number'
          ? currencyFormat(options.amount ?? 0, {
              symbol: (options?.symbol ?? '') as string,
              precision: options?.precision ?? 2,
              withZero: options?.withZero ?? true
            })
          : options.amount ?? '-'}
        {options.suffix && <span class={'suffix'}>{options.suffix}</span>}
      </span>
    )
  }

  /**
   * 内容区
   */
  protected renderContentItem(
    options: RenderContentParams,
    isValueHighlight?: boolean
  ) {
    return (
      <div
        class={{
          [style.commonContent]: true,
          [style.valueHighlight]: !!isValueHighlight,
          ['valueHighlight']: !!isValueHighlight
        }}
      >
        <inner-html text={options.title} />
        <NetRetry
          loading={options.retryLoading ?? false}
          axiosError={options.axiosError}
          onRetry={options.onRetry}
        >
          {this.renderCustomerTableColumn(options)}
        </NetRetry>
      </div>
    )
  }

  /**
   * 标题
   */
  protected renderTitle(options: RenderTitleParams) {
    const { t } = useI18n()
    const hasMore = options.linkTo !== TabsTypeEnum.None
    return (
      <div class={style.commonTitle}>
        <span>{options.title}</span>
        {hasMore && (
          <Button.Tsx
            type="link"
            onClick={() => this.handleHerf(options.linkTo)}
          >
            {t('lobby.promote.promote.more')}
          </Button.Tsx>
        )}
        {options.slot && options.slot()}
      </div>
    )
  }

  /**
   * 页面跳转
   */
  protected handleHerf(current: TabsTypeEnum) {
    this.$router.replace({
      name: RouteName.PROMOTE,
      query: { current }
    })
  }

  /**
   * table行渲染
   */
  protected renderCustomerTableColumn(options: CustomerRenderParams) {
    const { text, type = TableType.Normal, config = {} } = options
    const { t } = useI18n()
    const emptyString = ''

    switch (type) {
      /**
       * 数量
       */
      case TableType.Amount: {
        return this.renderAmount({
          amount: text,
          ...(config ?? {})
        })
      }

      /**
       * 金额
       */
      case TableType.Money: {
        return this.renderAmount({
          amount: text,
          isYellow: Number(text) >= 0,
          isGreen: Number(text) < 0,
          isRed: config.sign && Number(text) >= 0,
          precision: config.precision,
          ...(config ?? {})
        })
      }

      /**
       * 人数
       */
      case TableType.People: {
        return this.renderAmount({
          amount: text,
          suffix: t('lobby.promote.unitPeople'),
          precision: 0
        })
      }

      /**
       * 日期
       */

      case TableType.Date: {
        return (
          <span class={'dateTime'}>
            {text
              ? BusinessUtils.formatSiteOffsetTime(text as number, 'ymd')
              : '-'}
          </span>
        )
      }

      case TableType.Time: {
        return (
          <span class={'dateTime'}>
            {text ? BusinessUtils.formatSiteOffsetTime(text as number) : '-'}
          </span>
        )
      }

      /**
       * 自定义金额
       */
      case TableType.CustomAmount: {
        return this.renderAmount({
          amount: text,
          ...config
        })
      }

      /**
       * 自定义前后缀
       */
      case TableType.CustomNormal: {
        return (
          <div
            class={[
              'customNormal',
              {
                yellowColor: config.isYellow ?? false,
                greenColor: config.isGreen ?? false,
                redColor: config.isRed ?? false
              }
            ]}
          >
            {config.symbol && <span>{config.symbol}</span>}
            <span> {text ?? emptyString}</span>
            {config.suffix && (
              <span class={['suffixText']}>{config.suffix}</span>
            )}
          </div>
        )
      }
      /** 单位转换 */
      case TableType.UnitFormat: {
        const { language } = useMainStore()
        const isZh = ['zh_CN', 'zh_TW'].includes(language)
        const zhUnit = t('lobby.promote.proportion.rebateAmountUnitSuffix')
        const number = isZh
          ? Number(text)
          : parseFloat((Number(text) * (config?.unitSize ?? 1)).toFixed(2))
        let unit = ''
        let fomatNumber = 0
        if (isZh) {
          unit = zhUnit as string
          fomatNumber = number
        } else {
          if (number >= 1000000000) {
            unit = 'B'
            fomatNumber = parseFloat((number / 1000000000).toFixed(2))
          } else if (number >= 1000000) {
            unit = 'M'
            fomatNumber = parseFloat((number / 1000000).toFixed(2))
          } else if (number >= 1000) {
            unit = 'K'
            fomatNumber = parseFloat((number / 1000).toFixed(2))
          } else {
            fomatNumber = number
          }
        }
        const formattedNumber = currencyFormat(fomatNumber, {
          symbol: '',
          withZero: false
        })

        return (
          <div
            class={[
              {
                yellowColor: config.isYellow ?? false,
                greenColor: config.isGreen ?? false,
                redColor: config.isRed ?? false
              }
            ]}
          >
            {config.symbol && <span>{config.symbol}</span>}
            <span>
              {formattedNumber}
              {unit}
            </span>
            {config.suffix && <span>{config.suffix}</span>}
          </div>
        )
      }

      /**
       * 小数
       */
      case TableType.Decimal: {
        return <span>{Number(text).toFixed(config.precision ?? 2)}</span>
      }
      case TableType.Normal:
      default: {
        return <span>{text ?? emptyString}</span>
      }
    }
  }

  /**
   * 转换 dateRadio value值
   */
  protected transformDateRadioVal(val: number): number {
    switch (val) {
      case DateConditionEnum.THIS_MONTH:
        return 30
      case DateConditionEnum.LAST_TOW_MONTH:
        return 60
      case DateConditionEnum.LAST_THREE_MONTH:
        return 90
      default:
        return val
    }
  }
}
