import {
  BobiDetailData,
  CustomerServicePayData,
  FinanceGetPayPlatformListData,
  FinanceListData,
  FinancePayListDataWrapper,
  MemberBalanceData,
  OnlinePayData,
  QianNengDetalData,
  SilverMerchantData,
  TransferPayData,
  UPAYBalanceData
} from '@/api/finance/type'

export enum RootCategoryEnum {
  UNKNOWN = 0,
  ONLINE = 1,
  TRANSFER = 2,
  CRYPTO = 3,
  ARTIFICIAL = 4,
  SILVERMERCHANT = 5,
  ORDER_LIST = -1
}

export enum ChannelCodeEnum {
  BALANCE_PAY = 6, // 余额支付
  BALANCE_PAY2 = 13, // 余额支付新通道
  DIRECT_PURCHASE = 9, // 直接购买
  SCAN_CODE_PAY = 12 // 扫码支付
}

export enum BindTypeEnum {
  BOUND = 1, // 已绑定
  NOT_BOUND = 2, // 未绑定
  UN_BOUND = 3 // 已解绑
}

export type FinancePayState = {
  isPaySuccess: boolean
  loading: boolean
  rootCategoryList: FinancePayListDataWrapper[]
  getPayPlatformList: FinanceListData[]
  getPayPlatform: FinancePayListDataWrapper
  rootCategoryIndex: RootCategoryEnum
  activePlatformParent?: FinanceListData
  activePlatformCategory?: FinanceGetPayPlatformListData
  onlineChannelList: Array<OnlinePayData>
  transferChannelList: Array<TransferPayData>
  activePlatformMethod?:
    | OnlinePayData
    | TransferPayData
    | CustomerServicePayData
    | SilverMerchantData
  money?: string | null
  memberBalanceDetail?: MemberBalanceData
  isBindUPAYWallet: boolean
  UPAYBalanceDetail?: UPAYBalanceData
  validateForm: {
    realName?: string
    walletAddress?: string
    phone?: string
    email?: string
    cardNumber?: string
    cardPassword?: string
    networkMerch?: string
    bankSysCode?: string
    walletPassword?: string
    cpf?: string
  }
  buyUrl?: string
  signKey?: string
  orderNo?: string
  visible: boolean
  startCount: boolean
  countdown: number
  tableQueryTrigger: number
  exchangeRate: string
  payCurrencyCategory: string
  activeCurrencyCode: string
  qianNengAccountDetail: QianNengDetalData
  bindUPAYWalletUrl: string
  bobiAccountDetail: BobiDetailData
  bindQianNengWalletResult: {
    errcode: number
    errmsg: string
  }
  isScoreMode: boolean
  fee: {
    deduction: string
    realFee: string
  }
  payUrl?: string
  fastestDomain: string

  payListV4Error?: {
    code: string
    traceId?: string
    requestId?: string
  }

  // 当前选中的大类ID
  activePlatformId?: number
  // 当前选中的通道ID
  activeChannelId?: string | number
  // 平台是否展开
  isPlatformExpand: boolean
  // 通道是否展开
  isChannelExpand: boolean

  // 是否请求失败
  fetchError?: boolean

  fetchStatus: Record<
    string,
    {
      loading: boolean
      timeout: boolean
    }
  >
  isBankCardFocused: boolean
}
export enum ERROR {
  LIMIT_ERROR
}

export enum GoubaoErrorCode {
  NotBind = '01',
  Unauthorized = '02',
  Ok = '03'
}

export interface GoubaoDetail {
  code: GoubaoErrorCode
  step: 0 | 1 | 2 | 3
  qrcodeUrl?: string
  qrcodeContent?: string
  balance?: number
}

export type BindGoubaoPayload = {
  siteCode: string
  merchId: string
  time: number
  userId: string
  sign?: string
}
