import {
  ApiLoginRegisterType,
  CreateModeOptType,
  NormalLoginFormData
} from '@/api/common/type'
import { AxiosResponse } from 'axios'
import {
  Base,
  Component,
  Emit,
  Prop,
  Ref,
  Watch
} from '@/vue-property-decorator'
import { Bind, Debounce } from '@/utils/Decorator'
import {
  CommonCodeAuth,
  CommonPhoneNumberInput
} from '@/components/business-components'
import { FormModel, Space } from '@/plugins/ant-design-vue/tsx-support'
// import { FormModel, Space } from '@/plugins/ant-design-vue/tsx-support'
import { GlobalConst, GlobalManager } from '@/context'
import { Response } from '@/context/service'
// import { RouteName } from '@/router/config'
// import { TabsEnum } from '@/views/center/security/const'
// import { apiCheckCodePhoneNumber } from '@/api/event'
import { ApiEventItem } from '@/api/event/type'
import {
  apiGetSmsCodeAfterLogined,
  apiVerifyPhoneNumberAfterLogin
} from '@/api/common'
import { apiVerifyDiscountPhone } from '@/api/event'
import { createPngSprite } from '@/utils/business-utils/assets'
import { get, set } from 'lodash'
import { useI18n } from '@/i18n'
import { useMainStore } from '@/store/index'
import AppTypes from '@/vue-types'
import BusinessUtils from '@/utils/business-utils'
import Modal from '@/controller/Modal'
import Site from '@/controller/Site'
import style from './style.module.scss'
import to from 'await-to-js'
export type Actions = { type: 'back-home' } | { type: 'login-now' }

type FormModelAttrs = VueTsxSupport.JSX.ExtractComponentsTsxAttributes<
  typeof FormModel.Tsx,
  unknown
>

interface Props {
  item?: ApiEventItem
  activeId: number
  codeType?: number
  bindPhone?: number
  areaCode: string
  onComplete: (val: object) => void
}

@Component<VeriryBindPhone>({
  name: 'VeriryBindPhone'
})
export default class VeriryBindPhone extends Base<Props> {
  @Ref()
  private readonly phoneNumberFormItemRef!: {
    onFieldBlur: () => void
    onFieldChange: () => void
  }
  @Ref()
  private formModel!: Required<FormModelAttrs>
  @Ref()
  private smsVerificationRef!: CommonCodeAuth
  private form: NormalLoginFormData = {
    username: '',
    userpass: '',
    phone: '',
    captcha: '',
    loginType: ApiLoginRegisterType.Account
  }
  @Prop({ required: false })
  item!: Props['item']

  // activeId
  @Prop(AppTypes.number.isRequired)
  activeId!: Props['activeId']

  // codeType
  @Prop(AppTypes.number)
  codeType!: Props['codeType']

  // bindPhone
  @Prop(AppTypes.number)
  bindPhone!: Props['bindPhone']

  // areaCode 手机区号
  @Prop(AppTypes.string)
  areaCode!: Props['areaCode']

  @Watch('form.phone')
  @Watch('form.captcha')
  protected onInputValueChange() {
    this.onComplete()
  }

  @Emit('complete')
  private onComplete() {
    return this.form
  }

  private rules: FormModelAttrs['rules'] = {}

  private isPhoneNumberChecking = false

  /**
   * 输入的phoneNumber是否通过
   */
  private isPhoneNumberPassed: boolean | null = null

  private phoneNumberValidError: AxiosResponse<Response> | null = null

  /**
   * 脱敏的手机号信息(有手机号代表当前用户已绑定手机)
   * '+86-15******212'
   */
  private desensitizedPhoneNumber = ''

  private get phoneNumberFormat() {
    const { smsCountry } = useMainStore()
    const form = this.form
    if (!form.phone) return ''
    const phoneNumberSegment = `${
      this.areaCode ? this.areaCode : smsCountry?.phoneNumberSegment
    }-`
    return `${!this.desensitizedPhoneNumber ? phoneNumberSegment : ''}${
      form.phone
    }`
  }

  /**
   * 是否已绑定手机
   */
  private get isBindPhone() {
    return this.desensitizedPhoneNumber
  }

  private get userInfos() {
    return useMainStore().userInfos
  }

  async mounted() {
    if (this.userInfos) {
      this.desensitizedPhoneNumber = this.userInfos.mobile_phone
    }
    if (!this.isBindPhone) {
      await Site.initSmsCountry()
      Site.initSmsSegment(this.areaCode)
    }
  }

  @Bind()
  @Debounce()
  public async onSubmit(): Promise<Record<string, string> | false> {
    try {
      await this.formModel.validate()
      return {
        verifyCode: this.smsVerificationRef.verificationCode,
        phone: this.form.phone!
      }
    } catch (error) {
      const serviceCode = GlobalConst.ServiceCode
      const data = (error as { data: { code?: number; msg: string } })?.data
      const code = data?.code
      if (
        code === serviceCode.LOGIN_ERROR ||
        code === serviceCode.ACCOUNT_FREEZE
      ) {
        Modal.openLoginRegisterModal()
      }
      console.error(error)
      return false
    }
  }

  private async validPhoneNumberInput(phone: string) {
    if (phone.length < this.desensitizedPhoneNumber?.length) {
      this.isPhoneNumberPassed = null
      return Promise.reject(new Error())
    }
    // TODO：修改验证方法
    const promise = apiVerifyPhoneNumberAfterLogin(
      {
        phone
      },
      {
        customParams: { silentOnError: true }
      }
    )
    this.isPhoneNumberChecking = true
    const [error] = await to(promise)
    this.phoneNumberValidError = error as unknown as AxiosResponse<Response>
    this.isPhoneNumberChecking = false
    this.isPhoneNumberPassed = !error
    if (error) return Promise.reject(error)
  }

  private phoneDisable() {
    // 判断新人彩金活动时间（活动未开始、活动已结束）
    const now = +new Date()
    const startTime: number = this.item?.startTime
      ? parseInt(String(this.item.startTime * 1000))
      : 0
    const endTime: number = this.item?.endTime
      ? parseInt(String(this.item.endTime * 1000))
      : 0
    if (startTime - now > 0) {
      return true
    }

    if (endTime - now < 0) {
      return true
    }
    return false
  }

  render() {
    const { t } = useI18n()
    const { smsSegment } = useMainStore()
    const commonFormModelItemRender =
      GlobalManager.FuncRender.createFormModelItem.call(this)
    return (
      <div class={style.main}>
        <Space.Tsx direction={'vertical'} size={14} class={style.content}>
          {/* 会员账号 密码 */}
          <FormModel.Tsx
            ref={'formModel'}
            layout={'vertical'}
            hideRequiredMark
            class={this.phoneDisable() ? style.phoneDisabled : ''}
            props={{
              rules: this.rules,
              model: this.form
            }}
          >
            {
              /**
               * PHONE验证
               */
              this.isBindPhone
                ? [
                    <div class={style.infos} key="infos">
                      <span class={style.key}>
                        {t('lobby.common.components.auth.phone.current')}
                      </span>
                      <span class={style.value}>
                        {this.desensitizedPhoneNumber?.replace('-', ' ')}
                      </span>
                    </div>,
                    commonFormModelItemRender.custom({
                      hiddenPrefix: true,
                      showLabel: true,
                      prop: 'phone',
                      model: this.form,
                      formModelItemTsxData: {
                        class: [style.phoneFormItem],
                        ref: 'phoneNumberFormItemRef',
                        props: {
                          autoLink: false,
                          help: () => {
                            const renderText = () => {
                              if (this.isPhoneNumberChecking) {
                                return t('lobby.common.tips.validating')
                              }
                              if (this.isPhoneNumberPassed === null) {
                                return t(
                                  'lobby.common.components.auth.phone.requiredNew',
                                  {
                                    length: BusinessUtils.phoneNumberParse(
                                      this.desensitizedPhoneNumber
                                    )?.placeholder.length
                                  }
                                )
                              }

                              if (this.isPhoneNumberPassed === true) {
                                return t(
                                  'lobby.common.components.auth.phone.success'
                                )
                              }

                              if (this.isPhoneNumberPassed === false) {
                                if (
                                  this.phoneNumberValidError?.data.code ===
                                  GlobalConst.ServiceCode.PHONE_ERROR
                                ) {
                                  return t(
                                    'lobby.common.components.auth.phone.error'
                                  )
                                } else {
                                  return this.phoneNumberValidError?.data.msg
                                }
                              }
                            }

                            return (
                              <div
                                class={[
                                  style.tipsContainer,
                                  this.isPhoneNumberPassed ? style.success : ''
                                ]}
                              >
                                {!this.isPhoneNumberPassed && (
                                  <icon-sprite
                                    class={style.icon}
                                    sprite={createPngSprite(
                                      'sprite_main_comm_icon_pay_4'
                                    )}
                                    scale={0.343}
                                  />
                                )}
                                <div class={style.tips}>{renderText()}</div>
                              </div>
                            )
                          },
                          rules: [
                            GlobalManager.Rules.create({
                              trigger: ['blur', 'change'],
                              asyncValidator: async (
                                _rule,
                                value,
                                callback
                              ) => {
                                const [error] = await to(
                                  this.validPhoneNumberInput(value)
                                )
                                if (error) return callback('')
                                return callback()
                              }
                            })
                          ]
                        }
                      },
                      render: () => {
                        return (
                          <CommonPhoneNumberInput
                            ref={'phoneAuthInputRef'}
                            onComplete={() => {
                              /**
                               * 关联trigger 中的 blur 监听
                               */
                              this.phoneNumberFormItemRef?.onFieldBlur()
                            }}
                            disabled={
                              this.phoneDisable() || !!this.isPhoneNumberPassed
                            }
                            onInput={(value: string) => {
                              set(this.form, 'phone', value)
                              /**
                               * 关联trigger 中的change监听
                               */
                              if (value) {
                                this.phoneNumberFormItemRef?.onFieldChange()
                              }
                            }}
                            value={get(this.form, 'phone') as string}
                            phoneNumberTemplate={this.desensitizedPhoneNumber}
                          />
                        )
                      }
                    })
                  ]
                : [
                    // <div class={style.toBindPhone}>
                    //   {t('lobby.common.components.auth.phone.toBindPhone')}
                    //   <Button.Tsx
                    //     class={style.toBindPhoneBtn}
                    //     type="link"
                    //     nativeOnClick={() => {
                    //       this.$router.push({
                    //         name: RouteName.SECURITY,
                    //         query: {
                    //           current: TabsEnum.PHONE.toString()
                    //         }
                    //       })
                    //     }}
                    //   >
                    //     {t('lobby.common.components.auth.phone.toBindPhoneBtn')}
                    //   </Button.Tsx>
                    // </div>
                    [
                      smsSegment &&
                        commonFormModelItemRender.userPhoneSelect({
                          hiddenPrefix: false,
                          hiddenIcon: true,
                          showLabel: false,
                          prop: 'phone',
                          model: this.form,
                          inputTsxData: {
                            attrs: {
                              disabled: this.phoneDisable(),
                              placeholder: t(
                                'lobby.center.security.phone.phonePlaceholder'
                              )
                            }
                          },
                          selectTsxData: {
                            props: {
                              disabled: !!this.areaCode,
                              showArrow: !this.areaCode
                            }
                          },
                          onSelectChange: () => {
                            this.form.phone = ''
                          }
                        })
                    ]
                  ]
            }
            <CommonCodeAuth
              hiddenPrefix={true}
              showLabel={false}
              ref={'smsVerificationRef'}
              prop={'captcha'}
              class={this.phoneDisable() ? style.codeDisabled : ''}
              getCodeFunPromise={async (targetValue) => {
                /** 先保留：如果 codeType 为4时，先校验手机号是否合法 */
                // if (this.codeType === 4) {
                //   const { status, message } = await apiCheckCodePhoneNumber({
                //     areaCode: targetValue?.split('-')[0],
                //     phoneNumber: targetValue?.includes('-')
                //       ? targetValue?.split('-')[1]
                //       : targetValue,
                //     activeId: this.activeId
                //   })
                //   /** 0成功 1活动ID无效 2活动id不是电话彩金 3区域码不正确 4电话号码不存在 5电话号码已被使用 */
                //   if (status) {
                //     GlobalManager.Modal.message({
                //       type: 'error',
                //       content: message
                //       // content: t('lobby.event.invest.receiveSuccess')
                //     })
                //     return Promise.reject()
                //   }
                // }

                /** 验证手机号是否具备领取资格 */
                const { status, message } = await apiVerifyDiscountPhone({
                  areaCode: targetValue?.split('-')[0],
                  phone: targetValue?.includes('-')
                    ? targetValue?.split('-')[1]
                    : targetValue,
                  activeId: this.activeId
                })
                /** 0成功 */
                if (status) {
                  GlobalManager.Modal.message({
                    type: 'error',
                    content: message
                  })
                  return Promise.reject()
                }

                return apiGetSmsCodeAfterLogined({
                  create_mode: CreateModeOptType.Verify,
                  limit_type: 'phone',
                  phone_number: targetValue
                })
              }}
              disabled={
                this.phoneDisable() || this.isBindPhone
                  ? !this.isPhoneNumberPassed
                  : false
              }
              targetValue={this.phoneNumberFormat}
              v-model={this.form.captcha}
            />
          </FormModel.Tsx>
        </Space.Tsx>
      </div>
    )
  }
}
