import {
  ApiRedPocketItem1,
  ApiRedPocketItem2,
  ApiRedPocketItem3,
  ApiRedPocketResult,
  RedTypeEnum
} from '@/api/redPocket/type'

/**
 * 红包视图模式
 */
export enum RedUiModeEnum {
  /**
   * 空白
   */
  blank = 'blank',
  /**
   * 开红包
   */
  entrance = 'entrance',
  /**
   * 抢红包
   */
  pin = 'pin',
  /**
   * 送红包
   */
  send = 'send',
  /**
   * 成功
   */
  success = 'success'
}

/**
 * 红包样式类型 0红包 1宝箱 2礼包 3福袋 4美金（宝箱2）
 */
export enum RedUiStyleEnum {
  /** 开红包 */
  RedPocket,
  /** 开宝箱1 */
  TreasureBox1,
  /** 开礼包 */
  GiftBag,
  /** 开福袋 */
  LuckyBag,
  /** 开宝箱2 */
  TreasureBox2
}

export interface State {
  isCollecting?: boolean
  animation?: boolean
  ui?: RedUiModeEnum
  result?: ApiRedPocketResult
  pin?: number[]
  pinVerify?: string
  showClose?: boolean
}

export interface Props {
  type: RedTypeEnum
  item: RedPocketItemType
}

export type RedPocketItemType =
  | ApiRedPocketItem1
  | ApiRedPocketItem2
  | ApiRedPocketItem3
