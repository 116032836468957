import { MessageAllRequest } from '@/api/home/type'
import { NoticeItem, NoticeResponse } from '@/api/notice/type'
import { NoticeType } from '../const'
import { apiGetMessageAll } from '@/api/home'
import { cloneDeep } from 'lodash'
import { createMyPersistedStorage } from '@/utils/business-utils'
import { defineStore } from 'pinia'
import { useFeedbackStore } from '@/views/feedback/store'
import to from 'await-to-js'

export interface NoticeState {
  notice?: NoticeResponse
  unreadAnnouncement: number
  unreadNotice: number
  cacheData?: NoticeResponse
  axiosError?: null | Error
}

export const useNoticeStore = defineStore('notice', {
  state: (): NoticeState => ({
    notice: {} as NoticeResponse,
    unreadAnnouncement: 0,
    unreadNotice: 0,
    cacheData: undefined,
    axiosError: null
  }),
  persist: {
    enabled: true,
    strategies: [
      {
        key: 'web.lobby.notice',
        storage: createMyPersistedStorage(),
        paths: ['cacheData']
      }
    ]
  },
  getters: {
    noticeList(): NoticeState['notice'] {
      return this.notice
    },
    unreadMessageCount(): number {
      const { unRead } = useFeedbackStore()
      return this.unreadAnnouncement + this.unreadNotice + unRead
    }
  },
  actions: {
    async getNoticeList(page: number, type: number, size = 5) {
      const payload = {
        list: 1,
        listType: type,
        listPage: page,
        listSize: size
      } as MessageAllRequest
      const [error, data] = await to(apiGetMessageAll(payload))
      if (error) {
        this.axiosError = error
        return
      }
      if (data) {
        this.axiosError = null
        const _notice = data.list
        this.setNoticeList(_notice, type, page)
        return _notice
      }
      return null
    },
    setUnreadAnnouncement(val: number) {
      this.unreadAnnouncement = val
    },
    setUnreadNotice(val: number) {
      this.unreadNotice = val
    },
    setNoticeList(
      _notice: NoticeResponse | undefined,
      type: number | undefined,
      page: number | undefined
    ) {
      if (type === NoticeType.ANNOUNCEMENT) {
        this.unreadAnnouncement = _notice?.count || 0
        //首次打开消息中心的第一页数据存入持久化缓存。
        //过滤掉content 避免图文内容把localstorage撑爆
        if (page == 1 && _notice) {
          const cache = cloneDeep(_notice)
          cache.list.forEach((item: NoticeItem) => {
            item.content = ''
          })
          this.cacheData = cache
        }
      } else if (type === NoticeType.NOTICE) {
        this.unreadNotice = _notice?.count || 0
      }
      this.notice = _notice
    }
  }
})
