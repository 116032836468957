import { Button, Progress } from '@/plugins/ant-design-vue/tsx-support'
import { CPFStatus } from '@/api/rechargeFund/type'
import { Component } from '@/vue-property-decorator'
import { TabsEnum as ReportTabsEnum } from '@/views/center/report/config'
import { RouteName } from '@/router/config'
import { canReceiveReward } from '@/views/event/item/utils'
import { currencyFormatter } from '@/utils/FormatterUtils'
import { useI18n } from '@/i18n'
import { useMainStore } from '@/store/index'
import BusinessUtils from '@/utils/business-utils'
import ModalBase from '@/mixins/business/modal/modal-base'
import WithdrawDonwTime from '../WithdrawDonwTime'
import style from '../../style.module.scss'
import useRechargeFundStore from '../../store'
type State = {
  isDisabled: boolean
}
/** 取出弹窗 */
@Component<ModalDetail>({ name: 'ModalDetail' })
export default class ModalDetail extends ModalBase<State> {
  state: State = {
    isDisabled: false
  }
  /** 父组件调用打开弹窗 */
  public openModal() {
    this.baseOpen()
  }
  get isWeb() {
    return useMainStore().isWeb
  }
  get hasLogin() {
    return useMainStore().hasLogined
  }
  /** 倒计时时间戳 */
  get timeStamp() {
    const { currentPeriodReward } = useRechargeFundStore()
    if (!currentPeriodReward?.fund_period_end_time) {
      return 0
    }
    const time =
      currentPeriodReward?.fund_period_end_time -
      Math.round(new Date().getTime() / 1000)
    if (time > 15 * 24 * 60 * 60) {
      return 0
    }
    return time
  }
  /** 取出操作 */
  handleWithdraw = async () => {
    const { receive_device_type } = useRechargeFundStore().rechargeSetting || {}
    // 判定领取设备
    if (receive_device_type && !canReceiveReward(receive_device_type)) {
      return
    }
    this.state.isDisabled = true
    const isAllowed = await useRechargeFundStore().withdrawCPF()
    this.state.isDisabled = false
    if (!!isAllowed) {
      this.$emit('openAanimation')
    }
  }
  /** 公积金取出按钮禁用状态 */
  get getOutState() {
    const { currentPeriodReward } = useRechargeFundStore()
    return (
      currentPeriodReward?.receive_log_client_status !== CPFStatus.CanReceive
    )
  }
  /** 赠送金额文本 */
  getSendNum(value = 0) {
    const { currentPeriodReward } = useRechargeFundStore()
    const text = currencyFormatter(value, {
      code: currentPeriodReward?.currency,
      precision: 0,
      symbol: ''
    })
    return value ? text : 0
  }
  /** 公积金封顶次数 封顶金额提示文案 因为拼接的有语法顺序问题 所以整理成一个整体今天翻译 */
  getMaxLimitText(max_limit_add_times = 0, max_limit_fund_amount = 0) {
    const { t } = useI18n()
    if (max_limit_add_times && max_limit_fund_amount) {
      return t('lobby.cpf.maxLimitText1', {
        num1: String(max_limit_add_times),
        num2: this.getSendNum(max_limit_fund_amount)
      })
    }

    if (max_limit_add_times) {
      return t('lobby.cpf.maxLimitText2', {
        num: String(max_limit_add_times)
      })
    }

    if (max_limit_fund_amount) {
      return t('lobby.cpf.maxLimitText3', {
        num: this.getSendNum(max_limit_fund_amount)
      })
    }

    return t('lobby.cpf.maxLimitText4')
  }
  /** 详情跳转 */
  handleJump = () => {
    this.baseClose()
    this.$router.push({
      name: RouteName.RECHARGE_FUND,
      query: {
        rechargeFunCurrent: ReportTabsEnum.BETTING_RECORD
      }
    })
  }
  /** 进度条百分比 */
  get progressRate() {
    const { currentPeriodReward } = useRechargeFundStore()
    let rate = 0
    // 剩余打码量为0，或当前周期开始到现在用户打总码量大于用户所需总打码量时为100%
    const isGreater =
      currentPeriodReward &&
      currentPeriodReward?.bet_code >=
        currentPeriodReward?.receive_need_bet_code
    if (currentPeriodReward?.remaining_bet_code === 0 || isGreater) {
      rate = 100
    } else {
      rate = Math.floor(
        ((currentPeriodReward?.bet_code || 0) /
          (currentPeriodReward?.receive_need_bet_code || 1)) *
          100
      )
    }
    return rate
  }

  private refresh() {
    useRechargeFundStore().updateData()
  }

  render() {
    const { t } = useI18n()
    const { rechargeSetting, currentPeriodReward, updateTamp } =
      useRechargeFundStore()

    const renderContent = () => {
      return [
        <div>
          <div class={style.modalContent}>
            <div>
              <div class={style.currentFund}>
                {t('lobby.cpf.currentCPF')}
                <span class={style.currentFundNum}>
                  {currencyFormatter(currentPeriodReward?.fund_amount || 0, {
                    code: currentPeriodReward?.currency,
                    symbol: ''
                  })}
                </span>
              </div>
              <div class={style.fundText}>
                <div>
                  1.
                  {t('lobby.cpf.firtRechargeCPF', {
                    num: String(rechargeSetting?.add_fund_rate || 0) + '%'
                  })}
                </div>
                <div>
                  2.
                  {this.getMaxLimitText(
                    rechargeSetting?.max_limit_add_times,
                    rechargeSetting?.max_limit_fund_amount
                  )}
                </div>
              </div>
            </div>
            <div class={style.detailWrapper}>
              {!this.getOutState ? (
                <div>
                  <span>{t('lobby.cpf.finishRecharge')}</span>
                  <span class={style.fundDetail} onClick={this.handleJump}>
                    {t('lobby.cpf.details')}
                  </span>
                </div>
              ) : (
                <div>
                  <span>{t('lobby.cpf.needRecharge')}</span>

                  <span class={style.needBet}>
                    {currencyFormatter(
                      currentPeriodReward?.remaining_bet_code || 0,
                      {
                        code: currentPeriodReward?.currency,
                        symbol: ''
                      }
                    )}
                  </span>
                  <span>{t('lobby.cpf.enableWithdraw')}</span>
                  <span class={style.fundDetail} onClick={this.handleJump}>
                    {t('lobby.cpf.details')}
                  </span>
                </div>
              )}
              <WithdrawDonwTime
                donwTimestamp={this.timeStamp}
                onCountDownEnd={() => updateTamp()}
              />
            </div>
            <div class={style.progressWrapper}>
              <Progress.Tsx
                showInfo={false}
                percent={this.progressRate}
                strokeWidth={20}
                trailColor={'var(--theme-color-line)'}
              />
              <div class={style.num}>
                {(currentPeriodReward?.bet_code || 0) +
                  '/' +
                  currentPeriodReward?.receive_need_bet_code}
              </div>
            </div>
          </div>
          <Button.Tsx
            disabled={this.state.isDisabled || this.getOutState}
            class={style.enableWithdraw}
            nativeOnClick={this.handleWithdraw}
          >
            {currentPeriodReward?.receive_log_client_status ===
            CPFStatus.Received
              ? t('lobby.cpf.hasWithdraw')
              : t('lobby.cpf.withdraw')}
          </Button.Tsx>
        </div>
      ]
    }

    return this.renderModal({
      modalConfig: {
        class: style.modalFund,
        props: {
          width: BusinessUtils.px2rem(600),
          footer: null,
          closable: true,
          wrapClassName: style.modalFund,
          title: t('lobby.cpf.withdraw').toString()
        }
      },
      renderContent
    })
  }
}
