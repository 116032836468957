import { GlobalService } from '@/context'
import { TaskCategory } from '../class/taskCategory'
import { TaskCategoryApiCodeEnum } from './type'
import { useDeviceType } from '@/utils/business-utils/useDeviceType'
// import { mockMapper } from './mock/apiGetTaskActivityInTabs'

/**  通过apiCode获取活动任务Tabs弹框状态  */
export const apiGetTaskActivityInTabs = async (
  apiCode: TaskCategoryApiCodeEnum
) => {
  // taskId与template在TaskCategory中获取
  const taskCategory = new TaskCategory(apiCode)
  const { taskId, template } = taskCategory
  const response = await GlobalService.post<TaskCategory>({
    url: '/activetask/task',
    data: { taskId, template },
    headers: {
      deviceModel: useDeviceType().deviceModel,
      devicetype: useDeviceType().deviceType
    },
    // 屏蔽掉错误信息,如果后台临时改了activetask/index或者用户层级或者是配置什么的,那前端还是会去发请求,这是无法避免的会报异常.
    // 因此,还是得从根源上屏蔽掉错误警告.
    customParams: {
      noErrorMessage: true,
      silentOnError: true
    }
  })
  if (!response.data?.data) {
    return taskCategory
  }
  taskCategory.immitResponse(response.data.data)
  return taskCategory
}

/** （假数据）通过apiCode获取活动任务Tabs弹框状态 */
// export const _调用假数据_apiGetTaskActivityInTabs = async (
//   apiCode: TaskCategoryApiCodeEnum
// ) => {
//   // taskId与template在TaskCategory中获取
//   const taskCategory = new TaskCategory(apiCode)
//   const resData = mockMapper[apiCode]
//   if (!resData) {
//     return taskCategory
//   }
//   const ret = merge(taskCategory, resData) as TaskCategory
//   return ret
// }
